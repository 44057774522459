import { environment } from "conf"
import { 
    AUTH_STORE_CONNECT_API_URL, 
    AUTH_STORE_CONNECT_API_URL_PREFIX, 
    CANCEL_PRICING_PLAN_API_URL, 
    STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX, 
    STORE_CONNECT_API_URL, 
    STORE_DISCONNECT_API_URL, 
    STORE_SETTINGS_API_URL_PREFIX, 
    STORE_SETTINGS_API_URL_SUFFIX, 
    STORE_SETTINGS_PRODUCT_SYNC_API_URL_SUFFIX, 
    STORE_SETTINGS_THEME_UPDATE_API_URL_SUFFIX, 
    UPGRADE_PRICING_PLAN_API_URL 
} from "constants/api.constants"
import { request_auth } from "services/RequestWrapper"

export function AuthConnectStoreAPI(auth) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                auth
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + AUTH_STORE_CONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function ConnectStoreAPI(shopUrl) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                shop_url: shopUrl
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + STORE_CONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function UpgradePlanAPI(planName, businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                store_subscription_plan: planName,
                business_id: businessId
            }
        }

        const url = environment.base_url + UPGRADE_PRICING_PLAN_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function CancelPlanAPI(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = environment.base_url + CANCEL_PRICING_PLAN_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function DisconnectStore(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + STORE_DISCONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Updates the business's settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/settings

    Body params:
    ============
        (*) businessId - The business id
        (*) shippingAndReturnUrl - The shipping and return URL of the business
        (*) facebookPixelId - The id of the business for facebook pixel analytics
        (*) googleAnalyticsId - The id of the business for google analytics
*/
export function UpdateStoreSettings(businessId, shippingAndReturnUrl, facebookPixelId, googleAnalyticsId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                settings: {
                    shipping_and_return_url: shippingAndReturnUrl,
                    facebook_pixel_id: facebookPixelId,
                    google_analytics_id: googleAnalyticsId
                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_SETTINGS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Updates the business's appearance settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/appearance

    Body params:
    ============
        (*) businessId - The business id
        (*) logoUrl - The url of the new logo
*/
export function UpdateStoreAppearanceSettings(businessId, logoUrl) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                appearance: {
                    logo_url: logoUrl
                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Performs a full products and inventory sync to all the user's stores.

    Method: [POST]
    URL: /admin/v1/store/sync
*/
export function SyncProducts(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}${STORE_SETTINGS_PRODUCT_SYNC_API_URL_SUFFIX}`

        request_auth(url, options, false, null)
            .then(resolve)
            .catch(reject)
    })
}

/*
    Updates the link's theme customization settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/theme
*/
export function UpdateLinkStyles(businessId, theme) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                theme: {
                    checkout_button_shape: theme.checkoutButtonShape,
                    checkout_button_background_color: theme.checkoutButtonBackgroundColor,
                    checkout_button_font_color: theme.checkoutButtonFontColor,
                    checkout_button_border_color: theme.checkoutButtonBorderColor,
                    cart_button_icon: theme.cartButtonIcon,
                    discount_background_color: theme.discountBackgroundColor,
                    discount_font_color: theme.discountFontColor
                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_SETTINGS_THEME_UPDATE_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}