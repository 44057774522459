import React from 'react'
import './Card.css'

/**
 * Represents a card-like container.
 * @param {number} paddingPx - The padding of the card's content 
 */
export default function Card({
    children,
    paddingPx = 15
}) {
    return (
        <div className='card-container' style={{ padding: `${paddingPx}px`}}>
            {children}
        </div>
    )
}