import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from '@mui/material'
import './PopupDialog.css'

/**
 * Represents a custom popup dialog.
 * @param {DOMElement} children - The content for this dialog
 * @param {boolean} isOpen - Determins whether the dialog is open or not
 * @param {function} onDialogClose - A callback to perform when closing the dialog
 * @param {object} styles - A custom styles object
 * @param {string} childClassName - A class name for the children's container element. Used to set the dialog's height dynamically
 * @param {boolean} hasNoWidthLimit - Determins whether the dialog has limited width (or maximum width property) or not
 * @param {string} backdropColor - The color of the dialog's background
 * @param {number} borderRadiusPx - The border radius of the dialog in pixels
 * @param {number} width - The width of the dialog in pixels. If non given, its width is set to 100%
 */
export default function PopupDialog({
    children,
    isOpen,
    onDialogClose,
    styles = {},
    childClassName = '',
    hasNoWidthLimit = false,
    hasNoHeightLimit = false,
    maxWidth = '600px',
    maxHeight = '600px',
    backdropColor = 'rgba(0, 0, 0, 0.5)',
    borderRadiusPx = 20,
    width = null,
    margin = '15px'
}) {

    const [dialogHeight, setDialogHeight] = useState(0)
    const dialogRef = useRef(null)

    useEffect(() => {
        setDialogHeight(getDialogHeight())
    })

    function getDialogHeight() {
        const dialogContentElement = dialogRef?.current?.querySelector(`.${childClassName}`)
        return dialogContentElement?.clientHeight
    }

    function getWidth() {
        if (!width) return '100%'
        if (typeof width !== 'number') return width
        return `${width}px`
    }

    const dialogContentStyles = {
        width: getWidth(),
        backgroundColor: 'white',
        margin,
        height: `${dialogHeight}px`,
        ...{ maxWidth: hasNoWidthLimit ? 'unset' : maxWidth },
        ...{ maxHeight: hasNoHeightLimit ? 'unset' : maxHeight },
        ...styles
    }

    const dialogStyles = {
        borderRadius: `${borderRadiusPx}px`,
        '& .MuiBackdrop-root': {
            backgroundColor: backdropColor, // Set your desired backdrop color here
        },
    }

    return (
        <Dialog
            ref={dialogRef}
            open={isOpen}
            onClose={onDialogClose}
            fullScreen={false}
            sx={dialogStyles}
            PaperProps={{
                sx: dialogContentStyles
            }}
            disableScrollLock
        >
            {children}
        </Dialog>
    )
}