import React, { useEffect } from 'react'
import './ApprovalMessage.css'
import { GrClose } from 'react-icons/gr'
import { APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS } from 'constants/general.constants'

/**
 * Represents an approval message for saving changes scenarios.
 * The message is entering the screen with a nice translation animation.
 * @param {string} text - The text to display 
 * @param {boolean} isUp - Determins whether the message is entering the screen from the top or bottom
 * @param {boolean} isMessageShown - Determins whether the message is shown or not
 * @param {function} closeMessage - A function for hiding the message
 */
export default function ApprovalMessage({
    text,
    isUp,
    isMessageShown,
    closeMessage = () => { }
}) {
    useEffect(() => {
        if (isMessageShown) {
            setTimeout(() => {
                closeMessage()
            }, APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS)
        }
    }, [isMessageShown])

    return (
        <div className={`approval-message-container ${isMessageShown ? 'show' : ''} ${isUp ? 'up' : ''}`}>
            <div className="approval-message-text">{text}</div>
            <GrClose className='approval-message-close-button' onClick={closeMessage} />
        </div>
    )
}