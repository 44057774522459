import React, { useState } from 'react'
import './LayoutTopbarRightSection.css'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import { useHistoryContext } from 'contexts/History'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    ADMIN_PAGES,
    DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX,
    DASHBOARD_TOPBAR_USER_MENU_WIDTH_PX,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_MENU_OPENING_DIRECTIONS,
    SHOPIFY_LOGO_URL
} from 'constants/general.constants'
import { CiLogout, CiLogin } from 'react-icons/ci'
import { BiLink } from 'react-icons/bi'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { GrDocumentText } from 'react-icons/gr'
import { SlQuestion } from 'react-icons/sl'
import { AiOutlineClose, AiOutlineUserAdd } from 'react-icons/ai'
import { FiUser, FiMenu } from 'react-icons/fi'
import { RiPaletteLine } from 'react-icons/ri'
import { BsCurrencyDollar } from 'react-icons/bs'
import { isDesktop, isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import {
    ACCOUNT_PAGE_URL,
    ADMIN_PAGES_URL_PREFIX,
    LINKS_PAGE_URL,
    LOGIN_PAGE_URL,
    PRICING_PLANS_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    SHOPIFY_HELP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    STORE_SETTINGS_PAGE_URL_PREFIX,
    TERMS_PAGE_URL
} from 'constants/routes.constants'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ImageBox from 'components/ImageBox/ImageBox'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'

/**
 * Represents the right section of the welcome/dashboard topbar.
 * It can be either an element with 2 buttons: "Log in" & "Sign up for free" (if the user is not authenticated)
 * or user button that opens a menu and an "Admin" button next to it.
 */
export default function LayoutTopbarRightSection() {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()
    const { logout } = useAuthContext()

    const [isLogoutConfirmationDialogOpen, setIsLogoutConfirmationDialogOpen] = useState(false)
    const [userMenuState, setUserMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: () => { },
        onClose: () => closeMoreOptionsDrawer(),
        children: <></>
    })

    function closeMoreOptionsDrawer() {
        history.goBack()
    }

    function goToLinks() {
        if (window.location.pathname !== LINKS_PAGE_URL)
            history.push(LINKS_PAGE_URL)
    }

    function goToSignUp() {
        history.push(SIGN_UP_REQUEST_PAGE_URL)
    }

    function goToAppearance() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}/${userDetails?.businessId}`)
    }

    function openMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: true,
            children:
                ADMIN_PAGES.includes(window.location.pathname) ? <>
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-my-account mobile-dashboard-topbar-more-options-drawer-option" onClick={goToMyAccount}>
                        <FiUser className="mobile-dashboard-topbar-more-options-drawer-image" />
                        <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_MY_ACCOUNT')}</div>
                    </div>
                    <div className={`mobile-dashboard-topbar-more-options-drawer-option-appearance mobile-dashboard-topbar-more-options-drawer-option ${!hasShopifyStore() ? 'disabled' : ''}`} onClick={goToAppearance}>
                        <RiPaletteLine className="mobile-dashboard-topbar-more-options-drawer-image" />
                        <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_APPEARANCE')}</div>
                    </div>
                    {/* TODO leave this comment untill the public app is approved */}
                    {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-billing mobile-dashboard-topbar-more-options-drawer-option disabled">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_BILLING')}</div> */}
                    {/* TODO leave this comment until re-adding the archive page */}
                    {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-archive mobile-dashboard-topbar-more-options-drawer-option">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_ARCHIVE')}</div> */}
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-logout mobile-dashboard-topbar-more-options-drawer-option" onClick={showLoginConfirmationDialog}>
                        <CiLogout className="mobile-dashboard-topbar-more-options-drawer-image" />
                        <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGOUT')}</div>
                    </div>
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-cancel mobile-dashboard-topbar-more-options-drawer-option" onClick={closeMoreOptionsDrawer}>
                        <AiOutlineClose className="mobile-dashboard-topbar-more-options-drawer-image" />
                        <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL')}</div>
                    </div>
                </> : (
                    userDetails?.isAuthenticated ? <>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-admin mobile-dashboard-topbar-more-options-drawer-option" onClick={goToLinks}>
                            <BiLink className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_ADMIN')}</div>
                        </div>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-pricing mobile-dashboard-topbar-more-options-drawer-option" onClick={goToPricingPlans}>
                            <BsCurrencyDollar className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_PRICING')}</div>
                        </div>
                        {/* TODO leave this comment untill the public app is approved */}
                        {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-learn mobile-dashboard-topbar-more-options-drawer-option disabled">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_BILLING')}</div> */}
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-logout mobile-dashboard-topbar-more-options-drawer-option" onClick={showLoginConfirmationDialog}>
                            <CiLogout className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGOUT')}</div>
                        </div>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-cancel mobile-dashboard-topbar-more-options-drawer-option" onClick={closeMoreOptionsDrawer}>
                            <AiOutlineClose className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL')}</div>
                        </div>
                    </> : <>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-login mobile-dashboard-topbar-more-options-drawer-option" onClick={goToLinks}>
                            <CiLogin className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGIN')}</div>
                        </div>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-signin mobile-dashboard-topbar-more-options-drawer-option" onClick={goToSignUp}>
                            <AiOutlineUserAdd className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_SIGNUP_FOR_FREE')}</div>
                        </div>
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-pricing mobile-dashboard-topbar-more-options-drawer-option" onClick={goToPricingPlans}>
                            <BsCurrencyDollar className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_PRICING')}</div>
                        </div>
                        {/* TODO leave this comment untill the public app is approved */}

                        {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-learn mobile-dashboard-topbar-more-options-drawer-option disabled">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_BILLING')}</div> */}
                        <div className="mobile-dashboard-topbar-more-options-drawer-option-cancel mobile-dashboard-topbar-more-options-drawer-option" onClick={closeMoreOptionsDrawer}>
                            <AiOutlineClose className="mobile-dashboard-topbar-more-options-drawer-image" />
                            <div className="mobile-dashboard-topbar-more-options-drawer-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL')}</div>
                        </div>
                    </>
                )
        }))
        history.addBackHandler(() => setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        })))
    }

    function openUserMenu(e) {
        if (isMobile) {
            openMoreOptionsDrawer()
        } else {
            setUserMenuState({
                isOpen: true,
                anchorElement: e.currentTarget,
                onClose: () => setUserMenuState(prev => ({
                    ...prev,
                    isOpen: false
                }))
            })
        }
    }

    function goToPricingPlans() {
        if (window.location.pathname !== PRICING_PLANS_PAGE_URL)
            history.push(PRICING_PLANS_PAGE_URL)
    }

    function goToMyAccount() {
        history.push(ACCOUNT_PAGE_URL)
    }

    function showLoginConfirmationDialog() {
        if (isMobile)
            optionsDrawerState.onClose()
        else
            userMenuState.onClose()

        setTimeout(() => {
            setIsLogoutConfirmationDialogOpen(true)
        }, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY)
    }

    function openPrivacyPolicyPage() {
        history.push(PRIVACY_POLICY_PAGE_URL)
    }

    function openTermsAndConditionsPage() {
        history.push(TERMS_PAGE_URL)
    }

    function openShopifyHelpPage() {
        history.push(SHOPIFY_HELP_PAGE_URL)
    }

    function logOut() {
        logout()
        history.push(LOGIN_PAGE_URL)
    }

    function closeDialog() {
        setIsLogoutConfirmationDialogOpen(false)
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    return (
        <div className='layout-topbar-right-section'>
            {
                isDesktop ? <>
                    {
                        userDetails?.isAuthenticated ? <>
                            {!window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX) && <div className="welcome-topbar-admin-button welcome-topbar-right-button welcome-login-button" onClick={goToLinks}>{t('WELCOME_TOPBAR_ADMIN_BUTTON_TEXT')}</div>}
                            <div className="dashboard-topbar-username-circle" onClick={openUserMenu}>
                                <div className="dashboard-topbar-username-initials">{userDetails?.username[0]}</div>
                            </div>
                        </> : <div className="welcome-topbar-right-buttons-container">
                            <div className="welcome-topbar-signin-button welcome-topbar-right-button welcome-login-button" onClick={goToLinks}>{t('WELCOME_PAGE_SIGN_IN_BUTTON_TEXT')}</div>
                            <div className="welcome-topbar-signup-button welcome-topbar-right-button welcome-signup-button" onClick={goToSignUp}>{t('WELCOME_PAGE_SIGN_UP_BUTTON_TEXT')}</div>
                        </div>
                    }
                    <PaydinMenu
                        widthPx={DASHBOARD_TOPBAR_USER_MENU_WIDTH_PX}
                        isOpen={userMenuState.isOpen}
                        anchorElement={userMenuState.anchorElement}
                        onClose={userMenuState.onClose}
                        direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                        borderRadius={DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX}
                    >
                        <div className="dashboard-topbar-user-menu-container">
                            <div className="dashboard-topbar-user-menu-titles-container">
                                <div className="dashboard-topbar-user-menu-username-initials-circle">
                                    <div className="dashboard-topbar-user-menu-username-initials">{userDetails?.username[0] ?? ""}</div>
                                </div>
                                <div className="dashboard-topbar-user-menu-names-container">
                                    <div className="dashboard-topbar-user-menu-username">@{userDetails?.username}</div>
                                    <div className="dashboard-topbar-user-menu-name">{userDetails?.name}</div>
                                </div>
                            </div>
                            <div className="dashboard-topbar-user-menu-plan-section dashboard-topbar-user-menu-section">
                                <div className="dashboard-topbar-user-menu-plan-section-title dashboard-topbar-user-menu-section-title">{t('DASHBOARD_TOPBAR_USER_MENU_PLAN_SECTION_TITLE')}</div>
                                <div className="dashboard-topbar-user-menu-plan-section-content dashboard-topbar-user-menu-section-content">
                                    <div className="dashboard-topbar-user-menu-plan-section-plan-name-container">
                                        <div className="dashboard-topbar-user-menu-plan-section-plan-name">{userDetails?.subscriptionPlan ?? ''}</div>
                                        <div className="dashboard-topbar-user-menu-plan-section-plan-upgrade-button" onClick={goToPricingPlans}>{t('DASHBOARD_TOPBAR_USER_MENU_PLAN_SECTION_UPGRADE_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                hasShopifyStore() && <div className="dashboard-topbar-user-menu-store-section dashboard-topbar-user-menu-section">
                                    <div className="dashboard-topbar-user-menu-store-section-title dashboard-topbar-user-menu-section-title">{t('DASHBOARD_TOPBAR_USER_MENU_STORE_SECTION_TITLE')}</div>
                                    <div className="dashboard-topbar-user-menu-store-section-content dashboard-topbar-user-menu-section-content">
                                        <div className="dashboard-topbar-user-menu-store-section-shopify-provider-container">
                                            <ImageBox
                                                className="dashboard-topbar-user-menu-store-section-store-provider-image-container"
                                                imageClassName="dashboard-topbar-user-menu-store-section-store-provider-image"
                                                image={SHOPIFY_LOGO_URL} />
                                            <div className="dashboard-topbar-user-menu-store-section-store-name">{userDetails?.businessName ?? ""}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="dashboard-topbar-user-menu-account-section dashboard-topbar-user-menu-section">
                                <div className="dashboard-topbar-user-menu-account-section-title dashboard-topbar-user-menu-section-title">{t('DASHBOARD_TOPBAR_USER_MENU_ACCOUNT_SECTION_TITLE')}</div>
                                <div className="dashboard-topbar-user-menu-account-section-content dashboard-topbar-user-menu-section-content">
                                    <div className="dashboard-topbar-user-menu-account-section-option-my-account-container dashboard-topbar-user-menu-option-container" onClick={goToMyAccount}>
                                        <FiUser className='dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-account-section-option-my-account-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_ACCOUNT_SECTION_MY_ACCOUNT_OPTION_TEXT')}</div>
                                    </div>
                                    {/* <div className="dashboard-topbar-user-menu-account-section-option-billing-container disabled dashboard-topbar-user-menu-option-container">
                                        <HiOutlineCurrencyDollar className='dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-account-section-option-billing-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_ACCOUNT_SECTION_BILLING_OPTION_TEXT')}</div>
                                    </div> */}
                                    <div className="dashboard-topbar-user-menu-account-section-option-logout-container dashboard-topbar-user-menu-option-container" onClick={showLoginConfirmationDialog}>
                                        <CiLogout className='dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-account-section-option-logout-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_ACCOUNT_SECTION_LOGOUT_OPTION_TEXT')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-topbar-user-menu-support-section dashboard-topbar-user-menu-section">
                                <div className="dashboard-topbar-user-menu-support-section-title dashboard-topbar-user-menu-section-title">{t('DASHBOARD_TOPBAR_USER_MENU_SUPPORT_SECTION_TITLE')}</div>
                                <div className="dashboard-topbar-user-menu-support-section-content dashboard-topbar-user-menu-section-content">
                                    <div className="dashboard-topbar-user-menu-support-section-option-privacy-policy-container dashboard-topbar-user-menu-option-container" onClick={openPrivacyPolicyPage}>
                                        <MdOutlinePrivacyTip className='dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-support-section-option-privacy-policy-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_SUPPORT_SECTION_PRIVACY_POLICY_OPTION_TEXT')}</div>
                                    </div>
                                    <div className="dashboard-topbar-user-menu-support-section-option-terms-and-conditions-container dashboard-topbar-user-menu-option-container" onClick={openTermsAndConditionsPage}>
                                        <GrDocumentText className='dashboard-topbar-user-menu-support-section-option-terms-and-conditions-image dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-support-section-option-terms-and-conditions-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_SUPPORT_SECTION_TERMS_AND_CONDICTIONS_OPTION_TEXT')}</div>
                                    </div>
                                    <div className="dashboard-topbar-user-menu-support-section-option-help-container dashboard-topbar-user-menu-option-container" onClick={openShopifyHelpPage}>
                                        <SlQuestion className='dashboard-topbar-user-menu-option-image' />
                                        <div className="dashboard-topbar-user-menu-support-section-option-help-text dashboard-topbar-user-menu-option-text">{t('DASHBOARD_TOPBAR_USER_MENU_SUPPORT_SECTION_HELP_OPTION_TEXT')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PaydinMenu>
                </> : <>
                    <FiMenu className='layout-topbar-right-section-menu-image' onClick={openMoreOptionsDrawer} />
                    <PaydinDrawer
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={optionsDrawerState.isOpen}
                        onOpen={optionsDrawerState.onOpen}
                        onClose={optionsDrawerState.onClose}
                        hasPadding={false}
                        topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    >
                        {optionsDrawerState.children}
                    </PaydinDrawer>
                </>
            }
            {
                isLogoutConfirmationDialogOpen && <PaydinDialog
                    title={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_TITLE')}
                    message={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_MESSAGE')}
                    isDialogOpen={isLogoutConfirmationDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                    rightButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={logOut}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                    areButtonsInColumn={isMobile}
                />
            }
        </div>
    )
}