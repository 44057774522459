import React from 'react'
import './WelcomePageSection.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a section in the welcome page.
 * Each section contains a generic left section and a generic right section.
 * @param {DOMElement} leftSection - The left section 
 * @param {DOMElement} rightSection - The right section 
 */
export default function WelcomePageSection({
    leftSection,
    rightSection,
    backgroundColor
}) {
    return (
        <div className={isMobile ? 'mobile-welcome-page-section-container' : 'welcome-page-section-container'} style={{ backgroundColor: backgroundColor ?? 'transparent' }}>
            <div className={isMobile ? 'mobile-welcome-page-section-content' : 'welcome-page-section-content'}>
                <div className={`welcome-page-section-content-left ${isMobile ? 'mobile-welcome-page-section-side' : 'welcome-page-section-side'}`}>{leftSection}</div>
                <div className={`${isMobile ? 'mobile-welcome-page-section-content-right' : 'welcome-page-section-content-right'} ${isMobile ? 'mobile-welcome-page-section-side' : 'welcome-page-section-side'}`}>{rightSection}</div>
            </div>
        </div>
    )
}