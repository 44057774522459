import React, { useEffect, useState } from 'react'
import './CreateNewPasswordPage.css'
import { useTranslation } from 'react-i18next'
import TopBar from 'components/TopBar/TopBar'
import { BOXED_MESSAGE_TYPES, PAYLINK_LOGO_MAX_WIDTH_PX, PAYLINK_LOGO_URL } from 'constants/general.constants'
import {
    AiOutlineEye,
    AiOutlineEyeInvisible
} from 'react-icons/ai'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { USER_PASSWORD_MIN_LENGTH } from 'constants/general.constants'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { getQueryVariable } from 'services/SystemService'
import { LOGIN_PAGE_URL, RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY } from 'constants/routes.constants'
import { isPasswordSecure } from 'services/utils'
import { ResetPasswordAPI } from 'api/auth/auth'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'

export default function CreateNewPasswordPage() {
    const history = useHistoryContext()
    const { t } = useTranslation()

    const [isAbleToResetPassword, setIsAbleToResetPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [inputState, setInputState] = useState({
        password: '',
        confirmPassword: ''
    })
    const [dialogProperties, setDialogProperties] = useState({
        title: '',
        leftButtonText: '',
        rightButtonText: ''
    })
    const [isPasswordInsecure, setIsPasswordInsecure] = useState(false)
    const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false)
    const [isPasswordResetErrorDialogOpen, setIsPasswordResetErrorDialogOpen] = useState(false)

    useEffect(() => {
        setIsAbleToResetPassword(isPasswordValid() && isConfirmPasswordValid())
    }, [inputState])

    function createNewPassword() {
        if (isPasswordSecure(inputState.password)) {
            setIsPasswordInsecure(false)
            setIsResetPasswordInProgress(true)
            const resetPasswordToken = getQueryVariable(RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY, "")
            ResetPasswordAPI(resetPasswordToken, inputState.password)
                .then(() => {
                    history.replace(LOGIN_PAGE_URL)
                }).catch(error => {
                    console.log(error)
                    setIsResetPasswordInProgress(false)
                    setDialogProperties({
                        title: t('CREATE_NEW_PASSWORD_PAGE_RESET_PASSWORD_ERROR_DIALOG_TITLE'),
                        leftButtonText: t('CREATE_NEW_PASSWORD_PAGE_RESET_PASSWORD_ERROR_DIALOG_LEFT_BUTTON_TEXT')
                    })
                    setIsPasswordResetErrorDialogOpen(true)
                })
        } else {
            setIsPasswordInsecure(true)
        }
    }

    function handleInputChange(e) {
        setInputState(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    function togglePasswordVisibility() {
        setShowPassword(prev => !prev)
    }

    function isPasswordValid() {
        return inputState.password.length >= USER_PASSWORD_MIN_LENGTH
    }

    function isConfirmPasswordValid() {
        return inputState.password === inputState.confirmPassword
    }

    function closeDialog() {
        setIsPasswordResetErrorDialogOpen(false)
    }

    return (
        <div className='create-new-password-page-container'>
            <form className="create-new-password-page-content">
                <h1 className="create-new-password-page-title">{t('CREATE_NEW_PASSWORD_PAGE_TITLE')}</h1>
                {
                    isPasswordInsecure && <BoxedMessage
                        message={t('PASSWORD_INSECURE_ERROR_MESSAGE')}
                        type={BOXED_MESSAGE_TYPES.ERROR} />
                }
                <div className="create-new-password-page-password-input-button-section">
                    <div className="create-new-password-input-field-container create-new-password-input-field-container">
                        <input className='input-field' placeholder={t('CREATE_NEW_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER')} name='password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} />
                        {
                            showPassword ? <AiOutlineEye className='login-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                                : <AiOutlineEyeInvisible className='login-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                        }
                    </div>
                    <div className="create-new-password-page-confirm-password-input-field-container create-new-password-input-field-container">
                        <input className='input-field' placeholder={t('CREATE_NEW_PASSWORD_PAGE_CONFIRM_PASSWORD_INPUT_PLACEHOLDER')} name='confirmPassword' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} />
                        {
                            showPassword ? <AiOutlineEye className='create-new-password-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                                : <AiOutlineEyeInvisible className='create-new-password-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                        }
                    </div>
                    <LoaderButton
                        className='create-new-password-reset-password-button'
                        buttonText={t('CREATE_NEW_PASSWORD_PAGE_RESET_PASSWORD_BUTTON_TEXT')}
                        isLoading={isResetPasswordInProgress}
                        isDisabled={!isAbleToResetPassword}
                        onClick={createNewPassword}
                        renderAsButton={true} />
                </div>
            </form>
            {
                isPasswordResetErrorDialogOpen && <PaydinDialog
                    title={dialogProperties.title}
                    isDialogOpen={isPasswordResetErrorDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={dialogProperties.leftButtonText}
                    rightButtonText={dialogProperties.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    isLeftButtonWithLoader={false}
                    isRightButtonWithLoader={false}
                />
            }
        </div>
    )
}