import React, { useCallback, useState } from 'react'
import './DashboardTopBar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import {
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY,
    PAYDIN_DRAWER_DIRECTION,
    PAYLINK_LOGO_URL,
    PAYLINK_SMALL_LOGO_URL
} from 'constants/general.constants'
import { BiLink } from 'react-icons/bi'
import { RiPaletteLine } from 'react-icons/ri'
import { TbDeviceDesktopAnalytics } from 'react-icons/tb'
import { FiDatabase, FiUser } from 'react-icons/fi'
import { BsPlusSquare } from 'react-icons/bs'
import { CiLogout } from 'react-icons/ci'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { GrDocumentText } from 'react-icons/gr'
import { SlQuestion } from 'react-icons/sl'
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import {
    ACCOUNT_PAGE_URL,
    ADMIN_PAGES_URL_PREFIX,
    ANALYTICS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    LINKS_PAGE_URL,
    LOGIN_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    STORE_SETTINGS_PAGE_URL_PREFIX,
    TERMS_PAGE_URL,
    SHOPIFY_HELP_PAGE_URL,
} from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import { CREATE_NEW_LINK_PAGE_URL } from 'constants/routes.constants'
import LayoutTopbarRightSection from 'components/LayoutTopbarRightSection/LayoutTopbarRightSection'

/**
 * Represents the dashboard top bar for all admin pages.
 */
export default function DashboardTopBar() {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()
    const { logout } = useAuthContext()

    const [isLogoutConfirmationDialogOpen, setIsLogoutConfirmationDialogOpen] = useState(false)
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: openMoreOptionsDrawer,
        onClose: () => { },
        children: <></>
    })

    function showLoginConfirmationDialog() {
        if (isMobile)
            optionsDrawerState.onClose()

        setTimeout(() => {
            setIsLogoutConfirmationDialogOpen(true)
        }, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY)
    }

    function goToLinks() {
        if (window.location.pathname === LINKS_PAGE_URL) {
            return
        }

        history.push(LINKS_PAGE_URL)
    }

    function goToAppearance() {
        if (window.location.pathname.startsWith(`${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}`)) {
            return
        }

        history.push(`${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}/${userDetails?.businessId}`)
    }

    function goToAnalytics() {
        if (window.location.pathname === ANALYTICS_PAGE_URL) {
            return
        }

        history.push(ANALYTICS_PAGE_URL)
    }

    function goToInfluencers() {
        if (window.location.pathname === INFLUENCERS_PAGE_URL) {
            return
        }

        history.push(INFLUENCERS_PAGE_URL)
    }

    function logOut() {
        logout()
        history.push(LOGIN_PAGE_URL)
    }

    function goToMyAccount() {
        if (window.location.pathname === ACCOUNT_PAGE_URL) {
            return
        }

        history.push(ACCOUNT_PAGE_URL)
    }

    function closeDialog() {
        setIsLogoutConfirmationDialogOpen(false)
    }

    function closeMoreOptionsDrawer() {
        history.goBack()
    }

    function createNewLink() {
        history.push(CREATE_NEW_LINK_PAGE_URL)
    }

    function goToPrivacyPolicy() {
        history.push(PRIVACY_POLICY_PAGE_URL)
    }

    function goToTermsAndConditions() {
        history.push(TERMS_PAGE_URL)
    }

    function openShopifyHelpPage() {
        history.push(SHOPIFY_HELP_PAGE_URL)
    }

    function openMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: true,
            onClose: closeMoreOptionsDrawer,
            children: <>
                <div className="mobile-dashboard-topbar-more-options-drawer-option-my-account mobile-dashboard-topbar-more-options-drawer-option" onClick={goToMyAccount}>
                    <FiUser className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_MY_ACCOUNT')}</div>
                </div>
                <div className={`mobile-dashboard-topbar-more-options-drawer-option-appearance mobile-dashboard-topbar-more-options-drawer-option ${!hasShopifyStore() ? 'disabled' : ''}`} onClick={goToAppearance}>
                    <RiPaletteLine className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_APPEARANCE')}</div>
                </div>
                {/* TODO leave this comment untill the public app is approved */}
                {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-billing mobile-dashboard-topbar-more-options-drawer-option disabled">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_BILLING')}</div> */}
                {/* TODO leave this comment until re-adding the archive page */}
                {/* <div className="mobile-dashboard-topbar-more-options-drawer-option-archive mobile-dashboard-topbar-more-options-drawer-option">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_ARCHIVE')}</div> */}
                <div className="mobile-dashboard-topbar-more-options-drawer-option-privacy-policy mobile-dashboard-topbar-more-options-drawer-option" onClick={goToPrivacyPolicy}>
                    <MdOutlinePrivacyTip className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_PRIVACY_POLICY')}</div>
                </div>
                <div className="mobile-dashboard-topbar-more-options-drawer-option-terms-and-conditions mobile-dashboard-topbar-more-options-drawer-option" onClick={goToTermsAndConditions}>
                    <GrDocumentText className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_TERMS_AND_CONDITIONS')}</div>
                </div>
                <div className="mobile-dashboard-topbar-more-options-drawer-option-help mobile-dashboard-topbar-more-options-drawer-option" onClick={openShopifyHelpPage}>
                    <SlQuestion className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_HELP')}</div>
                </div>
                <div className="mobile-dashboard-topbar-more-options-drawer-option-logout mobile-dashboard-topbar-more-options-drawer-option" onClick={showLoginConfirmationDialog}>
                    <CiLogout className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGOUT')}</div>
                </div>
                <div className="mobile-dashboard-topbar-more-options-drawer-option-cancel mobile-dashboard-topbar-more-options-drawer-option" onClick={closeMoreOptionsDrawer}>
                    <AiOutlineClose className='mobile-dashboard-topbar-more-options-drawer-option-image' />
                    <div className="mobile-dashboard-topbar-more-options-drawer-option-text">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL')}</div>
                </div>
            </>
        }))
        history.addBackHandler(() => setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        })))
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    return (
        <>
            <BrowserView>
                <div className='dashboard-topbar-container'>
                    <div className="dashboard-topbar-content">
                        <ImageBox
                            className='dashboard-topbar-image'
                            image={PAYLINK_LOGO_URL}
                        />
                        <div className="dashboard-topbar-buttons-container">
                            <div onClick={goToLinks} className={`dashboard-topbar-links-button dashboard-button ${window.location.pathname === LINKS_PAGE_URL ? 'selected' : ''}`}>
                                <BiLink className='dashboard-topbar-links-button-image dashboard-topbar-button-image' />
                                <div className="dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_LINKS_BUTTON_TEXT')}</div>
                            </div>
                            <div onClick={goToAppearance} className={`dashboard-topbar-appearance-button dashboard-button ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + STORE_SETTINGS_PAGE_URL_PREFIX) ? 'selected' : ''}`}>
                                <RiPaletteLine className='dashboard-topbar-appearance-button-image dashboard-topbar-button-image' />
                                <div className="dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_APPEARANCE_BUTTON_TEXT')}</div>
                            </div>
                            <div onClick={goToAnalytics} className={`dashboard-topbar-analytics-button dashboard-button ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname === ANALYTICS_PAGE_URL ? 'selected' : ''}`}>
                                <TbDeviceDesktopAnalytics className='dashboard-topbar-analytics-button-image dashboard-topbar-button-image' />
                                <div className="dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_ANALYTICS_BUTTON_TEXT')}</div>
                            </div>
                            {/* TODO leave this comment untill the public app is approved */}
                            {/* <div onClick={goToInfluencers} id='disabled' className={`dashboard-topbar-influencers-button dashboard-button ${window.location.pathname === INFLUENCERS_PAGE_URL ? 'selected' : ''}`}>
                                <FiUsers className='dashboard-topbar-influencers-button-image dashboard-topbar-button-image' />
                                <div className="dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_INFLUENCERS_BUTTON_TEXT')}</div>
                            </div> */}
                        </div>
                        <LayoutTopbarRightSection />
                    </div>
                    <div className="dashboard-topbar-bottom-line" />
                </div>
            </BrowserView>

            <MobileView>
                <div className='mobile-dashboard-topbar-container'>
                    <div className="mobile-dashboard-topbar-content">
                        <div className="mobile-dashboard-topbar-paylink-title-container">
                            <div className="mobile-dashboard-topbar-paylink-logo-container">
                                <ImageBox
                                    image={PAYLINK_SMALL_LOGO_URL}
                                    className='mobile-dashboard-topbar-paylink-logo'
                                />
                                <div className="mobile-dashboard-topbar-store-name">{hasShopifyStore() ? userDetails?.businessName : userDetails?.username}</div>
                            </div>
                            {hasShopifyStore() && <BsPlusSquare className="mobile-dashboard-topbar-create-new-link-button" onClick={createNewLink} />}
                        </div>
                        <div className="mobile-dashboard-topbar-buttons-container">
                            <div onClick={goToLinks} className={`mobile-dashboard-topbar-links-button mobile-dashboard-button mobile-dashboard-button ${window.location.pathname === LINKS_PAGE_URL ? 'selected' : ''}`}>
                                <BiLink className='mobile-dashboard-topbar-links-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_LINKS_BUTTON_TEXT')}</div>
                            </div>
                            <div onClick={goToAnalytics} className={`mobile-dashboard-topbar-analytics-button mobile-dashboard-button mobile-dashboard-button ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname === ANALYTICS_PAGE_URL ? 'selected' : ''}`}>
                                <TbDeviceDesktopAnalytics className='mobile-dashboard-topbar-analytics-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_ANALYTICS_BUTTON_TEXT')}</div>
                            </div>
                            {/* TODO leave this comment untill the public app is approved */}
                            {/* <div onClick={goToInfluencers} id='disabled' className={`mobile-dashboard-topbar-influencers-button mobile-dashboard-button ${window.location.pathname === INFLUENCERS_PAGE_URL ? 'selected' : ''}`}>
                                <FiUsers className='mobile-dashboard-topbar-influencers-button-image mobile-dashboard-topbar-button-image' />
                                <div className="mobile-dashboard-topbar-links-button-label">{t('DASHBOARD_TOPBAR_INFLUENCERS_BUTTON_TEXT')}</div>
                            </div> */}
                            <div onClick={openMoreOptionsDrawer} className='mobile-dashboard-topbar-more-options-button mobile-dashboard-button'>
                                <div className='mobile-dashboard-topbar-more-options-button-image mobile-dashboard-topbar-button-image'>
                                    <div className="mobile-dashboard-topbar-more-options-button-username-initials-cirlce">
                                        <div className="mobile-dashboard-topbar-more-options-button-text">{userDetails?.username[0] ?? ''}</div>
                                    </div>
                                </div>
                                <div className="mobile-dashboard-topbar-links-button-label">{t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_BUTTON_TEXT')}</div>
                            </div>
                        </div>
                    </div>
                    <PaydinDrawer
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={optionsDrawerState.isOpen}
                        onOpen={optionsDrawerState.onOpen}
                        onClose={optionsDrawerState.onClose}
                        hasPadding={false}
                        topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    >
                        {optionsDrawerState.children}
                    </PaydinDrawer>
                </div>
            </MobileView>
            {
                isLogoutConfirmationDialogOpen && <PaydinDialog
                    title={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_TITLE')}
                    message={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_MESSAGE')}
                    isDialogOpen={isLogoutConfirmationDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                    rightButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={logOut}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                    areButtonsInColumn={isMobile}
                />
            }
        </>
    )
}