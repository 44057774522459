import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from 'constants/storage.constants'
import React, { useContext, useState, useEffect } from 'react'
import { LocalSharedPreferences } from 'services/SharedPreferences'
import { GetLinksAPI } from 'api/links/links'
import { AuthAPI, LoginAPI, RegisterAPI } from 'api/auth/auth'
import {
    APPLICATION_READY_TIMEOUT_MILISECONDS,
    DEFAULT_CURRENCY_CODE,
    LINKS_PAGE_LINKS_FILTER_COUNT,
    NO_AUTH_PAGES
} from 'constants/general.constants'
import { LocalStorageGetItem } from 'services/LocalStorage'
import { ACCOUNT_VERIFICATION_PAGE_URL } from 'constants/routes.constants'

const userDetailsContext = React.createContext()
const userDetailsInsitialState = {
    name: '',
    username: '',
    brand: {},
    businessName: '',
    businessLogo: '',
    businessCurrency: DEFAULT_CURRENCY_CODE,
    subscriptionPlan: 'FREE',
    shopUrl: '',
    linksData: {
        links: [
            {
                linkId: '',
                images: [],
                createdAt: 0,
                linkAnalytics: {
                    views: 0,
                    revenue: 0,
                    checkouts: 0,
                    currency: DEFAULT_CURRENCY_CODE
                }
            }
        ]
    },
    theme: {
        checkoutButtonShape: 2,
        checkoutButtonBackgroundColor: '#000000',
        checkoutButtonBorderColor: '#000000',
        checkoutButtonFontColor: '#ffffff',
        cartButtonIcon: 1,
        discountFontColor: '#ffffff',
        discountBackgroundColor: '#000000'
    },
    isVerified: false,
    isAuthenticated: false
}

const AuthContext = React.createContext()
const fetchLinksContext = React.createContext()
const isReadyContext = React.createContext()
const isUserLoadedContext = React.createContext()

export function useUserDetailsContext() {
    return useContext(userDetailsContext)
}

export function useAuthContext() {
    return useContext(AuthContext)
}

export function useFetchLinksContext() {
    return useContext(fetchLinksContext)
}

export function useIsReadyContext() {
    return useContext(isReadyContext)
}

export function useIsUserLoadedContext() {
    return useContext(isUserLoadedContext)
}

/**
 * Providing an userDetails context to all children components.
 */
export default function UserProvider({ children, onUserLoaded, onReady }) {
    const [userDetails, setUserDetails] = useState(userDetailsInsitialState)
    const [isReady, setIsReady] = useState(false)
    const [isUserLoaded, setIsUserLoaded] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            onReady(true)
            setIsReady(true)
        }, APPLICATION_READY_TIMEOUT_MILISECONDS)
        if (shouldVerify()) { // checking if the authentication token exists because the verify request always sends the authentication token, if not exists, verify fails.
            verify()
        } else {
            setIsUserLoaded(true)
            onUserLoaded(true)
        }
    }, [])

    function shouldVerify() {
        const token = LocalStorageGetItem(AUTH_TOKEN_KEY_LOCAL_STORAGE, null)
        return token !== null && isNotInAuthPage()
    }

    function isNotInAuthPage() {
        return !NO_AUTH_PAGES.includes(window.location.pathname) && !window.location.pathname.startsWith(ACCOUNT_VERIFICATION_PAGE_URL)
    }

    function getThemeObject(userData) {
        return userData?.brand?.theme ? {
            checkoutButtonShape: userData?.brand?.theme?.checkout_button_shape ?? userDetailsInsitialState.theme.checkoutButtonShape,
            checkoutButtonBackgroundColor: userData?.brand?.theme?.checkout_button_background_color ?? userDetailsInsitialState.theme.checkoutButtonBackgroundColor,
            checkoutButtonBorderColor: userData?.brand?.theme?.checkout_button_border_color ?? userDetailsInsitialState.theme.checkoutButtonBorderColor,
            checkoutButtonFontColor: userData?.brand?.theme?.checkout_button_font_color ?? userDetailsInsitialState.theme.checkoutButtonFontColor,
            cartButtonIcon: userData?.brand?.theme?.cart_button_icon ?? userDetailsInsitialState.theme.cartButtonIcon,
            discountFontColor: userData?.brand?.theme?.discount_font_color ?? userDetailsInsitialState.theme.discountFontColor,
            discountBackgroundColor: userData?.brand?.theme?.discount_background_color ?? userDetailsInsitialState.theme.discountBackgroundColor
        } : userDetailsInsitialState.theme
    }

    function setUserProperties(userData) {
        setUserDetails(prev => ({
            ...prev,
            name: userData?.user?.name ?? "",
            username: userData?.user?.username ?? "",
            email: userData?.user?.email ?? "",
            brand: userData?.brand ?? {},
            businessId: userData?.brand?.business_id ?? "",
            businessName: userData?.brand?.business_name ?? "",
            businessLogo: userData?.brand?.logo_url ?? "",
            businessCurrency: userData?.brand?.currency ?? userDetailsInsitialState.businessCurrency,
            subscriptionPlan: userData?.brand?.subscription_plan ?? userDetailsInsitialState.subscriptionPlan,
            domain: userData?.brand?.domain ?? "",
            shippingAndReturnsUrl: userData?.brand?.shipping_and_return_url ?? "",
            theme: getThemeObject(userData),
            facebookPixelId: userData?.brand?.facebook_analytics_id ?? '',
            googleAnalyticsId: userData?.brand?.google_analytics_id ?? '',
            isVerified: true,
            isAuthenticated: true
        }))
    }

    function setUnauthenticatedUserProperties() {
        setUserDetails({
            ...userDetailsInsitialState
        })
    }

    function setLinkProperties(linksData) {
        setUserDetails(prev => {
            return {
                ...prev,
                linksData: {
                    links: linksData.links.map(link => {
                        return {
                            linkId: link?.link_id ?? 0,
                            images: link?.images && link?.images?.length > 0 ? link?.images : [],
                            createdAt: link?.created_at ?? 0,
                            views: link?.visits ?? 0,
                            revenue: link?.revenue ?? 0,
                            isLinkExpired: link?.is_link_expired ?? false,
                            currency: link?.currency ?? (userDetails?.businessCurrency ?? userDetailsInsitialState.businessCurrency),
                            title: link?.description ?? "" // consider the description as the link's title
                        }
                    }).sort((link1, link2) => link2.createdAt - link1.createdAt),
                    lastKey: linksData.last_key,
                }
            }
        })
    }

    function fetchLinks(searchQuery, skip, shouldUpdateContext, onFinish = () => { }) {
        GetLinksAPI(userDetails?.businessId, searchQuery, LINKS_PAGE_LINKS_FILTER_COUNT, skip)
            .then((linksData) => {
                if (shouldUpdateContext)
                    setLinkProperties(linksData)
                onFinish(linksData)
            }).catch((error) => {
                setUserDetails(prev => {
                    return {
                        ...prev,
                        linksData: {
                            links: [],
                            lastKey: null,
                        },
                    }
                })
                onFinish(null)
            })
    }

    function setUserDetailsAndFetchLinks(userData, onFinish = () => { }, lastKey = null) {
        if (userData.user && userData.brand) {
            GetLinksAPI(userData.brand.business_id, '', LINKS_PAGE_LINKS_FILTER_COUNT, 0)
                .then((linksData) => {
                    setLinkProperties(linksData)
                    onFinish()
                }).catch((error) => {
                    setUserDetails(prev => {
                        return {
                            ...prev,
                            linksData: {
                                links: [],
                                lastKey: null,
                            },
                        }
                    })
                    onFinish()
                })
        } else {
            setUserDetails(prev => {
                return {
                    ...prev,
                    linksData: {}
                }
            })
            onFinish()
        }
    }

    function register(name, username, password, email, onSuccess = () => { }, onFailure = () => { }) {
        RegisterAPI(name, username, password, email)
            .then(onSuccess)
            .catch(onFailure)
    }

    function login(username, password, onLoggedIn) {
        LoginAPI(username, password)
            .then((userData) => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, userData.token)
                setUserProperties(userData)
                setUserDetailsAndFetchLinks(userData, () => {
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onLoggedIn(true)
                })
            }).catch((error) => {
                console.log(error)
                setUnauthenticatedUserProperties()
                setIsUserLoaded(true)
                onUserLoaded(true)
                onLoggedIn(false)
            })
    }

    function verify(onSuccess = () => { }, onFailure = () => { }) {
        AuthAPI()
            .then((userData) => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, userData.token)
                setUserProperties(userData)
                setUserDetailsAndFetchLinks(userData, () => {
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onSuccess(userData)
                })
            }).catch((error) => {
                setUnauthenticatedUserProperties()
                setIsUserLoaded(true)
                onUserLoaded(true)
                onFailure(error)
            })
    }

    function logout() {
        LocalSharedPreferences.remove_key(AUTH_TOKEN_KEY_LOCAL_STORAGE)
        setUserDetails({
            ...userDetailsInsitialState
        })
    }

    return (
        <userDetailsContext.Provider value={{ userDetails, setUserDetails }}>
            <AuthContext.Provider value={{ login, register, verify, logout }}>
                <fetchLinksContext.Provider value={fetchLinks}>
                    <isReadyContext.Provider value={isReady}>
                        <isUserLoadedContext.Provider value={isUserLoaded}>
                            {children}
                        </isUserLoadedContext.Provider>
                    </isReadyContext.Provider>
                </fetchLinksContext.Provider>
            </AuthContext.Provider>
        </userDetailsContext.Provider>
    );
}
