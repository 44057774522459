import React, { useEffect, useRef, useState } from 'react'
import './PaydinImageCropper.css'
import Cropper from 'react-easy-crop'
import { BiExpandAlt } from 'react-icons/bi'
import { SlMagnifierAdd } from 'react-icons/sl'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX,
    PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS_MENU_WIDTH_PX,
    PAYDIN_IMAGE_CROPPER_DEFAULT_ASPECT_RATIO,
    PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO,
    PAYDIN_IMAGE_CROPPER_OBJECT_FITS,
    PAYDIN_MENU_OPENING_DIRECTIONS
} from 'constants/general.constants'
import PaydinSlider from 'components/PaydinSlider/PaydinSlider'
import { getImageRatio } from 'services/imageUtils'
import { isMobile } from 'react-device-detect'

/**
 * Represents an image cropper that allows image manipulation.
 * @param {string} image - The image to crop  
 * @param {object} crop - The crop coordinates of the image  
 * @param {fucntion} setCrop - A function for setting the image crop  
 * @param {number} zoom - The zoom value of the image
 * @param {function} setZoom - A function for setting the image zoom  
 * @param {number} aspectRatio - The aspect ratio of the image  
 * @param {function} setAspectRatio - A function for setting the aspect ratio of the image  
 * @param {function} setCroppedAreaPixels - A function for setting the cropped area of the image, in pixels
 * @param {array} aspectRatiosArray - The aspect ratio options list  
 * @param {boolean} isLogoCropper - Determins whether the cropper is used for logo image or for general image
 * @param {boolean} isOriginalAspectRatioExcluded - Determins whether the original aspect ratio should be omitted or not
 */
export default function PaydinImageCropper({
    image,
    crop,
    setCrop,
    zoom,
    setZoom,
    aspectRatio,
    setAspectRatio,
    setCroppedAreaPixels,
    aspectRatiosArray,
    isLogoCropper = false,
    isOriginalAspectRatioExcluded = false
}) {
    const [userMenuState, setUserMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [objectFit, setObjectFit] = useState(null)
    const [aspectRatios, setAspectRatios] = useState([])

    const objectFitRef = useRef(null) // used to store the ideal object fit of the cropper

    useEffect(() => {
        async function addOriginalAspectRatio() {
            const imageDimens = await getImageRatio(image)
            const originalAspectRatio = imageDimens ? imageDimens.width / imageDimens.height : 1
            objectFitRef.current = isLogoCropper ? PAYDIN_IMAGE_CROPPER_OBJECT_FITS.HORIZONTAL_COVER : (imageDimens.width >= imageDimens.height ? PAYDIN_IMAGE_CROPPER_OBJECT_FITS.VERTICAL_COVER : PAYDIN_IMAGE_CROPPER_OBJECT_FITS.HORIZONTAL_COVER)

            if (isOriginalAspectRatioExcluded) {
                onAspectRatioChange({ ratio: PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO })
                setAspectRatios([PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO])
            } else {
                onAspectRatioChange({ ratio: PAYDIN_IMAGE_CROPPER_DEFAULT_ASPECT_RATIO })
                setAspectRatios([
                    {
                        text: 'original',
                        ratio: originalAspectRatio
                    },
                    ...aspectRatiosArray
                ])
            }
        }

        addOriginalAspectRatio()
    }, [])

    function onCropChange(crop) {
        setCrop(crop)
    }

    function onZoomChange(zoom) {
        setZoom(zoom)
    }

    function onCropComplete(croppedArea, croppedAreaPixels) {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    function onAspectRatioChange(aspectRatio) {
        setAspectRatio(aspectRatio.ratio)
        if (aspectRatio.text === 'original')
            setObjectFit(PAYDIN_IMAGE_CROPPER_OBJECT_FITS.CONTAIN)
        else
            setObjectFit(objectFitRef?.current)
    }

    function openRatiosMenu(e) {
        setUserMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            width: PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS_MENU_WIDTH_PX,
            borderRadius: DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX,
            onClose: () => setUserMenuState(prev => ({
                ...prev,
                isOpen: false
            })),
            children: aspectRatios.map(aspectRatio => <div
                key={aspectRatio.text + aspectRatio.ratio}
                className='paydin-image-cropper-aspect-ratio-option'
                onClick={() => onAspectRatioChange(aspectRatio)}>{aspectRatio.text}</div>
            )
        })
    }

    function openZoomMenu(e) {
        setUserMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            width: '150px',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            onClose: () => setUserMenuState(prev => ({
                ...prev,
                isOpen: false
            })),
            children: <PaydinSlider initialValue={zoom} onValueChange={onZoomChange} />
        })
    }

    return (
        <div className='paydin-image-cropper-container'>
            {
                objectFit && <><Cropper
                    image={image}
                    zoom={zoom}
                    crop={crop}
                    aspect={aspectRatio}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    objectFit={objectFit}
                />
                    <div className="paydin-image-cropper-controls">
                        {/* if isLogoCropper is true, then the aspect ratio picker button is irrelevant */}
                        {!isLogoCropper && <BiExpandAlt className='paydin-image-cropper-aspect-ratio-button paydin-image-cropper-button' onClick={openRatiosMenu} />}
                        <SlMagnifierAdd className='paydin-image-cropper-zoom-button paydin-image-cropper-button' onClick={openZoomMenu} />
                    </div>
                </>
            }
            <PaydinMenu
                widthPx={userMenuState.width}
                isOpen={userMenuState.isOpen}
                anchorElement={userMenuState.anchorElement}
                onClose={userMenuState.onClose}
                direction={PAYDIN_MENU_OPENING_DIRECTIONS.LEFT}
                borderRadius={userMenuState.borderRadius}
                paddingLeftPx={userMenuState.paddingLeft}
                paddingRightPx={userMenuState.paddingRight}
            >
                {userMenuState.children}
            </PaydinMenu>
        </div>
    )
}