const debug = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: "http://127.0.0.1:5001",
    frontend_url: "http://127.0.0.1:3000/",
    dashboard_url: "http://127.0.0.1:3000/",
}

const dev = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-dev.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}`,
    frontend_url: `https://dev-pl.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
    dashboard_url: `https://dev.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
}

const staging = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-staging.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}`,
    frontend_url: `https://staging-pl.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
    dashboard_url: `https://staging.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
}

const prod = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}`,
    frontend_url: `https://pl.${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
    dashboard_url: `https://${process.env.REACT_APP_APPLICATION === "payli" ? "payli.in" : "payli.link"}/`,
}

export const environment = (() => {
    switch (process.env.REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "staging":
            return staging;
        case "prod":
            return prod;
        default:
            return null;
    }
})()