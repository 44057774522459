import React from 'react'
import './PaydinSwitch.css'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

/**
 * Represents a custom switch base on MUI {@link Switch}.
 * @param {boolean} disabled - Determins whether the switch is disabled or not
 * @param {boolean} checked - Determins whether the switch is checked or not
 * @param {function} setChecked - A function that sets the checked status of this switch
 * @param {number} switchWidth - The switch width in pixels
 * @param {number} switchHeight - The switch height in pixels
 * @param {number} switchPadding - The switch padding in pixels
 * @param {string} switchColor - The switch color
 * @param {number} thumbHeight - The thumb width in pixels
 * @param {number} thumbWidth - The thumb height in pixels
 */
export default function PaydinSwitch({
    disabled,
    checked = false,
    setChecked = () => { },
    switchWidth = 28,
    switchHeight = 16,
    switchPadding = 0,
    switchColor = '#1890ff',
    thumbHeight = 12,
    thumbWidth = 12
}) {

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: `${switchWidth}px`,
        height: `${switchHeight}px`,
        padding: `${switchPadding}px`,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {},
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: 'white',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: switchColor,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: `${thumbWidth}px`,
            height: `${thumbHeight}px`,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: '24px',
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));

    return (
        <AntSwitch
        disabled={disabled}
            checked={checked}
            onChange={() => setChecked(prev => !prev)}
        />
    )
}