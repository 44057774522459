import { request_auth } from 'services/RequestWrapper';
import { environment } from "conf";
import { 
    LINKS_API_ROUTE, 
    EDIT_LINK_API_URL_SUFFIX, 
    CREATE_LINK_API_URL_SUFFIX, 
    DELETE_LINK_API_URL_SUFFIX,
    BUSINESS_ANALYTICS_API_URL_PREFIX,
    ANALYTICS_API_URL_SUFFIX,
    LINK_ANALYTICS_API_URL_PREFIX,
    ARCHIVE_LINKS_API_URL_PREFIX,
    ARCHIVE_LINKS_API_URL_SUFFIX
} from 'constants/api.constants';

export function GetLinksAPI(businessId, searchQuery, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}/${businessId}?q=${searchQuery}&count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetLinkDetailsAPI(linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function UpdateLinkDetailsAPI(businessId, linkId, images, inventory_ids, promocode, description, shoppingURL, isVanishLink, expiryDate) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                images,
                inventory_ids,
                promocode,
                description,
                business_id: businessId,
                shopping_redirect_url: shoppingURL,
                is_vanish_link: isVanishLink,
                expiry_date: expiryDate
            }
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}/` + EDIT_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve();
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function CreateLinkDetailsAPI(businessId, images, inventory_ids, promocode, description, shoppingURL, isVanishLink, expiryDate) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                images,
                inventory_ids,
                promocode,
                description,
                business_id: businessId,
                shopping_redirect_url: shoppingURL,
                is_vanish_link: isVanishLink,
                expiry_date: expiryDate
            }
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + '/' + CREATE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve();
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function DeleteLinkAPI(linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        
        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}` + '/' + DELETE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

/*
    Retrieves analytics' data of a specific business.

    Method: [GET]
    URL: /admin/v1/links/analytics/business/<business_id>/overview

    Route params:
    =============
        (*) business_id - The is of the business
    
    Query params:
    ==============
        (*) from_date - The start of the time range of the analytics data
        (*) to_date - The end of the time range of the analytics data
*/
export function GetBusinessAnalytics(businessId, fromDate, toDate) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${BUSINESS_ANALYTICS_API_URL_PREFIX}/${businessId}${ANALYTICS_API_URL_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves analytics' data of a specific link.

    Method: [GET]
    URL: /admin/v1/links/analytics/username/<username>/id/<link_id>/overview

    Route params:
    =============
        (*) username - The username of the user
        (*) link_id - The id of the link
    
    Query params:
    ==============
        (*) from_date - The start of the time range of the analytics data
        (*) to_date - The end of the time range of the analytics data
*/
export function GetLinkAnalytics(username, linkId, fromDate, toDate) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINK_ANALYTICS_API_URL_PREFIX}/username/${username}/id/${linkId}${ANALYTICS_API_URL_SUFFIX}?from_date=${fromDate}&to_date=${toDate}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the archived links.

    Method: [PUT]
    URL: /admin/v1/links/<business_id>/archive

*/
export function GetArchiveLinks(businessId, lastKey) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                last_key: lastKey
            }
        }

        const url = `${environment.base_url}${ARCHIVE_LINKS_API_URL_PREFIX}/${businessId}${ARCHIVE_LINKS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}