import React from 'react'
import './WelcomeTopBar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { PAYLINK_LOGO_URL } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import {
    PRICING_PLANS_PAGE_URL,
    WELCOME_PAGE_URL
} from 'constants/routes.constants'
import LayoutTopbarRightSection from 'components/LayoutTopbarRightSection/LayoutTopbarRightSection'
import { isMobile } from 'react-device-detect'


/**
 * Represents the topbar of the welcome page.
 */
export default function WelcomeTopBar() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function goToPricing() {
        if (window.location.pathname !== PRICING_PLANS_PAGE_URL)
            history.push(PRICING_PLANS_PAGE_URL)
    }

    return (
        <div className={`${isMobile ? 'mobile-welcome-topbar-container' : 'welcome-topbar-container'}`}>
            <ImageBox
                image={PAYLINK_LOGO_URL}
                className='welcome-topbar-logo-image'
            />
            {
                [WELCOME_PAGE_URL, PRICING_PLANS_PAGE_URL].includes(window.location.pathname) && <>
                    {
                        !isMobile && <div className="welcome-topbar-center-buttons-container">
                            <div className="welcome-topbar-pricing-button welcome-topbar-center-button" onClick={goToPricing}>{t('WELCOME_TOPBAR_PRICING_BUTTON_TEXT')}</div>
                            <div className="welcome-topbar-learn-button welcome-topbar-center-button">{t('WELCOME_TOPBAR_LEARN_BUTTON_TEXT')}</div>
                        </div>
                    }
                    <LayoutTopbarRightSection />
                </>
            }
        </div>
    )
}