
export function LocalStorageGetItem(key, initialValue) {
	const item = window.localStorage.getItem(key);
	return item ? item : initialValue;
}

export function LocalStorageSetItem(key, value) {
	try {
		window.localStorage.setItem(key, value);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export function LocalStorageRemoveItem(key) {
	window.localStorage.removeItem(key);
}

export function LocalStorageClear() {
	window.localStorage.clear();
}
