import React from 'react'
import './ColoredAddMedia.css'
import { useTranslation } from 'react-i18next'

/**
 * Represents the image placeholder "Add Media" for uploading an image at link create/edit.
 * @param {number} plusFontSizePx - The font size of the plus sign in pixels
 * @param {number} textFontSizePx - The font size of the text in pixels
 * @param {function} onClick - A function for handling click on the container
 * @param {boolean} hasText - Determins whether the text is shown or not
 * @param {string} className - A class name for the container
 * @param {string} innerClassName - A class name for the inner container
 */
export default function ColoredAddMedia({
    plusFontSizePx = 35,
    textFontSizePx = 16,
    onClick = () => { },
    hasText = true,
    className = '',
    innerClassName = '',
    hasBackground = true
}) {
    const { t } = useTranslation()

    const containerStyles = {
        ...(hasBackground ? { backgroundColor: '#eff0f3' } : {})
    }

    const innerContainerStyles = {
        ...(hasBackground ? { backgroundColor: 'white' } : {})
    }

    return (
        <div className={`colored-add-media-container ${className}`} style={containerStyles} onClick={onClick}>
            <div className={`colored-add-media-button-inner-container ${innerClassName}`} style={innerContainerStyles}>
                {
                    hasText && <>
                        <div className="colored-add-media-button-plus" style={{ fontSize: `${plusFontSizePx}px` }}>+</div>
                        <div className="colored-add-media-button-text" style={{ fontSize: `${textFontSizePx}px` }}>{t('COLORED_ADD_MEDIA_TEXT')}</div>
                    </>
                }
            </div>
        </div>
    )
}