import React from 'react'
import './MobileLinkPreviewSimulator.css'
import ImageBox from 'components/ImageBox/ImageBox'
import MobileLinkPreviewProductsSlider from 'components/MobileLinkPreviewProductsSlider/MobileLinkPreviewProductsSlider'
import { GrMenu, GrCart } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import ColoredAddMedia from 'components/ColoredAddMedia/ColoredAddMedia'
import { useUserDetailsContext } from 'contexts/User'

/**
 * Represents a simulator for preview link on a mobile device.
 * @param {string} mainImageUrl - The image url of the large main image on the simulator display
 * @param {array} products - The products' list which their images will be shown under the main image
 * @param {boolean} hasBorder - Determins whether the simulator has a bold border or not 
 * @param {boolean} isFullScreen - Determins whether the simulator will be displayed as fullscreen or not 
 * @param {boolean} isLinkExpired - Determins whether the link displayed in the simulator is expired or not 
 */
export default function MobileLinkPreviewSimulator({
    mainImageUrl,
    products,
    hasBorder = true,
    isFullScreen = false,
    isLinkExpired = false
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()

    return (
        <div className='mobile-link-preview-simulator-container'
            style={{
                border: hasBorder ? '12px solid black' : 'none',
                maxHeight: isFullScreen ? 'unset' : '560px'
            }}
        >
            <div className="mobile-link-preview-simulator-content">
                <div className="mobile-link-preview-simulator-upper-container">
                    <div className="mobile-link-preview-simulator-topbar">
                        <GrMenu className="mobile-link-preview-simulator-topbar-menu-image mobile-link-preview-simulator-topbar-image" />
                        {userDetails?.businessLogo ? <ImageBox
                            className="mobile-link-preview-simulator-mobile-simulator-topbar-image"
                            image={userDetails?.businessLogo}
                        /> : <div className="mobile-link-preview-simulator-topbar-store-name">{userDetails?.businessName}</div>}
                        <GrCart className="mobile-link-preview-simulator-topbar-cart-image mobile-link-preview-simulator-topbar-image" />
                    </div>
                    {
                        mainImageUrl ? <ImageBox
                            className="mobile-link-preview-simulator-main-image"
                            image={mainImageUrl}
                            enlargeImageOnClick={true}
                        /> : <div className="mobile-link-preview-simulator-main-image-placeholder-container">
                            <ColoredAddMedia
                                plusFontSizePx={55}
                                textFontSizePx={15}
                                hasText={false}
                            />
                        </div>
                    }
                    <div className="mobile-link-preview-simulator-products-slider-container">
                        <MobileLinkPreviewProductsSlider
                            products={products}
                        />
                    </div>
                </div>
            </div>
            {
                isLinkExpired && <div className="mobile-link-preview-simulator-link-expired-frame">
                    <div className="mobile-link-preview-simulator-link-expired-text">{t('PREVIEW_PAGE_EXPIRED_LINK_TAG_TEXT')}</div>
                </div>
            }
        </div>
    )
}