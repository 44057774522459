import React from 'react'
import './BoxedMessage.css'
import { BOXED_MESSAGE_TYPES } from 'constants/general.constants'
import { IoWarningOutline } from 'react-icons/io5'
import { BiInfoCircle } from 'react-icons/bi'

/**
 * Represents a boxed message.
 * @param {string} message - The message to display
 * @param {string} type - The type of message to display
 */
export default function BoxedMessage({
  message,
  type = BOXED_MESSAGE_TYPES.ERROR
}) {
  return (
    <div className={`boxed-message-container ${type}`}>
      { type === BOXED_MESSAGE_TYPES.ERROR ? <IoWarningOutline className='boxed-message-image' /> : <BiInfoCircle className='boxed-message-image' /> }
      <div className="boxed-message-text">{message}</div>
    </div>
  )
}