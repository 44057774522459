import React from 'react'
import './StoreSettingsOptionPicker.css'

/**
 * Represents an options' picker for the store settings.
 * @param {DOMElement} selectedOption - The selected option's index
 * @param {function} setSelectedOption - A function for setting the selected option's index
 * @param {array} options - An options array to pick from
 */
export default function StoreSettingsOptionPicker({
    selectedOption,
    setSelectedOption,
    options = []
}) {
    return (
        <div className='store-settings-option-picker-container'>
            {
                options.map((option, index) => <div
                    key={option.value + index}
                    className={`store-settings-option-picker-option ${selectedOption === index ? 'selected' : ''}`}
                    onClick={() => setSelectedOption(index)}>
                    {option?.image}
                </div>)
            }
        </div>
    )
}