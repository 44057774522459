import React from 'react'
import './WelcomePageFooter.css'
import { useTranslation } from 'react-i18next'
import { 
    CONTACT_SUPPORT_ADDRESS,CONTACT_SUPPORT_EMAIL
} from 'constants/general.constants'
import { useHistoryContext } from 'contexts/History'
import { 
    LINKS_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    TERMS_PAGE_URL 
} from 'constants/routes.constants'
import { isMobile } from 'react-device-detect'

/**
 * Represents the footer element in the welcome page.
 */
export default function WelcomePageFooter() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function goToPrivacyPolicy() {
        history.push(PRIVACY_POLICY_PAGE_URL)
    }

    function goToTermsAndConditions() {
        history.push(TERMS_PAGE_URL)
    }

    function login() {
        history.push(LINKS_PAGE_URL)
    }

    function goToSignUp() {
        history.push(SIGN_UP_REQUEST_PAGE_URL)
    }

    return (
        <div className='welcome-page-footer' style={{ padding: isMobile ? '15px' : '40px' }}>
            <div className="welcome-page-footer-sections-container" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <div className={`${isMobile ? 'mobile-welcome-page-footer-section' : 'welcome-page-footer-section'}`}>
                    <div className="welcome-page-footer-section-title">{t('WELCOME_PAGE_FOOTER_SECTION_CONTACT_TITLE')}</div>
                    <div className="welcome-page-footer-section-content">
                        <a href={`mailto:${CONTACT_SUPPORT_EMAIL}`} className="welcome-page-footer-section-send-mail-option welcome-page-footer-section-option">{CONTACT_SUPPORT_EMAIL}</a>
                    </div>
                </div>
                <div className={`${isMobile ? 'mobile-welcome-page-footer-section' : 'welcome-page-footer-section'}`}>
                    <div className="welcome-page-footer-section-title">{t('WELCOME_PAGE_FOOTER_SECTION_TRUST_AND_LEGAL_TITLE')}</div>
                    <div className="welcome-page-footer-section-content">
                        <div className="welcome-page-footer-section-option welcome-page-footer-section-button" onClick={goToPrivacyPolicy}>{t('WELCOME_PAGE_FOOTER_SECTION_TRUST_AND_LEGAL_OPTION_PRIVACY_POLICY')}</div>
                        <div className="welcome-page-footer-section-option welcome-page-footer-section-button" onClick={goToTermsAndConditions}>{t('WELCOME_PAGE_FOOTER_SECTION_TRUST_AND_LEGAL_OPTION_TERMS_AND_CONDITIONS')}</div>
                    </div>
                </div>
            </div>
            <div className="welcome-page-footer-buttons-container">
                <div className="welcome-page-footer-buttons-box">
                    <div className="welcome-page-footer-signin-button welcome-page-footer-button welcome-login-button" style={{ padding: isMobile ? '8px' : '10px' }} onClick={login}>{t('WELCOME_PAGE_SIGN_IN_BUTTON_TEXT')}</div>
                    <div className="welcome-page-footer-signup-button welcome-page-footer-button welcome-signup-button" style={{ padding: isMobile ? '8px' : '10px' }} onClick={goToSignUp}>{t('WELCOME_PAGE_FOOTER_SIGN_UP_BUTTON_TEXT')}</div>
                </div>
            </div>
        </div>
    )
}