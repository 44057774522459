import React, { useEffect, useState } from 'react'
import './AnalyticsPage.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { addTimeToDate, formatNumber, shortenNumber } from 'services/utils'
import { GetBusinessAnalytics } from 'api/links/links'
import { useUserDetailsContext } from 'contexts/User'
import { DEFAULT_CURRENCY_CODE, TIME_UNITS } from 'constants/general.constants'
import AnalyticsDataCard from 'components/AnalyticsDataCard/AnalyticsDataCard'
import { getCurrencySymbol } from 'services/currency'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import AnalyticsTimeSelect from 'components/AnalyticsTimeSelect/AnalyticsTimeSelect'

export default function AnalyticsPage() {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()

    const ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS = [
        {
            name: t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LAST_WEEK'),
            id: 1,
            timeUnit: TIME_UNITS.DAY,
            value: 7
        },
        {
            name: t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LAST_MONTH'),
            id: 2,
            timeUnit: TIME_UNITS.MONTH,
            value: 1
        },
        {
            name: t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LAST_YEAR'),
            id: 3,
            timeUnit: TIME_UNITS.YEAR,
            value: 1
        },
        {
            name: t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LIFETIME'),
            id: 4
        }
    ]

    const [overviewData, setOverviewData] = useState({
        visits: 0,
        checkouts: 0,
        cr: 0,
        revenue: 0
    })
    const [overviewTimeIntervalName, setOverviewTimeIntervalName] = useState(t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LAST_MONTH'))
    const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true)

    useEffect(() => {
        setIsLoadingAnalytics(true)
        const overviewTimeInterval = ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(timeInterval => timeInterval.name === overviewTimeIntervalName)

        let todayDateString = null
        let targetDateString = null
        if (overviewTimeIntervalName !== t('ANALYTICS_PAGE_RETROACTIVE_REPORT_TIME_INTERVAL_LIFETIME')) {
            const todayDate = new Date()
            todayDateString = todayDate.toISOString().split('T')[0]
            const targetDate = addTimeToDate(todayDate, overviewTimeInterval.timeUnit, overviewTimeInterval.timeUnit === TIME_UNITS.DAY ? -overviewTimeInterval.value + 1 : -overviewTimeInterval.value)
            targetDateString = targetDate.toISOString().split('T')[0]
        }

        GetBusinessAnalytics(userDetails?.businessId, targetDateString, todayDateString)
            .then(response => {
                setOverviewData({
                    visits: response?.visits,
                    completedCheckouts: response?.checkouts,
                    requestedCheckouts: response?.requested_checkouts,
                    revenue: response?.revenue,
                    cr: (response?.checkouts / response?.visits) * 100,
                    currency: response?.currency ?? DEFAULT_CURRENCY_CODE
                })
                setIsLoadingAnalytics(false)
            })
            .catch(error => {
                setIsLoadingAnalytics(false)
            })
    }, [overviewTimeIntervalName])

    return (
        <>
            {isLoadingAnalytics && <LoaderPage styles={{ backgroundColor: '#ffffffaa' }} isFullScreen={true} />}
            <div className={isMobile ? 'mobile-analytics-page-container' : 'analytics-page-container' }>
                <div className={isMobile ? "mobile-analytics-page-title-container" : "analytics-page-title-container" }>
                    <div className="analytics-page-title">{t('ANALYTICS_PAGE_TITLE')}</div>
                    <AnalyticsTimeSelect
                        options={ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.map(timeInterval => timeInterval.name)}
                        optionState={overviewTimeIntervalName}
                        onOptionClick={setOverviewTimeIntervalName}
                    />
                </div>
                <div className="analytics-page-content">
                    <div className={isMobile ? "mobile-analytics-page-overall-statistics" : "analytics-page-overall-statistics" }>
                        <AnalyticsDataCard
                            title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_VISITS')}
                            value={shortenNumber(overviewData?.visits)}
                        />
                        <AnalyticsDataCard
                            title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_COMPLETED_CHECKOUTS')}
                            value={shortenNumber(overviewData?.completedCheckouts)}
                        />
                        <AnalyticsDataCard
                            title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REQUESTED_CHECKOUTS')}
                            value={shortenNumber(overviewData?.requestedCheckouts)}
                        />
                        <AnalyticsDataCard
                            title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REVENUE')}
                            value={`${getCurrencySymbol(overviewData?.currency)}${shortenNumber(overviewData?.revenue)}`}
                        />
                        <AnalyticsDataCard
                            title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_CR')}
                            value={`${formatNumber(overviewData?.cr)}%`}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}