import React from 'react'
import './ExternalAuthPage.css'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import { useUserDetailsContext } from 'contexts/User'

export default function ExternalAuthPage() {
    const { userDetails } = useUserDetailsContext()

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    return (
        <div className='external-auth-page-container'>
            <div className="external-auth-page-content top-bar-page">
                <ShopifyConnect hasShopifyStore={hasShopifyStore()} />
            </div>
        </div>
    )
}