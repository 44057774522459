import React, { useState } from 'react'
import './StoreSettingsColorPicker.css'
import { ChromePicker } from 'react-color'

/**
 * Represents a color picker for the store settings.
 * @param {string} title - The color title
 * @param {string} color - The current color
 * @param {function} setColor - A function for setting the current color
 */
export default function StoreSettingsColorPicker({
    title,
    color,
    setColor = () => { }
}) {

    const [isColorPickerShown, setIsColorPickerShown] = useState(false)

    function onColorChange(color) {
        setColor(color.hex)
    }

    return (
        <div className='store-settings-color-picker'>
            <div className="store-settings-color-frame-container">
                <div className="store-settings-color-frame" style={{ backgroundColor: color }} onClick={() => setIsColorPickerShown(true)}></div>
                {
                    isColorPickerShown && <div className='store-settings-color-picker-dialog-container'>
                        <ChromePicker
                            color={color}
                            onChange={onColorChange} 
                            disableAlpha={true}
                        />
                    </div>
                }
            </div>
            <div className="store-settings-color-description">
                <div className="store-settings-color-description-title">{title}</div>
                <div className="store-settings-color-description-value">{color}</div>
            </div>
            {isColorPickerShown && <div className="store-settings-color-picker-dialog-modal" onClick={() => setIsColorPickerShown(false)}></div>}
        </div>
    )
}