import React from 'react'
import './WelcomeLayout.css'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import WelcomeTopBar from 'components/WelcomeTopBar/WelcomeTopBar'
import { ACCOUNT_VERIFICATION_PAGE_URL, WELCOME_PAGE_URL } from 'constants/routes.constants'
import { isMobile } from 'react-device-detect'

/**
 * Represents a layout for all authentication pages.
 */
export default function WelcomeLayout({ children }) {

    function getLayoutClassName() {
        if (window.location.pathname === WELCOME_PAGE_URL) return isMobile ? 'mobile-topbar-padding-page' : 'topbar-padding-page'
        if (window.location.pathname.startsWith(ACCOUNT_VERIFICATION_PAGE_URL)) return 'no-padding-page'
        return ''
    }
    
    return (
        <BaseLayout>
            <WelcomeTopBar />
            <div className={`welcome-layout-content ${getLayoutClassName()}`}>
                {children}
            </div>
        </BaseLayout>
    )
}