import React from 'react'
import './ShopifyHelpPage.css'
import ImageBox from 'components/ImageBox/ImageBox'

export default function ShopifyHelpPage() {

    return (
        <div className='shopify-help-page-container'>
            <div className="shopify-help-page-title">How to connect your Shopify store to your Paylink account</div>
            <div className='shopify-help-page-section'>
                <ol>
                    <li>
                        <div className="shopify-help-page-section-title">Install the Payli sales channel app on your shopify store.</div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">Click signup on the Payli app to create a new paylink account if you do not already have an account.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/click_signup.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">Enter your detailsuse a unique username.</div>
                        <div className="shopify-help-page-section-title">Don't forget to use a unique username.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/signup.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">If the account is not connected to a store you can go to the connection page in the shopify admin by entering the shop URL and clicking on "Connect shopify store", or by going back to the aplication panel in the shopify admin.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/enter_shop_url.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">Click on "Connect my paylink account.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/connect.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">Click on "Connect my paylink account".</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/connect+store.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">The tab will close after succesfully connecting your shopify store to your paylink account</div>
                        <div className="shopify-help-page-section-title">On the shopify admin panel you can now see the account status.</div>
                        <div className="shopify-help-page-section-title">In the background, Paylink is syncing your products to your account.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paydin.cloudfront.paydin.io/application_resources/shopify-screenshots/shopify panel.png"}  alt={"helper1"}/>
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">In the admin page in paylink you will now be able to create shoppable links and connect all your products to each link per your choise.</div>
                        <div className="shopify-help-page-section-title">Start your Paylink journey!</div>
                    </li>
                </ol>
                <div className="shopify-help-page-section-title">Contact us on <a href = "mailto: support@paydin.net">support@paydin.net</a> for any further help needed.</div>
            </div>
        </div>
    )
}