import React, { useEffect, useRef, useState } from 'react'
import './LinksPage.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import {
    CREATE_NEW_LINK_PAGE_URL,
} from 'constants/routes.constants'
import {
    useFetchLinksContext,
    useIsReadyContext,
    useIsUserLoadedContext,
    useUserDetailsContext,
} from 'contexts/User'
import { useTranslation } from 'react-i18next'
import {
    EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS,
    IMAGE_PLACEHOLDER_URL,
    LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT,
    LINKS_PAGE_LINKS_FILTER_COUNT,
    LINKS_PAGE_LINK_COLUMN_COUNT,
    LINK_ITEM_HEIGHT_PX,
    LINK_PAGES_MAX_WIDTH,
    PAGE_DEFAULT_MAX_WIDTH,
    PAYLINK_SMALL_LOGO_URL
} from 'constants/general.constants'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import LinkItem from 'components/LinkItem/LinkItem'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'

/**
 * Represents the user's links page.
 */
export default function LinksPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails, setUserDetails } = useUserDetailsContext()
    const fetchLinks = useFetchLinksContext()
    const isReady = useIsReadyContext()
    const isUserLoaded = useIsUserLoadedContext()

    // TODO leave this comment until re-adding the archive page
    // const [optionsMenuState, setOptionsMenuState] = useState({
    //     isOpen: false,
    //     anchorElement: null,
    //     onClose: () => { }
    // })

    // TODO leave this comment until re-adding the archive page
    // const [isArchivePage, setIsArchivePage] = useState(false)
    const [isLoadingLinks, setIsLoadingLinks] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [skip, setSkip] = useState(0)
    const [linkSearchResult, setLinkSearchResult] = useState([])
    const [hasMoreLinks, setHasMoreLinks] = useState(true)
    const [shouldShowNoLinks, setShouldShowNoLinks] = useState(false)

    const typingTimeoutRef = useRef(null)

    useEffect(() => {
        if (hasShopifyStore()) {
            if (isUserLoaded && isReady) {
                // when the splash is no longer shown, load the links again to refresh the links collection
                fetchLinks(searchQuery, 0, true, linksData => {
                    setShouldShowNoLinks(true)
                    setLinks(linksData?.links, false, true)
                    setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
                    setIsLoadingLinks(false)
                })
            }
        }
    }, [])

    useEffect(() => {
        setShouldShowNoLinks(false)
        setLinks([], true, true)
        setIsLoadingLinks(true)
        setHasMoreLinks(true)
        clearTypingTimeout(typingTimeoutRef.current)
        if (searchQuery) {
            typingTimeoutRef.current = setTimeout(() => {
                fetchLinks(searchQuery, 0, false, linksData => {
                    setShouldShowNoLinks(true)
                    setLinks(linksData?.links, true, true)
                    setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
                    setIsLoadingLinks(false)
                })
            }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
        } else {
            setLinkSearchResult(userDetails?.linksData?.links)
            setHasMoreLinks(false)
            setIsLoadingLinks(false)
        }

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [searchQuery])

    function setLinks(links, shouldResetSkip = false, shouldOverrideLinks = false) {
        if (!links) {
            return
        }

        if (shouldOverrideLinks) {
            setLinkSearchResult([...(links.map(link => ({
                linkId: link?.link_id ?? 0,
                images: link?.images && link?.images?.length > 0 ? link?.images : [],
                createdAt: link?.created_at ?? 0,
                views: link?.visits ?? 0,
                revenue: link?.revenue ?? 0,
                isLinkExpired: link?.is_link_expired ?? false,
                currency: link?.currency ?? (userDetails?.businessCurrency),
                title: link?.description ?? ""
            })))])
        } else {
            setLinkSearchResult(prev => ([
                ...prev,
                ...(links.map(link => ({
                    linkId: link?.link_id ?? 0,
                    images: link?.images && link?.images?.length > 0 ? link?.images : [],
                    createdAt: link?.created_at ?? 0,
                    views: link?.visits ?? 0,
                    revenue: link?.revenue ?? 0,
                    isLinkExpired: link?.is_link_expired ?? false,
                    currency: link?.currency ?? (userDetails?.businessCurrency),
                    title: link?.description ?? ""
                })))
            ]))
        }
        if (shouldResetSkip)
            setSkip(0)
        else
            setSkip(prev => prev + links.length)
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleChange(e) {
        setSearchQuery(e.target.value)
    }

    function onAddLinkButtonClick() {
        history.push(CREATE_NEW_LINK_PAGE_URL)
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function hasNoLinks() {
        return !userDetails?.linksData?.links || userDetails?.linksData?.links.length === 0
    }

    function hasNoLinksResults() {
        return !linkSearchResult || linkSearchResult.length === 0
    }

    // TODO leave this comment until re-adding the archive page
    // function openOptionsMenu(e) {
    //     setOptionsMenuState({
    //         isOpen: true,
    //         anchorElement: e.currentTarget,
    //         onClose: () => setOptionsMenuState(prev => ({
    //             ...prev,
    //             isOpen: false
    //         }))
    //     })
    // }

    // TODO leave this comment until re-adding the archive page
    // function goToArchive() {
    //     optionsMenuState.onClose()
    //     setIsLoadingLinks(true)
    //     fetchLinks(() => {
    //         setIsLoadingLinks(false)
    //     })
    // }

    // TODO leave this comment until re-adding the archive page
    // function goToLinks() {
    //     optionsMenuState.onClose()
    //     setIsLoadingLinks(true)    
    //     fetchLinks(() => {
    //         setIsLoadingLinks(false)
    //     })
    // }

    function renderLinksContent() {
        if (hasNoLinks()) {
            return <>
                {
                    <div className='links-page-create-your-first-link-section'>
                        <ImageBox
                            image={PAYLINK_SMALL_LOGO_URL}
                            className="links-page-create-your-first-link-section-image"
                        />
                        <div className="links-page-create-your-first-link-section-titles-container">
                            <div className="links-page-create-your-first-link-section-title">{t('LINKS_PAGE_NO_LINKS_TITLE')}</div>
                            <div className="links-page-create-your-first-link-section-subtitle">{t('LINKS_PAGE_NO_LINKS_SUBTITLE')}</div>
                        </div>
                        <div className="links-page-create-your-first-link-section-create-link-button" onClick={onAddLinkButtonClick}>{t('LINKS_PAGE_NO_LINKS_CREATE_LINK_BUTTON_TEXT')}</div>
                    </div>
                }
            </>
        } else {
            if (hasNoLinksResults()) {
                return <>
                    {
                        shouldShowNoLinks && <div className={isMobile ? 'mobile-links-page-no-results-found-container' : 'links-page-no-results-found-container'}>
                            <div className="links-page-no-results-found-content">
                                <div className="links-page-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                            </div>
                        </div>
                    }
                </>
            }
            return <div className={isMobile ? 'mobile-links-page-content-links-list' : 'links-page-content-links-list'} onScroll={handleLinksScroll}>
                {
                    linkSearchResult?.map(link => <LinkItem
                        key={link?.linkId}
                        linkId={link?.linkId}
                        image={link?.images ? link?.images[0] : IMAGE_PLACEHOLDER_URL}
                        views={link?.views}
                        revenue={link?.revenue}
                        createdAt={link?.createdAt}
                        currency={link?.currency}
                        isLinkExpired={link?.isLinkExpired}
                        title={link?.title}
                        onDeleteLink={updateLinks}
                    />)
                }
            </div>
        }
    }

    function updateLinks(linkId) {
        fetchLinks(searchQuery, skip, false, linksData => {
            setLinkSearchResult(prev => prev.filter(link => link?.linkId !== linkId))
            setIsLoadingLinks(false)
        })
    }

    function handleLinksScroll(e) {
        const scrollPosition = e.target.scrollTop
        if (!isLoadingLinks && hasMoreLinks && scrollPosition >= ((LINK_ITEM_HEIGHT_PX * (userDetails?.linksData?.links.length / LINKS_PAGE_LINK_COLUMN_COUNT)) - (LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT * LINK_ITEM_HEIGHT_PX))) {
            setIsLoadingLinks(true)
            fetchLinks(searchQuery, skip, false, linksData => {
                setLinks(linksData?.links, false, false)
                setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
                setIsLoadingLinks(false)
            })
        }
    }

    return (
        <>
            <BrowserView>
                <div className='links-page-container' style={{ height: hasShopifyStore() ? null : '100vh', maxWidth: hasShopifyStore() ? `${LINK_PAGES_MAX_WIDTH}px` : `${PAGE_DEFAULT_MAX_WIDTH}px`, margin: !hasShopifyStore() && '0 auto' }}>
                    {
                        hasShopifyStore() ? (
                            <>
                                <div className="links-page-topbar">
                                    <div className="links-page-topbar-store-name-container">
                                        {
                                            userDetails?.businessLogo && <ImageBox
                                                className='links-page-topbar-store-image'
                                                image={userDetails?.businessLogo}
                                            />
                                        }
                                        {!userDetails?.businessLogo && <div className="links-page-topbar-store-name">{userDetails?.businessName ?? ""}</div>}
                                    </div>
                                    <div className="links-page-topbar-buttons-container">
                                        <div className="links-page-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                                            <input className='links-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                            {
                                                searchQuery.length === 0 ?
                                                    <CiSearch className='links-page-topbar-search-box-image' /> :
                                                    <IoCloseOutline className='edit-link-section-add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                                            }
                                        </div>
                                        <div className="links-page-topbar-create-new-link-button links-page-topbar-button" onClick={onAddLinkButtonClick}>{t('LINKS_PAGE_CREATE_NEW_LINK_BUTTON_TEXT')}</div>
                                        {/* TODO leave this comment until re-adding the archive page */}
                                        {/* <BiDotsVerticalRounded onClick={openOptionsMenu} className='links-page-topbar-more-options-button links-page-topbar-button' /> */}
                                    </div>
                                </div>
                                <div className={`links-page-content ${hasNoLinks() ? 'no-links-content' : ''}`}>
                                    {isLoadingLinks && <LoaderPage styles={{ backgroundColor: '#ffffffaa' }} marginBottom={0} isFullScreen={true} isContained={true} />}
                                    {renderLinksContent()}
                                </div>
                            </>
                        ) : <ShopifyConnect hasShopifyStore={false} />
                    }
                    {/* TODO leave this comment until re-adding the archive page */}
                    {/* <PaydinMenu
                        anchorElement={optionsMenuState.anchorElement}
                        isOpen={optionsMenuState.isOpen}
                        onClose={optionsMenuState.onClose}
                        direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                        options={[
                            {
                                text: isArchivePage ? t('LINKS_PAGE_OPTIONS_MENU_ITEM_GO_TO_LINKS') : t('LINKS_PAGE_OPTIONS_MENU_ITEM_GO_TO_ARCHIVE'),
                                onClick: isArchivePage ? goToLinks : goToArchive
                            }
                        ]}
                    /> */}
                </div>
            </BrowserView>

            <MobileView>
                {
                    hasShopifyStore() ? <div className='mobile-links-page-container'>
                        <div className="mobile-links-page-topbar-search-box" style={{ width: isMobile ? 'calc(100% - 20px)' : '300px', left: '10px' }}>
                            <input className='links-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                            {
                                searchQuery.length === 0 ?
                                    <CiSearch className='links-page-topbar-search-box-image' /> :
                                    <IoCloseOutline className='links-page-topbar-search-box-image close' onClick={clearSearchQuery} />
                            }
                        </div>
                        <div className={`mobile-links-page-content ${hasNoLinks() ? 'mobile-no-links-content' : ''}`}>
                            {isLoadingLinks && <LoaderPage styles={{ backgroundColor: '#ffffffaa' }} marginBottom={0} isFullScreen={true} />}
                            {renderLinksContent()}
                        </div>
                    </div> : <ShopifyConnect hasShopifyStore={false} />
                }
            </MobileView>
        </>
    )
}