import React from 'react'
import './SettingsInput.css'

export default function SettingsInput({
    name,
    value,
    isDisabled = false,
    prefix = null,
    placeholder = '',
    onValueChange = () => { }
}) {
    return (
        <div className="settings-input-container">
            { prefix && <div className="settings-input-prefix">{prefix}</div> }
            <input className="settings-input" disabled={isDisabled} placeholder={placeholder} value={value} name={name} onChange={onValueChange} />
        </div>
    )
}