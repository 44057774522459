import { UploadFileAPI } from "api/files/files"
import { TIME_UNITS } from "constants/general.constants"

export function generateRandomInteger(maxNumber) {
    return Math.random() * maxNumber
}

/**
 * Tests the password security.
 * It checks if the password has al least 1 digit, 1 lower case letter
 *  and 1 upper case letter.
 * @param {string} password - The password to test
 * @returns true if the password is secure and false otherwise.
 */
export function isPasswordSecure(password) {
    return /[0-9]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password)
}

/**
 * Formats a number to be displayed with 'decimalCount' decimal places.
 * @param {number} number - The number to format
 * @param {number} decimalCount - The number of decimal places for the formatted number
 * @returns The formatted number
 */
export function formatNumber(number, decimalCount = 2) {
    if (typeof number !== 'number' || !number)
        return 0.00
    return Number(number.toFixed(Number.isInteger(number) ? 0 : decimalCount)).toLocaleString('en-US')
}

/**
 * Uploads a file to the server
 * @param {string} imageUrl - The image url of the uploaded image
 * @param {function} onUrlReceive - A callback to call if the request was successful
 * @param {function} onFailure - A callback to call if the request was failed
 */
export async function uploadFile(imageUrl, onUrlReceive = () => { }, onFailure = () => { }) {
    if (imageUrl.startsWith('blob')) {
        try {
            const url = await UploadFileAPI(
                await fetch(imageUrl).then(r => r.blob())
                    .then(blobFile => new File([blobFile], `${imageUrl.substr(imageUrl.lastIndexOf('/') + 1)}.${blobFile.type.substr(blobFile.type.lastIndexOf('/') + 1)}`))
            )
            onUrlReceive(url)
        } catch (error) {
            onFailure(error)
        }
    }
}

/**
 * Shortens a large number (larger than 10,000) with a notation that indicates the amount, as shown below:
 * 
 * 1,000 ==> 1,000
 * 10,000 ==> 10K
 * 10,836 ==> 10.8K
 * 102,432 ==> 102.4K
 * 4,243,521 ==> 4.2M
 *      .
 *      .
 *      .
 * @param {number} number - The number to shorten
 * @param {number} maxBoundry - The maximum number from which the notation of 1K/1M/1B will be used
 * @returns the shortened number
 */
export function shortenNumber(number, maxBoundry = 10_000) {
    if (typeof number !== 'number' || !number)
        return 0

    const num = number.toString().replace(/[^0-9.]/g, '');
    if (num < maxBoundry) {
        return formatNumber(number);
    }
    let si = [
        { v: 1E3, s: "K" },
        { v: 1E6, s: "M" },
        { v: 1E9, s: "B" },
        { v: 1E12, s: "T" },
        { v: 1E15, s: "P" },
        { v: 1E18, s: "E" }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    return (num / si[index].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

export function addTimeToDate(date, timeUnit, value) {
    switch (timeUnit) {
        case TIME_UNITS.SECOND: 
            date.setSeconds(date.getSeconds() + value)
            break

        case TIME_UNITS.MINUTE: 
            date.setMinutes(date.getMinutes() + value)
            break

        case TIME_UNITS.HOUR: 
            date.setHours(date.getHours() + value)
            break

        case TIME_UNITS.DAY: 
            date.setDate(date.getDate() + value)
            break

        case TIME_UNITS.MONTH:
            date.setMonth(date.getMonth() + value)
            date.setDate(date.getDate() + 1)
            break;

        default: // TIME_UNITS.YEAR
            date.setFullYear(date.getFullYear() + value)
            date.setDate(date.getDate() + 1)
            break
    }

    return date
}