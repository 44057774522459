import React, { useRef, useState } from 'react'
import './StoreSettingsPage.css'
import { useTranslation } from 'react-i18next'
import AccountSection from 'components/AccountSection/AccountSection'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'
import {
    PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS,
    RESIZE_IMAGE_MAX_HEIGHT,
    RESIZE_IMAGE_MAX_WIDTH,
    SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR,
    STORE_SETTINGS_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS,
    STORE_SETTINGS_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS,
} from 'constants/general.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { uploadFile } from 'services/utils'
import { useUserDetailsContext } from 'contexts/User'
import AddMedia from 'components/AddMedia/AddMedia'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { UpdateLinkStyles, UpdateStoreAppearanceSettings } from 'api/store/store'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import StoreSettingsColorPicker from 'components/StoreSettingsColorPicker/StoreSettingsColorPicker'
import StoreSettingsLinkStyleSection from 'components/StoreSettingsLinkStyleSection/StoreSettingsLinkStyleSection'
import StoreSettingsOptionPicker from 'components/StoreSettingsOptionPicker/StoreSettingsOptionPicker'
import { resizeImage } from 'services/imageUtils'

export default function StoreSettingsPage() {
    const { t } = useTranslation()
    const { userDetails, setUserDetails } = useUserDetailsContext()

    const originalShopWebsite = (userDetails?.domain && userDetails?.domain !== '') ? `${userDetails?.domain}` : ''
    const originalShippingAndReturnUrl = (userDetails?.shippingAndReturnsUrl && userDetails?.shippingAndReturnsUrl !== '') ? userDetails?.shippingAndReturnsUrl.replace(t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_URL_PREFIX'), '') : ''

    const [businessDataState, setBusinessDataState] = useState({
        logoUrl: userDetails?.businessLogo,
        internationalRedirectUrl: originalShopWebsite,
        shippingAndReturnsUrl: originalShippingAndReturnUrl
    })
    const [buttonLoadersState, setButtonLoadersState] = useState({
        uploadLogoUrl: false,
        removeLogoUrl: false,
        internationalRedirectUrl: false,
        shippingAndReturnsUrl: false,
        linkStyle: false
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        leftButtonText: '',
        rightButtonText: ''
    })
    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')
    const [approvalMessageState, setApprovalMessageState] = useState({
        didChangesSaved: false,
        message: ''
    })
    const [linkStyleState, setLinkStyleState] = useState({
        isAbleToSave: false,
        theme: userDetails?.theme
    })

    const fileInputRef = useRef(null)

    const checkoutButtonPreviewStyles = {
        backgroundColor: linkStyleState.theme.checkoutButtonBackgroundColor,
        color: linkStyleState.theme.checkoutButtonFontColor,
        borderColor: linkStyleState.theme.checkoutButtonBorderColor,
        borderRadius: STORE_SETTINGS_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS[linkStyleState.theme.checkoutButtonShape].value
    }

    const discountPreviewStyles = {
        backgroundColor: linkStyleState.theme.discountBackgroundColor,
        color: linkStyleState.theme.discountFontColor
    }

    function showDialog(title, leftButtonText, rightButtonText, rightButtonClickHandler = () => { }) {
        setDialogState({
            isOpen: true,
            title,
            leftButtonText,
            rightButtonText,
            rightButtonClickHandler
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function updateStoreData(logoUrl, onSuccess = () => { }, onFailure = () => { }) {
        UpdateStoreAppearanceSettings(userDetails?.businessId, logoUrl)
            .then(onSuccess)
            .catch(onFailure)
    }

    function saveLinkStyle() {
        setButtonLoadersState(prev => ({
            ...prev,
            linkStyle: true
        }))
        UpdateLinkStyles(userDetails?.businessId, linkStyleState.theme)
            .then(response => {
                setButtonLoadersState(prev => ({
                    ...prev,
                    linkStyle: false
                }))
                showApprovalMessage(t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_SAVED_CHANGES_APPROVAL_MESSAGE'))
                setUserDetails(prev => ({
                    ...prev,
                    theme: linkStyleState.theme
                }))
                setLinkStyleState(prev => ({
                    ...prev,
                    isAbleToSave: false
                }))
            })
            .catch(error => {
                setButtonLoadersState(prev => ({
                    ...prev,
                    linkStyle: false
                }))
                showDialog(t('SETTINGS_PAGE_UPLOAD_LINK_STYLE_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_LINK_STYLE_ERROR_DIALOG_RIGHT_BUTTON_TEXT'), null)
            })
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function uploadLogoFromDevice() {
        fileInputRef.current.value = null
        fileInputRef?.current?.click()
    }

    function uploadLogo(importedFile) {
        setButtonLoadersState(prev => ({
            ...prev,
            uploadLogoUrl: true
        }))
        setBusinessDataState(prev => ({
            ...prev,
            logoUrl: importedFile
        }))

        uploadFile(importedFile, url => {
            setBusinessDataState(prev => {
                const updatedBusinessData = {
                    ...prev,
                    logoUrl: url
                }

                updateStoreData(updatedBusinessData.logoUrl, response => {
                    setButtonLoadersState(prev => ({
                        ...prev,
                        uploadLogoUrl: false
                    }))
                    setUserDetails(prev => ({
                        ...prev,
                        businessLogo: url
                    }))
                    showApprovalMessage(t('SETTINGS_PAGE_SHOP_LOGO_SECTION_SAVED_CHANGES_APPROVAL_MESSAGE'))
                }, error => {
                    console.log(error)
                    setButtonLoadersState(prev => ({
                        ...prev,
                        uploadLogoUrl: false
                    }))
                    showDialog(t('SETTINGS_PAGE_UPLOAD_LOGO_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_LOGO_ERROR_DIALOG_RIGHT_BUTTON_TEXT'), null)
                })

                return updatedBusinessData
            })
        }, error => {
            console.log(error)
            setButtonLoadersState(prev => ({
                ...prev,
                uploadLogoUrl: false
            }))
            showDialog(t('SETTINGS_PAGE_UPLOAD_LOGO_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_LOGO_ERROR_DIALOG_RIGHT_BUTTON_TEXT'), null)
        })
    }

    function removeLogo() {
        setButtonLoadersState(prev => ({
            ...prev,
            removeLogoUrl: true
        }))
        setBusinessDataState(prev => {
            const updatedBusinessData = {
                ...prev,
                logoUrl: ''
            }
            fileInputRef.current.value = null

            updateStoreData(updatedBusinessData.logoUrl, response => {
                setButtonLoadersState(prev => ({
                    ...prev,
                    removeLogoUrl: false
                }))
                setUserDetails(prev => ({
                    ...prev,
                    businessLogo: ''
                }))
            }, error => {
                console.log(error)
                setButtonLoadersState(prev => ({
                    ...prev,
                    removeLogoUrl: false
                }))
            })

            return updatedBusinessData
        })
    }

    function showRemoveLogoConfirmationDialog() {
        showDialog(
            t('SETTINGS_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_TITLE'),
            t('SETTINGS_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            t('SETTINGS_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            removeLogo
        )
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        uploadLogo(croppedImage)
        setIsImageCropDialogOpen(false)
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    function setCheckoutBackgroundColor(color) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                checkoutButtonBackgroundColor: color
            }
        }))
    }

    function setCheckoutBorderColor(color) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                checkoutButtonBorderColor: color
            }
        }))
    }

    function setCheckoutButtonShapeIndex(index) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                checkoutButtonShape: index
            }
        }))
    }

    function setCartButtonImageIndex(index) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                cartButtonIcon: index
            }
        }))
    }

    function setCheckoutButtonFontColor(color) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                checkoutButtonFontColor: color
            }
        }))
    }

    function setDiscountFontColor(color) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                discountFontColor: color
            }
        }))
    }

    function setDiscountBackgroundColor(color) {
        setLinkStyleState(prev => ({
            ...prev,
            isAbleToSave: true,
            theme: {
                ...prev.theme,
                discountBackgroundColor: color
            }
        }))
    }

    function showApprovalMessage(message) {
        setApprovalMessageState({
            message,
            didChangesSaved: true
        })
    }

    function hideApprovalMessage() {
        setApprovalMessageState(prev => ({
            ...prev,
            didChangesSaved: false
        }))
    }

    return (
        <div className={isMobile ? 'mobile-store-settings-page-container' : 'store-settings-page-container'}>
            <div className="store-settings-page-title">{t('SETTINGS_PAGE_TITLE')}</div>
            <div className='store-settings-page-content'>
                <AccountSection
                    title={t('SETTINGS_PAGE_STORE_DETAILS_SECTION_TITLE')}
                >
                    <>
                        <div className="store-settings-page-store-details-section-store-name-container">
                            <div className="settings-pages-subsection-title">{t('SETTINGS_PAGE_STORE_DETAILS_SECTION_STORE_NAME_TITLE')}</div>
                            <div className="store-settings-page-store-details-section-store-name-value-container settings-pages-subsection-frame">
                                <div className="store-settings-page-store-details-section-store-name-value">{userDetails?.businessName}</div>
                            </div>
                        </div>
                        <div className="store-settings-page-store-details-section-store-logo-container">
                            <div className="settings-pages-subsection-title">{t('SETTINGS_PAGE_STORE_DETAILS_SECTION_STORE_LOGO_TITLE')}</div>
                            <div className="store-settings-page-store-details-section-update-logo-container">
                                <div className="store-settings-page-store-details-section-update-logo-add-logo-restriction-container">
                                    <div className={`${isMobile ? 'mobile-store-settings-page-store-details-section-update-logo-add-logo-container' : 'store-settings-page-store-details-section-update-logo-add-logo-container'} settings-pages-subsection-frame`}>
                                        {
                                            businessDataState.logoUrl ? <ImageBox
                                                image={businessDataState.logoUrl}
                                                className='store-settings-page-store-details-section-update-logo-image-container'
                                                imageClassName='store-settings-page-store-details-section-update-logo-image'
                                            /> : <AddMedia onAddMedia={uploadLogoFromDevice} aspectRatio='5 / 2' />
                                        }
                                    </div>
                                    <div className="store-settings-page-store-details-section-update-logo-restriction">{t('SETTINGS_PAGE_STORE_DETAILS_SECTION_LOGO_RESTRICTION_TEXT')}</div>
                                </div>
                                <div className="store-settings-page-store-details-section-update-logo-buttons-container" style={{ ...(isMobile ? { width: '100%' } : {}) }}>
                                    <LoaderButton
                                        className='store-settings-page-store-details-section-update-logo-button'
                                        buttonText={t('SETTINGS_PAGE_STORE_DETAILS_SECTION_UPLOAD_LOGO_BUTTON_TEXT')}
                                        isLoading={buttonLoadersState.uploadLogoUrl}
                                        onClick={uploadLogoFromDevice}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                    <LoaderButton
                                        className='store-settings-page-store-details-section-remove-button'
                                        buttonText={t('SETTINGS_PAGE_STORE_DETAILS_SECTION_REMOVE_BUTTON_TEXT')}
                                        isDisabled={!businessDataState.logoUrl}
                                        isLoading={buttonLoadersState.removeLogoUrl}
                                        onClick={showRemoveLogoConfirmationDialog}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_TITLE')}
                    sectionContentClassName='store-settings-page-link-style-section'
                >
                    <>
                        <div className='store-settings-checkout-button-style-container'>
                            <div className="store-settings-checkout-button-style-fields">
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_SHAPE_SUBSECTION_TITLE')}
                                >
                                    <StoreSettingsOptionPicker
                                        selectedOption={linkStyleState.theme.checkoutButtonShape}
                                        setSelectedOption={setCheckoutButtonShapeIndex}
                                        options={STORE_SETTINGS_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BACKGROUND_COLOR_SECTION_TITLE')}
                                >
                                    <StoreSettingsColorPicker
                                        title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BACKGROUND_COLOR_SUBSECTION_SUBTITLE')}
                                        color={linkStyleState.theme.checkoutButtonBackgroundColor}
                                        setColor={setCheckoutBackgroundColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BORDER_COLOR_SECTION_TITLE')}
                                >
                                    <StoreSettingsColorPicker
                                        title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BORDER_COLOR_SUBSECTION_SUBTITLE')}
                                        color={linkStyleState.theme.checkoutButtonBorderColor}
                                        setColor={setCheckoutBorderColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_FONT_COLOR_SUBSECTION_TITLE')}
                                >
                                    <StoreSettingsColorPicker
                                        title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_FONT_COLOR_SUBSECTION_SUBTITLE')}
                                        color={linkStyleState.theme.checkoutButtonFontColor}
                                        setColor={setCheckoutButtonFontColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                            </div>
                            {!isMobile && <div className="store-settings-checkout-button-preview">
                                <div className="store-settings-checkout-button-preview-frame">
                                    <div className="store-settings-checkout-button-preview-element" style={checkoutButtonPreviewStyles}>
                                        <div className="store-settings-checkout-button-preview-element-text">Checkout</div>
                                        <div className="store-settings-checkout-button-preview-element-dot" style={{ backgroundColor: checkoutButtonPreviewStyles.color }}></div>
                                        <div className="store-settings-checkout-button-preview-element-price">$456</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <StoreSettingsLinkStyleSection
                            title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_CART_ICON_SUBSECTION_TITLE')}
                        >
                            <StoreSettingsOptionPicker
                                selectedOption={linkStyleState.theme.cartButtonIcon}
                                setSelectedOption={setCartButtonImageIndex}
                                options={STORE_SETTINGS_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS}
                            />
                        </StoreSettingsLinkStyleSection>
                        <div className='store-settings-discount-style-container'>
                            <div className="store-settings-discount-style-fields">
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_DISCOUNT_BACKGROUND_COLOR_SUBSECTION_TITLE')}
                                >
                                    <StoreSettingsColorPicker
                                        title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_DISCOUNT_BACKGROUND_COLOR_SUBSECTION_SUBTITLE')}
                                        color={linkStyleState.theme.discountBackgroundColor}
                                        setColor={setDiscountBackgroundColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_DISCOUNT_FONT_COLOR_SUBSECTION_TITLE')}
                                >
                                    <StoreSettingsColorPicker
                                        title={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_DISCOUNT_FONT_COLOR_SUBSECTION_SUBTITLE')}
                                        color={linkStyleState.theme.discountFontColor}
                                        setColor={setDiscountFontColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                            </div>
                            {!isMobile && <div className="store-settings-discount-preview">
                                <div className="store-settings-discount-preview-frame">
                                    <div className="store-settings-discount-preview-element">
                                        <div className="store-settings-discount-preview-discount-original-price">$799.00</div>
                                        <div className="store-settings-discount-preview-discount-price-container">
                                            <div className="store-settings-discount-preview-discount-price">$499.00</div>
                                            <div className="store-settings-discount-preview-discount-percentage" style={discountPreviewStyles}>37% OFF</div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <LoaderButton
                            className={isMobile ? 'mobile-store-settings-page-link-style-section-save-button' : 'store-settings-page-link-style-section-save-button'}
                            buttonText={t('SETTINGS_PAGE_SHOP_LINK_STYLE_SECTION_SAVE_BUTTON_TEXT')}
                            isDisabled={!linkStyleState.isAbleToSave}
                            isLoading={buttonLoadersState.linkStyle}
                            onClick={saveLinkStyle}
                            loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                        />
                    </>
                </AccountSection>
            </div>
            <input ref={fileInputRef} type="file" accept="image/jpeg,image/png,image/webp" onChange={onImageChange} style={{ display: 'none' }} />
            {
                isImageCropDialogOpen && <ImageCropDialog
                    isDialogOpen={isImageCropDialogOpen}
                    handleDialogClose={closeImageCropDialog}
                    image={cropperTempImage}
                    aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                    isOriginalAspectRatioExcluded={true}
                    isLogoCropper={true}
                    onCropDone={onApplyCrop}
                />
            }
            {
                dialogState.isOpen && <PaydinDialog
                    title={dialogState.title}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    isLeftButtonWithLoader={false}
                />
            }
            <ApprovalMessage
                isMessageShown={approvalMessageState.didChangesSaved}
                closeMessage={hideApprovalMessage}
                text={approvalMessageState.message}
                isUp={isMobile}
            />
        </div>
    )
}