import React from "react"
import './DashboardLayout.css'
import DashboardTopBar from "components/DashboardTopBar/DashboardTopBar"
import AuthLayout from "layouts/RequiredAuthLayout"
import { isMobile } from "react-device-detect"
import { DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR } from "constants/general.constants"
import { ADMIN_PAGES_URL_PREFIX, STORE_SETTINGS_PAGE_URL_PREFIX } from "constants/routes.constants"

export default function DashboardLayout({ children }) {

    function isDashboardTopBarShownInMobile() {
        return DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR.includes(window.location.pathname) || window.location.pathname.startsWith(`${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}`)
    }

    function isDashboardTopbarShown() {
        return !isMobile || isDashboardTopBarShownInMobile()
    }

    return (
        <AuthLayout>
            <div className={ isMobile ? 'mobile-dashboard-layout-content' : 'dashboard-layout-content'} style={{ paddingBottom: isMobile ? '0px' : '10px', paddingTop: isDashboardTopbarShown() ? (isMobile ? 'calc(var(--mobile-dashboard-topbar-height) + 20px)' : 'calc(var(--dashboard-topbar-height) + 20px)') : '0px' }}>
                { isDashboardTopbarShown() && <DashboardTopBar /> }
                {children}
            </div>
        </AuthLayout>
    )
}