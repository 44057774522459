import React from 'react'
import './ImageDialog.css'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import ImageBox from 'components/ImageBox/ImageBox'
import { GrClose } from 'react-icons/gr'
import { useHistoryContext } from 'contexts/History'
import { isMobile } from 'react-device-detect'

export default function ImageDialog({
    isDialogOpen,
    handleDialogClose,
    image
}) {
    const history = useHistoryContext()

    function closeDialog() {
        if (handleDialogClose)
            handleDialogClose()
        history.goBack()
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            borderRadiusPx={10}
            maxHeight={isMobile ? '100vh' : '900px'}
            maxWidth={isMobile ? '100vw' : '900px'}
            backgroundColor='black'
            paddingPx={0}
            margin={isMobile ? 0 : '15px'}
            styles={{ overflow: 'hidden' }}
            dialogLayout={<>
                <GrClose className='image-dialog-close-button' onClick={closeDialog} />
                <ImageBox
                    image={image}
                    className='image-dialog-image-container'
                    enlargeImageOnClick={false}
                />
            </>}
        />
    )
}