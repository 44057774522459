import React from 'react'
import './PreviewLinkPage.css'

import { LINK_VIEW_MODES } from 'constants/general.constants'
import LinkView from 'components/LinkView/LinkView'

export default function PreviewLinkPage() {
    return (
        <LinkView mode={LINK_VIEW_MODES.PREVIEW} />
    )
}