import React, { useState } from 'react'
import './SignUpRequestPage.css'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import { EMAIL_PATTERN_REGEX } from 'constants/regex.constants'
import {
    BOXED_MESSAGE_TYPES
} from 'constants/general.constants'
import { LOGIN_PAGE_URL, WELCOME_PAGE_URL } from 'constants/routes.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { RequestRegistration } from 'api/auth/auth'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { isMobile } from 'react-device-detect'

export default function SignUpRequestPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isProceeding, setIsProceeding] = useState(false)
    const [inputState, setInputState] = useState({
        name: '',
        email: '',
        description: ''
    })

    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: ''
    })

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { }
    })

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function handleInputChange(e) {
        setInputState(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    function sendContactInfo() {
        setIsProceeding(true)
        RequestRegistration(inputState.name, inputState.email, inputState.description)
            .then(response => {
                setIsProceeding(false)
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('SIGN_UP_TEMP_LANDING_PAGE_REGISTRATION_REQUEST_SUCCESS_DIALOG_TITLE'),
                    message: t('SIGN_UP_TEMP_LANDING_PAGE_REGISTRATION_REQUEST_SUCCESS_DIALOG_MESSAGE'),
                    leftButtonText: t('SIGN_UP_TEMP_LANDING_PAGE_REGISTRATION_REQUEST_SUCCESS_DIALOG_LEFT_BUTTON_TEXT'),
                    leftButtonClickHandler: () => history.push(WELCOME_PAGE_URL)
                }))
            })
            .catch(error => {
                setIsProceeding(false)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('SIGN_UP_TEMP_LANDING_PAGE_SEND_CONTACT_INFO_ERROR_MESSAGE_TEXT')
                })
            })
    }

    function isNameValid() {
        return inputState.name.length > 0
    }

    function isDescriptionValid() {
        return inputState.description.length > 0
    }

    function isEmailValid() {
        return EMAIL_PATTERN_REGEX.test(inputState.email)
    }

    function isAbleToProceed() {
        return (
            isNameValid() &&
            isEmailValid() &&
            isDescriptionValid()
        )
    }

    function goToLogin() {
        history.push(LOGIN_PAGE_URL)
    }

    return (
        <div className='sign-up-temp-landing-page-container'>
            <form dir={t('direction.dir')} className="sign-up-temp-landing-page-content">
                <div className="sign-up-temp-landing-page-titles-container">
                    <div className="sign-up-temp-landing-page-title auth-page-title">{t('SIGN_UP_TEMP_LANDING_PAGE_TITLE')}</div>
                    <div className="sign-up-temp-landing-page-subtitle">{t('SIGN_UP_TEMP_LANDING_PAGE_SUBTITLE')}</div>
                </div>
                {
                    boxedMessageState.isOpen && <BoxedMessage
                        message={boxedMessageState.message}
                        type={BOXED_MESSAGE_TYPES.ERROR} />
                }
                <div className="sign-up-temp-landing-page-fields-form">
                    <div className="sign-up-temp-landing-page-fields-form-name-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_TEMP_LANDING_PAGE_INPUT_NAME_PLACEHOLDER')} name='name' type='text' onChange={handleInputChange} />
                    </div>
                    <div className="sign-up-temp-landing-page-fields-form-email-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_TEMP_LANDING_PAGE_INPUT_EMAIL_PLACEHOLDER')} name='email' type='email' onChange={handleInputChange} />
                    </div>
                    <div className="sign-up-temp-landing-page-fields-form-description-input-container textarea-input-field-container">
                        <textarea className='textarea-input-field' rows={10} placeholder={t('SIGN_UP_TEMP_LANDING_PAGE_INPUT_DESCRIPTION_PLACEHOLDER')} name='description' type='text' onChange={handleInputChange} />
                    </div>
                </div>
                <LoaderButton
                    className="sign-up-temp-landing-page-sign-up-button"
                    buttonText={t('SIGN_UP_TEMP_LANDING_PAGE_BUTTON_TEXT')}
                    isLoading={isProceeding}
                    renderAsButton={true}
                    isDisabled={!isAbleToProceed()}
                    onClick={sendContactInfo}
                />

                <div className="sign-up-request-page-already-have-account-section">
                    <div className="sign-up-request-page-already-have-account-label">{t('SIGN_UP_PAGE_ALREADY_HAVE_ACCOUNT_LABEL_TEXT')}</div>
                    <div className="sign-up-request-page-already-have-account-login-button" onClick={goToLogin}>{t('SIGN_UP_PAGE_ALREADY_HAVE_ACCOUNT_LOGIN_BUTTON_TEXT')}</div>
                </div>
            </form>
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnLeftClick={false}
                    leftButtonText={dialogState.leftButtonText}
                    onLeftButtonClick={dialogState.leftButtonClickHandler}
                    isLeftButtonWithLoader={false}
                    areButtonsInColumn={isMobile}
                />
            }
        </div>
    )
}