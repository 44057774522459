import React, { useEffect, useState } from 'react'
import './LinkView.css'
import { isMobile } from 'react-device-detect'
import MobileLinkPreviewSimulator from 'components/MobileLinkPreviewSimulator/MobileLinkPreviewSimulator'
import PreviewLinkSection from 'components/PreviewLinkSection/PreviewLinkSection'
import { CREATE_LINK_PHASES, EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_TIME_UNIT, EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_VALUE, IMAGE_PLACEHOLDER_URL, LINK_ID_ROUTE_PARAMETER_INDEX, LINK_VIEW_MODES, PRICING_PLAN_DATA, TIME_UNITS } from 'constants/general.constants'
import EditLinkSection from 'components/EditLinkSection/EditLinkSection'
import { copyToClipboard } from 'services/SystemService'
import { useTranslation } from 'react-i18next'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { MdArrowBack } from 'react-icons/md'
import { RiDeleteBin6Line, RiEditLine } from 'react-icons/ri'
import { BiLink } from 'react-icons/bi'
import { GrClose } from 'react-icons/gr'
import {
    ADMIN_PAGES_URL_PREFIX,
    LINKS_PAGE_URL,
    LINKS_URL_PREFIX
} from 'constants/routes.constants'
import { environment } from 'conf'
import { useUserDetailsContext } from 'contexts/User'
import { useHistoryContext } from 'contexts/History'
import { CreateLinkDetailsAPI, DeleteLinkAPI, GetLinkDetailsAPI, UpdateLinkDetailsAPI } from 'api/links/links'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import { addTimeToDate, uploadFile } from 'services/utils'
import { logger } from 'services/CloudLogger'
import CreateLinkSection from 'components/CreateLinkSection/CreateLinkSection'
import { v4 as uuidV4 } from 'uuid'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'

/**
 * Represents the portion of the screen that displays the preview/edit/create section and the mobile simulator section.
 * @param {string} mode - The mode for this view. It controls what will be displayed. Can accept one the following: LINK_VIEW_MODES.PREVIEW, LINK_VIEW_MODES.EDIT & LINK_VIEW_MODES.CREATE
 */
export default function LinkView({
    mode
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()

    const [isLoadingLink, setIsLoadingLink] = useState(true)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [isMobilePreviewShown, setIsMobilePreviewShown] = useState(false)
    const [linkData, setLinkData] = useState({})
    const [isLinkVanished, setIsLinkVanished] = useState(false)
    const [isLinkExpired, setIsLinkExpired] = useState(false)
    const [userSelectedProducts, setUserSelectedProducts] = useState([])
    const [promocodeState, setPromocodeState] = useState({
        code: linkData?.promocode?.code,
        description: linkData?.promocode?.description
    })
    const [linkDescription, setLinkDescription] = useState('')
    const [shoppingUrl, setShoppingUrl] = useState('')
    const [isSavingLink, setIsSavingLink] = useState(false)
    const [createLinkPhase, setCreateLinkPhase] = useState(CREATE_LINK_PHASES.ADD_IMAGE_PHASE)
    const [linkPropertiesModified, setLinkPropertiesModified] = useState(false)
    const [isAbleToSaveChanges, setIsAbleToSaveChanges] = useState(false)
    const [didChangesSaved, setDidChangesSaved] = useState(false)

    useEffect(() => {
        if (mode !== LINK_VIEW_MODES.CREATE) {
            GetLinkDetailsAPI(window.location.pathname.split('/')[LINK_ID_ROUTE_PARAMETER_INDEX])
                .then(linkData => {
                    let imagesWithIds = ['']
                    if (linkData?.images && linkData.images?.length > 0) {
                        imagesWithIds = linkData.images.map(image => {
                            return {
                                src: image,
                                id: uuidV4()
                            }
                        })
                    }
                    const shoppingRedirectUrl = linkData?.shopping_redirect_url?.split('/')?.slice(-1) ? (linkData?.shopping_redirect_url?.split('/')?.slice(-1)[0]) : ''

                    setLinkData({
                        linkId: linkData?.link_id ?? '',
                        images: imagesWithIds,
                        description: linkData.description ?? '',
                        currency: linkData?.link_analytics?.currency ?? userDetails?.businessCurrency,
                        analytics: {
                            views: linkData?.link_analytics?.visits ?? 0,
                            checkouts: linkData?.link_analytics?.checkouts ?? 0,
                            revenue: linkData?.link_analytics?.revenue ?? 0,
                            checkoutRequests: linkData?.link_analytics?.requested_checkouts ?? 0
                        },
                        promocode: {
                            code: linkData?.promocode?.code ?? '',
                            description: linkData?.promocode?.description ?? ''
                        },
                        products: linkData?.products?.map(product => ({
                            inventory_id: product?.inventory_id,
                            title: product?.title ?? '',
                            image: {
                                src: product?.image?.src ?? ''
                            }
                        })),
                        isLinkVanished: linkData?.is_vanish_link ?? false,
                        isLinkExpired: linkData?.is_link_expired ?? false,
                        expiryDate: linkData?.expiry_date ?? 0,
                        title: linkData?.description ?? "", // consider the description as the link's title
                        shoppingRedirectUrl
                    })
                    setUserSelectedProducts(linkData?.products?.map(product => ({
                        inventory_id: product?.inventory_id,
                        title: product?.title ?? '',
                        image: {
                            src: product?.image?.src ?? ''
                        }
                    })))
                    setLinkDescription(linkData?.description ?? '')
                    setPromocodeState({
                        code: linkData?.promocode?.code ?? '',
                        description: linkData?.promocode?.description ?? ''
                    })
                    setShoppingUrl(shoppingRedirectUrl ?? '')
                    setIsLinkExpired(linkData?.is_link_expired ?? false)
                    setIsLinkVanished(linkData?.is_vanish_link ?? false)
                    setIsLoadingLink(false)
                })
                .catch(error => {
                    console.log(error)
                    history.push(LINKS_PAGE_URL)
                })
        } else {
            setIsLinkVanished(isFreeUser())
            setIsLoadingLink(false)
        }
    }, [])

    useEffect(() => {
        setIsAbleToSaveChanges(
            linkPropertiesModified &&
            userSelectedProducts && userSelectedProducts.length > 0 &&
            linkData.images && linkData.images?.length > 0 && linkData.images[0]?.src.length > 0
        )
    }, [linkPropertiesModified, userSelectedProducts, linkDescription, shoppingUrl, linkData.images])

    function showMobilePreview() {
        setIsMobilePreviewShown(true)
    }

    function isFreeUser() {
        return userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name.toUpperCase()
    }

    function closeMobilePreview() {
        setIsMobilePreviewShown(false)
    }

    function goBack() {
        if (mode === LINK_VIEW_MODES.CREATE && createLinkPhase === CREATE_LINK_PHASES.ADD_LINK_PROPERTIES_PHASE) {
            setCreateLinkPhase(CREATE_LINK_PHASES.ADD_IMAGE_PHASE)
        } else {
            if (linkPropertiesModified) {
                setDialogState({
                    isOpen: true,
                    handleDialogClose: closeDialog,
                    title: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_TITLE'),
                    message: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_MESSAGE'),
                    leftButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
                    rightButtonClickHandler: () => history.goBack(2),
                    isRightButtonWithLoader: false
                })
            } else {
                history.goBack()
            }
        }
    }

    function editLink() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${LINKS_URL_PREFIX}/${linkData?.linkId}/edit`)
    }

    async function copyLinkToClipboard() {
        const url = `${environment.frontend_url}${userDetails?.username}/${linkData?.linkId}`
        await copyToClipboard(url)
        alert(`${url} was copied to clipboard`)
    }

    function showDeleteLinkConfirmationDialog() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_TITLE'),
            message: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_MESSAGE'),
            leftButtonText: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteLink,
            isRightButtonWithLoader: true
        })
    }

    function deleteLink() {
        DeleteLinkAPI(linkData?.linkId)
            .then(response => {
                history.push(LINKS_PAGE_URL)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function hasLinkImages() {
        return linkData?.images && linkData.images.length > 0
    }

    async function save() {
        setIsSavingLink(true)
        if (hasLinkImages()) {
            const imagesToSave = linkData?.images?.map(image => image.src)
            await Promise.all(imagesToSave.map(async (image, i) => {
                await uploadFile(image, url => {
                    imagesToSave[i] = url
                }, () => {
                    console.log('an error as occurred during file upload...')
                })
            }))

            const inventoryIds = userSelectedProducts?.map(selectedProduct => selectedProduct.inventory_id)
            const shopUrl = `${t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PREFIX')}${userDetails?.domain}/${shoppingUrl}`

            if (!imagesToSave.some(image => image.startsWith('blob'))) {
                const promocode = {
                    code: promocodeState.code === '' ? null : promocodeState.code,
                    description: promocodeState.description === '' ? null : promocodeState.description
                }

                if (mode === LINK_VIEW_MODES.CREATE) {
                    const expiryDate = isLinkVanished ? addTimeToDate(
                        new Date(),
                        EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_TIME_UNIT,
                        EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_VALUE
                    ).getTime() : null

                    CreateLinkDetailsAPI(userDetails.businessId, imagesToSave, inventoryIds, promocode, linkDescription, shopUrl, isLinkVanished, expiryDate)
                        .then(() => {
                            history.push(LINKS_PAGE_URL)
                        }).catch((error) => {
                            //TODO Alert failure
                            logger.error(`Error - edit link failure - ${error}`)
                            showSaveDetailsErrorMessage()
                            setIsSavingLink(false)
                        })
                } else {
                    UpdateLinkDetailsAPI(userDetails.businessId, linkData.linkId, imagesToSave, inventoryIds, promocode, linkDescription, shopUrl, isLinkVanished, linkData?.expiryDate)
                        .then(() => {
                            setIsSavingLink(false)
                            setDidChangesSaved(true)
                            setLinkPropertiesModified(false)
                        }).catch((error) => {
                            //TODO Alert failure
                            logger.error(`Error - edit link failure - ${error}`)
                            showSaveDetailsErrorMessage()
                            setIsSavingLink(false)
                        })
                }
            } else {
                setDialogState({
                    isOpen: true,
                    handleDialogClose: closeDialog,
                    title: t('EDIT_LINK_PAGE_UPLOAD_FILE_ERROR_DIALOG_TITLE'),
                    leftButtonText: t('EDIT_LINK_PAGE_UPLOAD_FILE_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
                    rightButtonText: null,
                    rightButtonClickHandler: () => { },
                    isRightButtonWithLoader: false
                })
                setIsSavingLink(false)
            }
        }
    }

    function showSaveDetailsErrorMessage() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        })
    }

    function setShoppingURL(shoppingUrl) {
        setLinkPropertiesModified(true)
        setShoppingUrl(shoppingUrl)
    }

    function setSelectedProducts(products) {
        setLinkPropertiesModified(true)
        setUserSelectedProducts(products)
    }

    function setDescription(linkDescription) {
        setLinkPropertiesModified(true)
        setLinkDescription(linkDescription)
    }

    function setPromocode(promocode) {
        setLinkPropertiesModified(true)
        setPromocodeState(prev => ({
            ...prev,
            code: promocode
        }))
    }

    function setPromocodeDescription(promocodeDescription) {
        setLinkPropertiesModified(true)
        setPromocodeState(prev => ({
            ...prev,
            description: promocodeDescription
        }))
    }

    function setImage(imageFile) {
        setLinkPropertiesModified(true)
        setLinkData(prev => ({
            ...prev,
            images: [{
                // Determins here whether the imageFile is a blob image url or an image file object
                src: typeof imageFile === 'string' && imageFile.startsWith('blob') ? imageFile : URL.createObjectURL(imageFile),
                id: uuidV4()
            }]
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function renderContent() {
        switch (mode) {
            case LINK_VIEW_MODES.PREVIEW: {
                return <PreviewLinkSection
                    linkId={linkData?.linkId}
                    image={hasLinkImages() ? linkData?.images[0]?.src : ''}
                    description={linkDescription}
                    linkViews={linkData?.analytics?.views}
                    linkCheckoutsCompleted={linkData?.analytics?.checkouts}
                    linkCheckoutRequests={linkData?.analytics?.checkoutRequests}
                    linkRevenue={linkData?.analytics?.revenue}
                    linkCurrency={linkData?.currency}
                    promocode={linkData?.promocode?.code}
                    promocodeDescription={linkData?.promocode?.description}
                    isLinkExpired={isLinkExpired}
                />
            }

            case LINK_VIEW_MODES.EDIT: {
                return <EditLinkSection
                    image={hasLinkImages() ? linkData?.images[0]?.src : ''}
                    setImage={setImage}
                    selectedProducts={userSelectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    description={linkDescription}
                    setDescription={setDescription}
                    isButtonDisabled={!isAbleToSaveChanges}
                    isButtonProcessing={isSavingLink}
                    buttonText={t('EDIT_LINK_SECTION_SAVE_CHANGES_BUTTON_TEXT')}
                    onButtonClick={save}
                    promocode={promocodeState.code}
                    setPromocode={setPromocode}
                    promocodeDescription={promocodeState.description}
                    setPromocodeDescription={setPromocodeDescription}
                    isLinkVanished={isLinkVanished}
                    setIsLinkVanished={setIsLinkVanished}
                    shoppingUrl={shoppingUrl}
                    setShoppingUrl={setShoppingURL}
                    linkPropertiesModified={linkPropertiesModified}
                    mode={mode}
                />
            }

            default: { // CREATE mode
                return <CreateLinkSection
                    image={hasLinkImages() ? linkData?.images[0]?.src : ''}
                    setImage={setImage}
                    selectedProducts={userSelectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    description={linkDescription}
                    setDescription={setDescription}
                    isButtonDisabled={!isAbleToSaveChanges}
                    isButtonProcessing={isSavingLink}
                    onButtonClick={save}
                    promocode={promocodeState.code}
                    setPromocode={setPromocode}
                    promocodeDescription={promocodeState.description}
                    setPromocodeDescription={setPromocodeDescription}
                    isLinkVanished={isLinkVanished}
                    setIsLinkVanished={setIsLinkVanished}
                    shoppingUrl={shoppingUrl}
                    setShoppingUrl={setShoppingURL}
                    createLinkPhase={createLinkPhase}
                    setCreateLinkPhase={setCreateLinkPhase}
                    linkPropertiesModified={linkPropertiesModified}
                    mode={mode}
                />
            }
        }
    }

    function getTopbarCenterTitle() {
        switch (mode) {
            case LINK_VIEW_MODES.EDIT: return t('LINK_VIEW_TOPBAR_CENTER_TITLE_EDIT_LINK')
            case LINK_VIEW_MODES.CREATE: return t('LINK_VIEW_TOPBAR_CENTER_TITLE_CREATE_LINK')
            default: return ""
        }
    }

    function hasImage() {
        return linkData?.images && linkData?.images.length > 0
    }

    return (
        <>
            {isLoadingLink && <LoaderPage styles={{ backgroundColor: isLoadingLink ? '#ffffff' : '#ffffffaa' }} isFullScreen={true} />}
            <div className="link-view-container" style={{ height: isMobilePreviewShown ? 'calc(100vh - 45px)' : (!isMobile && '660px') }}>
                <div className="link-view-topbar" style={{ margin: isMobile ? '0 20px' : '0', width: isMobile ? 'unset' : '100%', padding: isMobile ? '10px 0' : '0' }}>
                    {
                        isMobilePreviewShown ? <GrClose className='mobile-link-view-mobile-preview-close-button' onClick={closeMobilePreview} /> : <>
                            <div className="link-view-topbar-back-button-container">
                                <div className="link-view-topbar-back-button" onClick={goBack}>
                                    <MdArrowBack className="link-view-topbar-back-button-image" />
                                    {!isMobile && <div className="link-view-topbar-back-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_BACK_BUTTON_TEXT')}</div>}
                                </div>
                            </div>
                            <div className="link-view-topbar-center-title">{getTopbarCenterTitle()}</div>
                            <div className="link-view-topbar-buttons-container">
                                {
                                    (isMobile && hasImage()) ? <div className='mobile-link-view-topbar-preview-button' onClick={showMobilePreview}>{t('MOBILE_PREVIEW_LINK_PAGE_TOPBAR_PREVIEW_BUTTON_TEXT')}</div> : (
                                        mode === LINK_VIEW_MODES.PREVIEW && <>
                                            <div className="link-view-topbar-delete-link-button link-view-topbar-button" onClick={showDeleteLinkConfirmationDialog}>
                                                <RiDeleteBin6Line className="link-view-topbar-delete-link-button-image link-view-topbar-button-image" />
                                                <div className="link-view-topbar-delete-link-button-text link-view-topbar-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_DELETE_LINK_BUTTON_TEXT')}</div>
                                            </div>
                                            {
                                                !isLinkExpired && <>
                                                    <div className="link-view-topbar-edit-link-button link-view-topbar-button" onClick={editLink}>
                                                        <RiEditLine className="link-view-topbar-edit-link-button-image link-view-topbar-button-image" />
                                                        <div className="link-view-topbar-edit-link-button-text link-view-topbar-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_EDIT_LINK_BUTTON_TEXT')}</div>
                                                    </div>
                                                </>
                                            }
                                            <div className="link-view-topbar-copy-link-button link-view-topbar-button" onClick={copyLinkToClipboard}>
                                                <BiLink className="link-view-topbar-copy-link-button-image link-view-topbar-button-image" />
                                                <div className="link-view-topbar-copy-link-button-text link-view-topbar-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_COPY_LINK_BUTTON_TEXT')}</div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="link-view-content" style={{ height: isMobile ? '100%' : '560px' }}>
                    {
                        isMobilePreviewShown ?
                            <MobileLinkPreviewSimulator
                                hasBorder={false}
                                isFullScreen={true}
                                mainImageUrl={linkData?.images[0]?.src}
                                products={userSelectedProducts}
                                isLinkExpired={isLinkExpired}
                            /> : <>
                                <div className="link-view-details-container" style={{ width: isMobile ? '100%' : '65%' }}>
                                    {renderContent()}
                                </div>
                                {
                                    !isMobile && <div className="link-view-preview-container">
                                        <MobileLinkPreviewSimulator
                                            mainImageUrl={hasLinkImages() ? linkData?.images[0]?.src : ''}
                                            products={userSelectedProducts}
                                            isLinkExpired={isLinkExpired}
                                        />
                                    </div>
                                }
                            </>
                    }
                </div>
                {
                    dialogState.isOpen && <PaydinDialog
                        isDialogOpen={dialogState.isOpen}
                        handleDialogClose={dialogState.handleDialogClose}
                        title={dialogState.title}
                        message={dialogState.message}
                        closeOnRightClick={false}
                        rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                        leftButtonText={dialogState.leftButtonText}
                        rightButtonText={dialogState.rightButtonText}
                        isLeftButtonWithLoader={false}
                        isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                        onRightButtonClick={dialogState.rightButtonClickHandler}
                        areButtonsInColumn={isMobile}
                    />
                }
            </div>
            <ApprovalMessage
                isMessageShown={didChangesSaved}
                closeMessage={() => setDidChangesSaved(false)}
                text={t('EDIT_LINK_SECTION_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT')}
                isUp={isMobile}
            />
        </>
    )
}