import React, { useRef, useState } from 'react'
import './CreateLinkSection.css'
import { CREATE_LINK_PHASES, PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS, RESIZE_IMAGE_MAX_HEIGHT, RESIZE_IMAGE_MAX_WIDTH } from 'constants/general.constants'
import ColoredAddMedia from 'components/ColoredAddMedia/ColoredAddMedia'
import { useTranslation } from 'react-i18next'
import EditLinkSection from 'components/EditLinkSection/EditLinkSection'
import ImageBox from 'components/ImageBox/ImageBox'
import { isDesktop } from 'react-device-detect'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import { resizeImage } from 'services/imageUtils'

/**
 * Represents the section in which a new link can be created. It consists of 2 phases, one for the image
 * uploading and one for the link properties.
 * @param {string} image - The image of the new link
 * @param {function} setImage - A function for setting the image of the new link
 * @param {function} onButtonClick - A function for handling the 'Create' button click
 * @param {boolean} isButtonProcessing - Determins whether the 'Create' button's loader is shown or not
 * @param {array} selectedProducts - The product list of the new link, selected by the user
 * @param {function} setSelectedProducts - A function for setting the product list of the new link, selected by the user
 * @param {string} description - The description of the new link
 * @param {function} setDescription - A function for setting the description of the new link
 * @param {string} promocode - The promocode of the new link
 * @param {function} setPromocode - A function for setting the promocode for the new link
 * @param {string} promocodeDescription - The promocode's description of the new link
 * @param {function} setPromocodeDescription - A function for setting the promocode's description of the new link
 * @param {boolean} isLinkVanished - The new link's visibility. Determins whether the link is set to be vanished in the future or not
 * @param {function} setIsLinkVanished - A function for setting the new link's visibility
 * @param {string} shoppingUrl - The shopping URL of the new link
 * @param {function} setShoppingUrl - A function for setting the shopping URL of the new link
 * @param {number} createLinkPhase - The phase of the link creation, can be one of: CREATE_LINK_PHASES.ADD_IMAGE_PHASE, CREATE_LINK_PHASES.ADD_LINK_PROPERTIES_PHASE
 * @param {function} setCreateLinkPhase - A function for setting the phase of the link creation
 * @param {boolean} linkPropertiesModified - Determins whether any change was made in the link details or not
 */
export default function CreateLinkSection({
    image = '',
    setImage = () => { },
    onButtonClick = () => { },
    isButtonDisabled = true,
    isButtonProcessing = false,
    selectedProducts = [],
    setSelectedProducts = () => { },
    description = '',
    setDescription = () => { },
    promocode = '',
    setPromocode = () => { },
    promocodeDescription = '',
    setPromocodeDescription = () => { },
    isLinkVanished = false,
    setIsLinkVanished = () => { },
    shoppingUrl = '',
    setShoppingUrl = () => { },
    createLinkPhase = CREATE_LINK_PHASES.ADD_IMAGE_PHASE,
    setCreateLinkPhase = () => { },
    linkPropertiesModified = false
}) {
    const { t } = useTranslation()

    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')

    const fileInputRef = useRef(null)

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function addMedia() {
        fileInputRef.current.value = null
        fileInputRef?.current?.click()
    }

    function goToNextPhase() {
        setCreateLinkPhase(CREATE_LINK_PHASES.ADD_LINK_PROPERTIES_PHASE)
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        setImage(croppedImage)
        setIsImageCropDialogOpen(false)
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    return (
        <div className='create-link-section-container' style={{ width: (isDesktop && createLinkPhase === CREATE_LINK_PHASES.ADD_IMAGE_PHASE) ? '60%' : '100%' }}>
            {
                createLinkPhase === CREATE_LINK_PHASES.ADD_IMAGE_PHASE ? <div className='create-link-section-add-image-phase-container' style={ isDesktop ? { justifyContent: 'space-between', gap: '10px' } : { gap: '30px' } }>
                    {
                        image ? <div className='create-link-section-image-container'>
                            <ImageBox
                                className="create-link-section-add-image"
                                image={image}
                            />
                            <ColoredAddMedia
                                plusFontSizePx={75}
                                textFontSizePx={14}
                                onClick={addMedia}
                                className='create-link-section-image-edit-image-frame'
                                innerClassName='create-link-section-image-edit-image-inner-frame'
                                hasBackground={false}
                            />
                        </div> : <ColoredAddMedia
                            plusFontSizePx={75}
                            textFontSizePx={14}
                            onClick={addMedia}
                        />
                    }
                    <div className={`create-link-section-add-image-phase-button ${image ? '' : 'disabled'}`} onClick={goToNextPhase}>{t('CREATE_LINK_SECTION_BUTTON_TEXT')}</div>
                </div> : <EditLinkSection
                    image={image}
                    setImage={setImage}
                    buttonText={t('CREATE_LINK_SECTION_CREATE_BUTTON_TEXT')}
                    onButtonClick={onButtonClick}
                    isButtonDisabled={isButtonDisabled}
                    isButtonProcessing={isButtonProcessing}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    description={description}
                    setDescription={setDescription}
                    promocode={promocode}
                    setPromocode={setPromocode}
                    promocodeDescription={promocodeDescription}
                    setPromocodeDescription={setPromocodeDescription}
                    isLinkVanished={isLinkVanished}
                    setIsLinkVanished={setIsLinkVanished}
                    shoppingUrl={shoppingUrl}
                    setShoppingUrl={setShoppingUrl}
                    linkPropertiesModified={linkPropertiesModified}
                />
            }
            <input ref={fileInputRef} type="file" accept="image/*" onChange={onImageChange} style={{ display: 'none' }} />
            {
                isImageCropDialogOpen && <ImageCropDialog
                    isDialogOpen={isImageCropDialogOpen}
                    handleDialogClose={closeImageCropDialog}
                    image={cropperTempImage}
                    aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                    onCropDone={onApplyCrop}
                />
            }
        </div>
    )
}