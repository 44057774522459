import React from 'react'
import './AccountSection.css'
import Card from 'components/Card/Card'
import { isMobile } from 'react-device-detect'

/**
 * Represents an account page section.
 * @param {string} title - The title for this section 
 * @param {string} sectionContentClassName - A class name for this section's content 
 * @param {boolean} hasTitle - Determins whether this section has a title or not
 */
export default function AccountSection({
    children,
    title = '',
    sectionContentClassName = '',
    hasTitle = true
}) {
    return (
        <>
            {
                isMobile ? <div className='mobile-account-page-section'>
                    {hasTitle && <div className="account-page-section-title">{title}</div>}
                    <div className={`account-page-section-content ${sectionContentClassName}`}>
                        {children}
                    </div>
                </div> : <Card>
                    <div className='account-page-section'>
                        {hasTitle && <div className="account-page-section-title">{title}</div>}
                        <div className={`account-page-section-content ${sectionContentClassName}`}>
                            {children}
                        </div>
                    </div>
                </Card>
            }
        </>
    )
}