import { request } from '../../services/RequestWrapper';
import { environment } from "../../conf";
import { COMMIT_LOGS_API_URL } from '../../constants/api.constants';

export function CommitLogsAPI(logs, store_id, cart_id) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                "logs": logs,
                "store_id": store_id,
                "cart_id": cart_id,
            },
        }
        request(environment.base_url + COMMIT_LOGS_API_URL, options)
            .then(resolve)
            .catch(reject)
    });
}