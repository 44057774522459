import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './AddProductsDialog.css'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import ImageBox from 'components/ImageBox/ImageBox'
import { useUserDetailsContext } from 'contexts/User'
import {
    ADD_PRODUCTS_DIALOG_FETCH_NEXT_PRODUCTS_PAGE_THRESHOLD_COUNT,
    ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX,
    EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS,
    IMAGE_PLACEHOLDER_URL,
    LINK_PAGES_MAX_WIDTH,
    MAX_PRODUCT_SEARCH_RESULT_NUMBER
} from 'constants/general.constants'
import { ProductSearchAPI } from 'api/products/products'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import { isMobile } from 'react-device-detect'
import { useHistoryContext } from 'contexts/History'

/**
 * Represents the dialog for adding products to the link.
 * @param {boolean} isDialogOpen - Determins whether this dialog is open or not
 * @param {function} handleDialogClose - A function for closing this dialog
 * @param {array} selectedProducts - The list of products the user selected
 * @param {function} setSelectedProducts - A function that sets the list of selected products
 */
export default function AddProductsDialog({
    isDialogOpen,
    handleDialogClose,
    selectedProducts,
    setSelectedProducts
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()

    const [cachedProductsResult, setCachedProductsResult] = useState([])
    const [productsResult, setProductsResult] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [hasMoreProducts, setHasMoreProducts] = useState(true)
    const [isSearchingProducts, setIsSearchingProducts] = useState(false)
    const [shouldShowNoLinks, setShouldShowNoLinks] = useState(false)

    const typingTimeoutRef = useRef(null)

    const imageTableDataStyles = {
        width: isMobile ? '20%' : '6%'
    }

    const buttonTableDataStyles = {
        width: isMobile ? '24%' : '16%'
    }

    const productNameTableDataStyles = {
        width: isMobile ? '52%' : '76%'
    }

    const getNoQueryUnionSet = useCallback(() => {
        return calculateProductsArrayUnion(selectedProducts, cachedProductsResult)
    }, [selectedProducts, cachedProductsResult])

    useEffect(() => {
        productSearch(searchQuery, 0, products => setCachedProductsResult(products))
    }, [])

    useEffect(() => {
        if (searchQuery === '')
            setProductsResult(getNoQueryUnionSet())
    }, [selectedProducts, cachedProductsResult])

    useEffect(() => {
        setShouldShowNoLinks(false)
        setProductsResult([])
        setHasMoreProducts(true)
        clearTypingTimeout(typingTimeoutRef.current)
        if (searchQuery) {
            setIsSearchingProducts(true)
            typingTimeoutRef.current = setTimeout(() => {
                productSearch(searchQuery, 0)
            }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
        } else {
            setProductsResult(getNoQueryUnionSet())
            setIsSearchingProducts(false)
        }

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [searchQuery])

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function handleScroll(e) {
        const scrollPosition = e.target.scrollTop;

        // checks if the scroll position has passed the threshold point for requesting the next products' data page
        if (!isSearchingProducts && hasMoreProducts && scrollPosition >= ((ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX * productsResult.length) - e.target.clientHeight - (ADD_PRODUCTS_DIALOG_FETCH_NEXT_PRODUCTS_PAGE_THRESHOLD_COUNT * ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX))) {
            setIsSearchingProducts(true)
            productSearch(searchQuery, productsResult.length)
        }
    }

    function calculateProductsArrayUnion(array1, array2) {
        const unionArray = []

        for (let i = 0; i < array1.length; i++) {
            const currentProduct = array1[i]
            if (unionArray.filter(product => product?.inventory_id === currentProduct?.inventory_id).length === 0) {
                unionArray.push(array1[i])
            }
        }

        for (let i = 0; i < array2.length; i++) {
            const currentProduct = array2[i]
            if (unionArray.filter(product => product?.inventory_id === currentProduct?.inventory_id).length === 0) {
                unionArray.push(array2[i])
            }
        }

        return unionArray
    }

    function productSearch(query, skip, onResultsReceived = () => { }) {
        setIsSearchingProducts(true)
        ProductSearchAPI(userDetails.businessId, query, MAX_PRODUCT_SEARCH_RESULT_NUMBER, skip)
            .then(products => {
                if (query === searchQuery) {
                    if (query !== '') {
                        setProductsResult(prev => ([
                            ...prev,
                            ...products
                        ]))
                    } else {
                        setProductsResult(prev => ([
                            ...calculateProductsArrayUnion(prev, products)
                        ]))
                    }
                    setHasMoreProducts(products.length === MAX_PRODUCT_SEARCH_RESULT_NUMBER)
                    setIsSearchingProducts(false)
                }
                
                setShouldShowNoLinks(true)
                onResultsReceived(products)
            }).catch(error => {
                console.log(error)
                setIsSearchingProducts(false)
            })
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleChange(e) {
        setSearchQuery(e.target.value)
    }

    function isProductSelected(productToCheck) {
        if (!selectedProducts)
            return false

        return selectedProducts.filter(product => product?.inventory_id === productToCheck?.inventory_id).length > 0
    }

    function addProduct(product) {
        setSelectedProducts(prev => [...prev, product])
    }

    function removeProduct(productToRemove) {
        setSelectedProducts(selectedProducts.filter(product => product.inventory_id !== productToRemove.inventory_id))
    }

    function closeDialog() {
        if (handleDialogClose)
            handleDialogClose()
        history.goBack()
    }

    function onProductClick(product) {
        if (!isProductSelected(product)) {
            addProduct(product)
        }
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            hasNoWidthLimit={true}
            backdropColor='rgba(255, 255, 255, 0.85)'
            borderRadiusPx={5}
            paddingPx={0}
            width={LINK_PAGES_MAX_WIDTH}
            dialogLayout={<>
                <div className="edit-link-section-add-products-dialog-content">
                    <div className="edit-link-section-add-products-dialog-topbar" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                        <div className="edit-link-section-add-products-dialog-topbar-title-container">
                            <div className="edit-link-section-add-products-dialog-topbar-title">{t('ADD_PRODUCTS_DIALOG_TOPBAR_TITLE')}</div>
                            <div className="edit-link-section-add-products-dialog-topbar-selected-products-count">{t('ADD_PRODUCTS_DIALOG_TOPBAR_SELECTED_PRODUCTS_COUNT', { count: selectedProducts.length })}</div>
                        </div>
                        <div className="edit-link-section-add-products-dialog-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                            <input className='edit-link-section-add-products-dialog-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                            {
                                searchQuery.length === 0 ?
                                    <CiSearch className='edit-link-section-add-products-dialog-topbar-search-box-image' /> :
                                    <IoCloseOutline className='edit-link-section-add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                            }
                        </div>
                    </div>
                    <table className='edit-link-section-add-products-dialog-products-table'>
                        <thead>
                            <tr>
                                <th className='products-table-header edit-link-section-add-products-dialog-products-table-header-image' style={imageTableDataStyles}></th>
                                <th className='products-table-header edit-link-section-add-products-dialog-products-table-header-product-name' style={productNameTableDataStyles}>{t('ADD_PRODUCTS_DIALOG_PRODUCTS_TABLE_PRODUCT_HEADER')}</th>
                                <th className='products-table-header edit-link-section-add-products-dialog-products-table-header-button' style={buttonTableDataStyles}></th>
                            </tr>
                        </thead>
                        <tbody onScroll={handleScroll}>
                            {
                                productsResult.length > 0 && productsResult.map(product => <tr key={product?.inventory_id} onClick={() => onProductClick(product)}>
                                    <td className='products-table-cell edit-link-section-add-products-dialog-products-table-cell-image' style={imageTableDataStyles}>
                                        <ImageBox
                                            className='edit-link-section-add-products-dialog-product-image'
                                            image={product?.image?.src ?? IMAGE_PLACEHOLDER_URL}
                                            isImageFitCover={true}
                                        />
                                    </td>
                                    <td className='products-table-cell edit-link-section-add-products-dialog-products-table-cell-product-name' style={productNameTableDataStyles}>{product?.title}</td>
                                    <td className='products-table-cell edit-link-section-add-products-dialog-products-table-cell-button' style={buttonTableDataStyles}>
                                        {
                                            isProductSelected(product) ? <div className='edit-link-section-add-products-dialog-product-button selected' onClick={() => removeProduct(product)}>
                                                {t('ADD_PRODUCTS_DIALOG_PRODUCTS_TABLE_ITEM_REMOVE_BUTTON_TEXT')}
                                            </div> : <div className='edit-link-section-add-products-dialog-product-button'>
                                                <div className='edit-link-section-add-products-dialog-product-button-plus'>+</div>
                                                <div className='edit-link-section-add-products-dialog-product-button-text'>{t('ADD_PRODUCTS_DIALOG_PRODUCTS_TABLE_ITEM_ADD_PRODUCT_BUTTON_TEXT')}</div>
                                            </div>
                                        }
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    <div className="edit-link-section-add-products-dialog-done-button-container">
                        <div className="edit-link-section-add-products-dialog-done-button" onClick={closeDialog}>{t('ADD_PRODUCTS_DIALOG_DONE_BUTTON_TEXT')}</div>
                    </div>
                    {
                        isSearchingProducts && <div className="edit-link-section-add-products-dialog-loader-container">
                            <LoaderPage isContained={isMobile} styles={{ backgroundColor: '#ffffffaa' }} marginBottom={0} isFullScreen={true} />
                        </div>
                    }
                    {
                        (shouldShowNoLinks && productsResult.length === 0) && <div className='edit-link-section-add-products-dialog-no-results-found-container'>
                            <div className="edit-link-section-add-products-dialog-no-results-found-content">
                                <div className="edit-link-section-add-products-dialog-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                            </div>
                        </div>
                    }
                </div>
            </>}
        />
    )
}