import React from 'react'
import './StoreSettingsLinkStyleSection.css'

/**
 * Represents a section inside the link style section for the store settings.
 * @param {string} title - The section's title
 */
export default function StoreSettingsLinkStyleSection({ 
    title,
    children
}) {
    return (
        <div className='store-settings-link-style-section-container'>
            <div className="store-settings-link-style-section-title">{title}</div>
            <div className="store-settings-link-style-section-content">{children}</div>
        </div>
    )
}