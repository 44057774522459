import React from 'react'
import './AddMedia.css'
import { useTranslation } from 'react-i18next'

export default function AddMedia({ 
    withBorder = false,
    aspectRatio = '1',
    onAddMedia = () => {} 
}) {
    const { t } = useTranslation()
    
    return (
        <div className='add-media-container' style={{ border: withBorder ? '2px solid #d6d6d6' : null, aspectRatio: aspectRatio }}>
            <div className="add-media-button" onClick={onAddMedia}>
                <div className="add-media-button-plus">+</div>
                <div className="add-media-button-text">{t('EDIT_LINK_PAGE_ADD_MEDIA_BUTTON_TEXT')}</div>
            </div>
        </div>
    )
}