import React, { useState } from 'react'
import './ShopifyConnect.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { IoIosArrowForward } from 'react-icons/io'
import { Trans, useTranslation } from 'react-i18next'
import { AuthConnectStoreAPI, ConnectStoreAPI } from 'api/store/store'
import { getQueryVariable } from 'services/SystemService'
import { AUTH_QUERY_PARAMETER_KEY, EXTERNAL_AUTH_PAGE_URL, LINKS_PAGE_URL, LOGIN_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { BOXED_MESSAGE_TYPES, PAYLINK_SMALL_LOGO_URL, SHOPIFY_CONNECT_NAVIGATE_TO_ADMIN_PAGE_DELAY, SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX, SHOPIFY_LOGO_URL } from 'constants/general.constants'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import ShopifyConnectStore from 'components/ShopifyConnectStore/ShopifyConnectStore'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { isMobile } from 'react-device-detect'

/**
 * Represents a Shopify connection interface.
 * @param {boolean} hasShopifyStore - Determins whether the user is connected to a shopify store or not
 */
export default function ShopifyConnect({
    hasShopifyStore = false
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails } = useUserDetailsContext()
    const { verify } = useAuthContext()

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isBoxedMessageShown, setIsBoxedMessageShown] = useState(false)
    const [boxedMessage, setBoxedMessage] = useState('')

    function connectToShopifyAccount() {
        setIsPageLoading(true)
        AuthConnectStoreAPI(getQueryVariable(AUTH_QUERY_PARAMETER_KEY))
            .then(response => {
                verify(data => {
                    notifyProcessFinished()
                }, error => {
                    console.log(error)
                    setIsPageLoading(false)
                })
            })
            .catch(error => {
                console.log(error)
                setBoxedMessage(t('SHOPIFY_CONNECT_CONNECTION_FAILED_ERROR_MESSAGE'))
                setIsBoxedMessageShown(true)
                setIsPageLoading(false)
            })
    }

    function navigateToLogin() {
        history.push(LOGIN_PAGE_URL + window.location.search)
    }

    function notifyProcessFinished() {
        window.opener.postMessage('CompletedPaylinkProcess', '*')
        setTimeout(() => {
            history.push(LINKS_PAGE_URL)
        }, SHOPIFY_CONNECT_NAVIGATE_TO_ADMIN_PAGE_DELAY)
    }

    function  connectShopifyStore(shopUrlPrefix) {
        // TODO change to the paily listing url when available
        window.open("https://apps.shopify.com/paylink", "_blank", "noreferrer")
        // TODO - shopify review don't allow requesting myshopify url. So for now redirecting to app store.
        // setIsPageLoading(true)
        // ConnectStoreAPI(shopUrlPrefix + SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX)
        //     .then(redirectUrl => {
        //         setIsPageLoading(false)
        //         window.open(redirectUrl, "_blank", "noreferrer")
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         setIsPageLoading(false)
        //         setBoxedMessage(t('SHOPIFY_CONNECT_CONNECTION_FAILED_ERROR_MESSAGE'))
        //         setIsBoxedMessageShown(true)
        //     })
    }

    return (
        <>
            <form className='shopify-connect-container'>
                <div className="shopify-connect-content" style={{ padding: isMobile ? '20px' : '30px 60px' }}>
                    {
                        window.location.pathname === EXTERNAL_AUTH_PAGE_URL ? <>
                            <div className="shopify-connect-upper-frame">
                                <div className="shopify-connect-connection-frame">
                                    <ImageBox
                                        className='shopify-connect-connection-frame-shopify-logo'
                                        imageClassName='shopify-connect-connection-frame-image'
                                        image={SHOPIFY_LOGO_URL} />
                                    <IoIosArrowForward className="shopify-connect-connection-frame-arrow-separator" />
                                    <ImageBox
                                        className='shopify-connect-connection-frame-paylink-small-logo'
                                        imageClassName='shopify-connect-connection-frame-image'
                                        image={PAYLINK_SMALL_LOGO_URL} />
                                </div>
                                <div className="shopify-connect-title">{t('SHOPIFY_CONNECT_TITLE')}</div>
                                <div className="shopify-connect-you-are-logged-in-as-title">{t('SHOPIFY_CONNECT_LOGGED_IN_AS_LABEL', { username: userDetails.username })}</div>
                            </div>
                            {
                                hasShopifyStore ? <>
                                    <BoxedMessage
                                        type={BOXED_MESSAGE_TYPES.INFO}
                                        message={
                                            <Trans
                                                i18nKey={t('SHOPIFY_CONNECT_ALREADY_CONNECTED_TO_STORE_LABEL', { businessName: userDetails?.businessName })}
                                            />
                                        }
                                    />
                                    <div className="shopify-connect-you-can-disconnect-text">{t('SHOPIFY_CONNECT_YOU_CAN_DISCONNECT_AND_TRY_AGAIN_LABEL')}</div>
                                    <div className="shopify-connect-buttons-box">
                                        <div className="shopify-connect-connect-to-different-account-button shopify-connect-button" onClick={navigateToLogin}>{t('SHOPIFY_CONNECT_CONTINUE_BUTTON_TEXT')}</div>
                                        <div className="shopify-connect-cancel-button shopify-connect-button" onClick={notifyProcessFinished}>{t('SHOPIFY_CONNECT_CANCEL_BUTTON_TEXT')}</div>
                                    </div>
                                </> : <>
                                    <div className="shopify-connect-agree-to-share-information-title">{t('SHOPIFY_CONNECT_AGREE_TO_SHARE_INFORMATION_LABEL')}</div>
                                    {isBoxedMessageShown && <BoxedMessage type={BOXED_MESSAGE_TYPES.ERROR} message={boxedMessage} />}
                                    <div className="shopify-connect-buttons-box">
                                        <LoaderButton
                                            buttonText={t('SHOPIFY_CONNECT_CONNECT_SHOPIFY_BUTTON_TEXT')}
                                            isLoading={isPageLoading}
                                            className='shopify-connect-connect-shopify-account-button shopify-connect-button'
                                            onClick={connectToShopifyAccount}
                                        />
                                        <div className="shopify-connect-cancel-button shopify-connect-button" onClick={notifyProcessFinished}>{t('SHOPIFY_CONNECT_CANCEL_BUTTON_TEXT')}</div>
                                    </div>
                                </>
                            }

                        </> : <>
                            <div className="shopify-connect-launch-title-section">
                                <div className="shopify-connect-launch-store-title">{t('SHOPIFY_CONNECT_LAUNCH_STORE_TITLE')}</div>
                                <div className="shopify-connect-launch-store-subtitle">{t('SHOPIFY_CONNECT_LAUNCH_STORE_SUBTITLE')}</div>
                            </div>
                            <div className="shopify-connect-store-provider-title">{t('SHOPIFY_CONNECT_STORE_PROVIDER_TITLE')}</div>
                            <ShopifyConnectStore
                                isConnectingStore={isPageLoading}
                                isShopifyConnectionFail={isBoxedMessageShown}
                                connectShopifyStoreFunction={connectShopifyStore}
                            />
                        </>
                    }
                </div>
            </form>
        </>
    )
}