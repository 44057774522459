import React, { useState } from 'react'
import './PreviewLinkSection.css'
import ImageBox from 'components/ImageBox/ImageBox'
import LinkAnalyticsSection from 'components/LinkAnalyticsSection/LinkAnalyticsSection'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'services/utils'
import { getCurrencySymbol } from 'services/currency'
import { isMobile } from 'react-device-detect'
import { RiDeleteBin6Line, RiEditLine } from 'react-icons/ri'
import { BiLink } from 'react-icons/bi'
import { copyToClipboard } from 'services/SystemService'
import { useHistoryContext } from 'contexts/History'
import { ADMIN_PAGES_URL_PREFIX, LINKS_PAGE_URL, LINKS_URL_PREFIX } from 'constants/routes.constants'
import { environment } from 'conf'
import { useUserDetailsContext } from 'contexts/User'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { DeleteLinkAPI } from 'api/links/links'
import { DEFAULT_CURRENCY_CODE, EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT } from 'constants/general.constants'

/**
 * Represents the section in which the link data is displayed.
 * @param {string} image - The image url of the link  
 * @param {string} description - The description of the link  
 * @param {number} linkViews - The number of views the link has
 * @param {number} linkViews - The number of views the link has
 * @param {number} linkRevenue - The total revenue of the link
 * @param {number} linkCheckoutsCompleted - The number of checkouts done on the link's products
 * @param {number} linkCheckoutRequests - The number of checkouts requests done on the link's products
 * @param {string} linkCurrency - The currency of the link's products price which is set on link's creation
 */
export default function PreviewLinkSection({
    linkId,
    image,
    description,
    linkViews,
    linkRevenue,
    linkCheckoutsCompleted,
    linkCheckoutRequests,
    promocode,
    promocodeDescription,
    isLinkExpired,
    linkCurrency = DEFAULT_CURRENCY_CODE
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails } = useUserDetailsContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    function editLink() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${LINKS_URL_PREFIX}/${linkId}/edit`)
    }

    async function copyLinkToClipboard() {
        const url = `${environment.frontend_url}${userDetails?.username}/${linkId}`
        await copyToClipboard(url)
        alert(`${url} was copied to clipboard`)
    }

    function showDeleteLinkConfirmationDialog() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_TITLE'),
            message: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_MESSAGE'),
            leftButtonText: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteLink,
            isRightButtonWithLoader: true
        })
    }

    function deleteLink() {
        DeleteLinkAPI(linkId)
            .then(response => {
                history.push(LINKS_PAGE_URL)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function getTitle() {
        return description.length > EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT ? description.slice(0, EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT) : description
    }

    return (
        <div className='preview-link-section'>
            <div className="preview-link-section-link-details">
                <ImageBox
                    className="preview-link-section-link-image"
                    image={image}
                    enlargeImageOnClick={true}
                />
                {isMobile ? <div className='mobile-preview-link-section-buttons-section'>
                    <div className="mobile-preview-link-section-copy-link-button mobile-preview-link-section-button" onClick={copyLinkToClipboard}>
                        <BiLink className="mobile-preview-link-section-copy-link-button-image mobile-preview-link-section-button-image" />
                        <div className="mobile-preview-link-section-copy-link-button-text mobile-preview-link-section-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_COPY_LINK_BUTTON_TEXT')}</div>
                    </div>
                    {
                        !isLinkExpired && <div className="mobile-preview-link-section-edit-link-button mobile-preview-link-section-button" onClick={editLink}>
                            <RiEditLine className="mobile-preview-link-section-edit-link-button-image mobile-preview-link-section-button-image" />
                            <div className="mobile-preview-link-section-edit-link-button-text mobile-preview-link-section-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_EDIT_LINK_BUTTON_TEXT')}</div>
                        </div>
                    }
                    <div className="mobile-preview-link-section-delete-link-button mobile-preview-link-section-button" onClick={showDeleteLinkConfirmationDialog}>
                        <RiDeleteBin6Line className="mobile-preview-link-section-delete-link-button-image mobile-preview-link-section-button-image" />
                        <div className="mobile-preview-link-section-delete-link-button-text mobile-preview-link-section-button-text">{t('PREVIEW_LINK_PAGE_TOPBAR_DELETE_LINK_BUTTON_TEXT')}</div>
                    </div>
                </div> : <div className="preview-link-section-link-description">{getTitle()}</div>}
            </div>
            {isMobile && <div className="preview-link-section-link-description" style={{ padding: '0' }}>{description}</div>}
            {
                (promocode && promocodeDescription) ? <div className="preview-link-section-promocode">{t('PREVIEW_LINK_SECTION_PROMOCODE_SECTION_TITLE', { promocode, description: promocodeDescription })}</div> : (
                    (promocode && !promocodeDescription) && <div className="preview-link-section-promocode">{t('PREVIEW_LINK_SECTION_PROMOCODE_SECTION_TITLE_NO_DESCRIPTION', { promocode })}</div>
                )
            }
            <div className="preview-link-section-link-analytics">
                <div className="preview-link-section-link-analytics-data">
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_VIEWS_TITLE')}
                        value={formatNumber(linkViews)}
                    />
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_REVENUE_TITLE')}
                        value={`${getCurrencySymbol(linkCurrency)}${formatNumber(linkRevenue)}`}
                    />
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CHECKOUTS_COMPLETED_TITLE')}
                        value={formatNumber(linkCheckoutsCompleted)}
                    />
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CHECKOUTS_REQUESTED_TITLE')}
                        value={formatNumber(linkCheckoutRequests)}
                    />
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CR_TITLE')}
                        value={`${formatNumber((linkCheckoutsCompleted / linkViews) * 100)}%`}
                    />
                    <LinkAnalyticsSection
                        title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CART_AVERAGE_TITLE')}
                        value={`${getCurrencySymbol(linkCurrency)}${formatNumber(linkRevenue / linkCheckoutsCompleted)}`}
                    />
                </div>
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    title={dialogState.title}
                    message={dialogState.message}
                    handleDialogClose={dialogState.handleDialogClose}
                    closeOnRightClick={false}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    leftButtonText={dialogState.leftButtonText}
                    isLeftButtonWithLoader={false}
                    rightButtonText={dialogState.rightButtonText}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    areButtonsInColumn={isMobile}
                    isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                />
            }
        </div>
    )
}