export const ADMIN_PAGES_URL_PREFIX = '/admin'
export const LINKS_URL_PREFIX = '/links'
export const SPECIFIC_LINK_URL_PREFIX = LINKS_URL_PREFIX + '/:linkId'
export const PREVIEW_LINK_PAGE_SUFFIX = '/preview'
export const EDIT_LINK_PAGE_SUFFIX = '/edit'

export const WELCOME_PAGE_URL = '/'
export const LOGIN_PAGE_URL = '/login'
export const SIGN_UP_PAGE_URL = '/register'
export const SIGN_UP_REQUEST_PAGE_URL = '/register-request'
export const CREATE_NEW_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX + '/new'
export const LINKS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX
export const EDIT_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_LINK_URL_PREFIX + '/edit'
export const PREVIEW_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_LINK_URL_PREFIX + PREVIEW_LINK_PAGE_SUFFIX
export const INFLUENCERS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/influencers'
export const ANALYTICS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/analytics'
export const ACCOUNT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/my-account'
export const FORGOT_PASSWORD_PAGE_URL = '/forgot-password'
export const CREATE_NEW_PASSWORD_PAGE_URL = '/reset-password'
export const EXTERNAL_AUTH_PAGE_URL = '/auth'
export const PRIVACY_POLICY_PAGE_URL = '/privacy-policy'
export const TERMS_PAGE_URL = '/terms'
export const STORE_SETTINGS_PAGE_URL_PREFIX = '/store-settings'
export const STORE_SETTINGS_PAGE_URL = `${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}/:store`
export const PRICING_PLANS_PAGE_URL = '/pricing-plans'
export const SHOPIFY_HELP_PAGE_URL = '/help/shopify'
export const ACCOUNT_VERIFICATION_PAGE_URL = '/verify-account'

export const ROLE_QUERY_PARAMETER_KEY = 'role'
export const ROLE_QUERY_PARAMETER_EDIT_VALUE = 'edit'
export const ROLE_QUERY_PARAMETER_CREATE_VALUE = 'create'
export const LINK_ID_QUERY_PARAMETER_KEY = 'linkId'
export const AUTH_QUERY_PARAMETER_KEY = 'auth'
export const RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY = 'token'