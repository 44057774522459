import { logger } from '../services/CloudLogger'

export default class Stack {
    constructor() {
      this.storage = [];
    };
  
    push(element) {
        this.storage.push(element);
    };
  
    pop() {
        if (!this.isEmpty()) {
            // LIFO - pop, FIFO - shift
            return this.storage.pop();
        }
        else {
            logger.log('Stack is empty');
        }
    };
  
    isEmpty() {
        if (this.storage.length === 0) {
            return true;
        }
        else {
            return false;
        }
    };
  }