import React, { useRef, useState } from 'react'
import './ForgotPasswordPage.css'
import { useTranslation } from 'react-i18next'
import TopBar from 'components/TopBar/TopBar'
import { PAYLINK_LOGO_MAX_WIDTH_PX, PAYLINK_LOGO_URL } from 'constants/general.constants'
import { useHistoryContext } from 'contexts/History'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { ResetPasswordRequestAPI } from 'api/auth/auth'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'

export default function ForgotPasswordPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isAbleToResetPassword, setIsAbleToResetPassword] = useState(false)
    const [value, setValue] = useState('')
    const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogProperties, setDialogProperties] = useState({
        title: '',
        leftButtonText: '',
        rightButtonText: ''
    })

    const inputRef = useRef(null)

    function resetPassword() {
        setIsResetPasswordInProgress(true)
        ResetPasswordRequestAPI(value)
            .then(() => {
                showPasswordResetEmailSentDialog()
                setIsResetPasswordInProgress(false)
            }).catch(error => {
                setIsResetPasswordInProgress(false)
                console.log(error)
                showPasswordResetErrorDialog()
            })
    }

    function showPasswordResetEmailSentDialog() {
        setDialogProperties({
            title: t('RESET_PASSWORD_EMAIL_SENT_DIALOG_TITLE'),
            leftButtonText: t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_EMAIL_SENT_DIALOG_LEFT_BUTTON_TEXT')
        })
        setIsDialogOpen(true)
        history.addBackHandler(() => setIsDialogOpen(false))
    }

    function showPasswordResetErrorDialog() {
        setDialogProperties({
            title: t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_ERROR_DIALOG_TITLE'),
            leftButtonText: t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_ERROR_DIALOG_LEFT_BUTTON_TEXT')
        })
        setIsDialogOpen(true)
    }

    function handleUsernameChange(value) {
        setIsAbleToResetPassword(value !== '')
        setValue(value)
    }

    function backToLogin() {
        history.goBack()
    }

    function closeDialog() {
        setIsDialogOpen(false)
    }

    return (
        <form className='forgot-password-page-container'>
            <div className="forgot-password-page-content">
                <div className="forgot-password-page-title-section">
                    <div className="forgot-password-page-title auth-page-title">{t('FORGOT_PASSWORD_PAGE_TITLE')}</div>
                    <div className="forgot-password-page-subtitle">{t('FORGOT_PASSWORD_PAGE_SUBTITLE')}</div>
                </div>
                <div className="forgot-password-page-input-button-section">
                    <input ref={inputRef} className='forgot-password-input-field' placeholder={t('FORGOT_PASSWORD_PAGE_USERNAME_INPUT_PLACEHOLDER')} type='text' onChange={e => handleUsernameChange(e.target.value)} />
                    <LoaderButton
                        className='forgot-password-reset-password-button'
                        isLoading={isResetPasswordInProgress}
                        buttonText={t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_BUTTON_TEXT')}
                        isDisabled={!isAbleToResetPassword}
                        renderAsButton={true}
                        onClick={resetPassword} />
                </div>
                <div className="forgot-password-page-back-to-login-button" onClick={backToLogin}>{t('FORGOT_PASSWORD_PAGE_BACK_TO_LOGIN_BUTTON_TEXT')}</div>
            </div>
            {
                isDialogOpen && <PaydinDialog
                    title={dialogProperties.title}
                    isDialogOpen={isDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={dialogProperties.leftButtonText}
                    rightButtonText={dialogProperties.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    isLeftButtonWithLoader={false}
                    isRightButtonWithLoader={false}
                />
            }
        </form>
    )
}