import React, { useRef, useState } from 'react'
import './LoginPage.css'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import {
    AUTH_QUERY_PARAMETER_KEY,
    EXTERNAL_AUTH_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    LINKS_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL
} from 'constants/routes.constants'
import {
    BOXED_MESSAGE_TYPES,
    USER_PASSWORD_MIN_LENGTH
} from 'constants/general.constants'
import { getQueryVariable } from 'services/SystemService'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useAuthContext } from 'contexts/User'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'

export default function LoginPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { login } = useAuthContext()

    const [showPassword, setShowPassword] = useState(false)
    const [isLoginInProcess, setIsLoginInProcess] = useState(false)
    const [inputState, setInputState] = useState({
        username: '',
        password: ''
    })
    const [loginFailed, setLoginFailed] = useState(false)

    const passwordInputRef = useRef(null)

    function togglePasswordVisibility() {
        setShowPassword(prev => !prev)
    }
    function handleInputChange(e) {
        setInputState(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    function handleLogin() {
        passwordInputRef.current.blur()

        setIsLoginInProcess(true)
        setLoginFailed(false)
        login(inputState.username, inputState.password, (isSuccess) => {
            if (isSuccess) {
                if (getQueryVariable(AUTH_QUERY_PARAMETER_KEY)) {
                    history.push(EXTERNAL_AUTH_PAGE_URL + window.location.search)
                } else {
                    history.push(LINKS_PAGE_URL)
                }
            } else {
                setLoginFailed(true)
                setIsLoginInProcess(false)
            }
        })
    }

    function navigateToSignUp() {
        history.push(SIGN_UP_REQUEST_PAGE_URL)
    }

    function navigateToForgotPassword() {
        history.push(FORGOT_PASSWORD_PAGE_URL)
    }

    return (
        <>
            <div className='login-page-container'>
                <form dir={t('direction.dir')} className="login-page-content">
                    <div className="login-page-title auth-page-title">{t('LOGIN_PAGE_TITLE')}</div>
                    {
                        loginFailed && <BoxedMessage
                            message={t('LOGIN_PAGE_LOGIN_ERROR_MESSAGE_TEXT')}
                            type={BOXED_MESSAGE_TYPES.ERROR} />
                    }
                    <div className="login-page-fields-form">
                        <div className="login-page-fields-form-username-input-container input-field-container">
                            <input className='input-field' placeholder={t('LOGIN_PAGE_USERNAME_INPUT_PLACEHOLDER')} name='username' type='text' onChange={handleInputChange} />
                        </div>
                        <div className="login-page-fields-form-password-input-container input-field-container">
                            <input ref={passwordInputRef} className='input-field' placeholder={t('LOGIN_PAGE_PASSWORD_INPUT_PLACEHOLDER')} name='password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} />
                            {
                                showPassword ? <AiOutlineEye className='login-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                                    : <AiOutlineEyeInvisible className='login-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                            }
                        </div>
                    </div>
                    <LoaderButton
                        className="login-page-login-button"
                        buttonText={t('LOGIN_PAGE_LOGIN_BUTTON_TEXT')}
                        isLoading={isLoginInProcess}
                        renderAsButton={true}
                        isDisabled={inputState.password.length < USER_PASSWORD_MIN_LENGTH}
                        onClick={handleLogin} />
                    <div className="login-page-forgot-password-button" onClick={navigateToForgotPassword}>{t('LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_TEXT')}</div>
                    <div className="login-page-dont-have-account-section">
                        <div className="login-page-dont-have-account-text">{t('LOGIN_PAGE_DONT_HAVE_ACCOUNT_TEXT')}</div>
                        <div className="login-page-dont-have-account-sign-up-button" onClick={navigateToSignUp}>{t('LOGIN_PAGE_DONT_HAVE_ACCOUNT_SIGN_UP_BUTTON_TEXT')}</div>
                    </div>
                </form>
                <PrivaciesFooterText />
            </div>
        </>
    )
}