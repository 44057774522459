import React, { useRef, useState } from 'react'
import './EditLinkSection.css'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'
import { useTranslation } from 'react-i18next'
import { RxQuestionMark } from 'react-icons/rx'
import ImageBox from 'components/ImageBox/ImageBox'
import { IoIosArrowForward } from 'react-icons/io'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import { isMobile } from 'react-device-detect'
import { EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT, IMAGE_PLACEHOLDER_URL, LINK_VIEW_MODES, PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS, PRICING_PLAN_DATA, PROMOCODE_DESCRIPTION_INPUT_MAX_LENGTH, PROMOCODE_INPUT_MAX_LENGTH, RESIZE_IMAGE_MAX_HEIGHT, RESIZE_IMAGE_MAX_WIDTH } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import EditLinkSectionInput from 'components/EditLinkSectionInput/EditLinkSectionInput'
import { resizeImage } from 'services/imageUtils'

/**
 * Represents the section in which the link data can be both displayed and edited.
 * @param {string} mode - The mode in which the user is in. Can be either one of LINK_VIEW_MODES.EDIT or LINK_VIEW_MODES.CREATE. 
 *                          It is useful due to the fact that this component can be either in edit link page and in create link page.
 * @param {string} image - The image url of the link
 * @param {function} setImage - A function that sets the user's selected image
 * @param {string} buttonText - This section's lower button's text
 * @param {function} onButtonClick - This section's lower button's click handler
 * @param {boolean} isButtonProcessing - Determins whether the loader of this section's lower button is shown or not
 * @param {array} selectedProducts - The list of products selected by the user
 * @param {function} setSelectedProducts - A function that sets the list of products selected by the user
 * @param {string} description - The description of the current link
 * @param {function} setDescription - A function that sets the description of the current link
 * @param {string} promocode - A promocode for the current link
 * @param {function} setPromocode - A function that sets the promocode for the current link
 * @param {string} promocodeDescription - The description of the promocode for the current link
 * @param {function} setPromocodeDescription - A function that sets the description of the promocode for the current link
 * @param {boolean} isLinkVanished - Determins whether the link will be vanished in the future or not
 * @param {function} setIsLinkVanished - A function that sets the link vanishing
 * @param {string} shoppingUrl - The shopping URL of the current link
 * @param {function} setShoppingUrl - A function that sets the shopping URL of the current link
 * @param {boolean} linkPropertiesModified - Determins whether any change was made in the link details or not
 */
export default function EditLinkSection({
    mode,
    image = '',
    setImage = () => { },
    buttonText = '',
    onButtonClick = () => { },
    isButtonDisabled = true,
    isButtonProcessing = false,
    selectedProducts = [],
    setSelectedProducts = () => { },
    description = '',
    setDescription = () => { },
    promocode = '',
    setPromocode = () => { },
    promocodeDescription = '',
    setPromocodeDescription = () => { },
    isLinkVanished = false,
    setIsLinkVanished = () => { },
    shoppingUrl = '',
    setShoppingUrl = () => { },
    linkPropertiesModified = false
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [isAddProductsDialogOpen, setIsAddProductsDialogOpen] = useState(false)
    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')

    const fileInputRef = useRef(null)

    function openAddProductsDialog() {
        setIsAddProductsDialogOpen(true)
    }

    function closeAddProductDialog() {
        setIsAddProductsDialogOpen(false)
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function addMedia() {
        fileInputRef.current.value = null
        fileInputRef.current.click()
    }

    function renderPromocodeTextFields() {
        return <>
            <EditLinkSectionInput
                title={t('EDIT_LINK_SECTION_PROMOCODE_INPUT_TITLE')}
                placeholder={t('EDIT_LINK_SECTION_PROMOCODE_INPUT_PLACEHOLDER')}
                maxLength={PROMOCODE_INPUT_MAX_LENGTH}
                tooltipText={t('EDIT_LINK_SECTION_PROMOCODE_TOOLTIP_TEXT')}
                value={promocode}
                setValue={setPromocode}
            />
            <EditLinkSectionInput
                title={t('EDIT_LINK_SECTION_PROMOCODE_DESCRIPTION_INPUT_TITLE')}
                placeholder={t('EDIT_LINK_SECTION_PROMOCODE_DESCRIPTION_INPUT_PLACEHOLDER')}
                maxLength={PROMOCODE_DESCRIPTION_INPUT_MAX_LENGTH}
                tooltipText={t('EDIT_LINK_SECTION_PROMOCODE_DESCTIPTION_TOOLTIP_TEXT')}
                value={promocodeDescription}
                setValue={setPromocodeDescription}
            />
        </>
    }

    function isFreeUser() {
        return userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name.toUpperCase()
    }

    function renderVanishLinkSubtitle() {
        if (isFreeUser()) {
            if (isLinkVanished)
                return t('EDIT_LINK_SECTION_LINK_VISIBILITY_SUBTITLE_FREE_VANISHED_LINK')
            else
                return t('EDIT_LINK_SECTION_LINK_VISIBILITY_SUBTITLE_FREE_NOT_VANISHED_LINK')
        } else {
            if (isLinkVanished)
                return t('EDIT_LINK_SECTION_LINK_VISIBILITY_SUBTITLE_NOT_FREE_VANISHED_LINK')
            else
                return t('EDIT_LINK_SECTION_LINK_VISIBILITY_SUBTITLE_NOT_FREE_NOT_VANISHED_LINK')
        }
    }

    function showDiscardChangesDialog() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_TITLE'),
            message: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_MESSAGE'),
            leftButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: goToPricingPlans
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function goToPricingPlans() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        setImage(croppedImage)
        setIsImageCropDialogOpen(false)
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    return (
        <div className='edit-link-section-container'>
            <div className="edit-link-section-link-details" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <div className="edit-link-section-link-image-container" onClick={addMedia}>
                    <ImageBox
                        className="edit-link-section-link-image"
                        image={image ? image : IMAGE_PLACEHOLDER_URL}
                    />
                    <div className="edit-link-section-add-media-button">
                        <div className="edit-link-section-add-media-button-inner-container">
                            <div className="edit-link-section-add-media-button-plus">+</div>
                            <div className="edit-link-section-add-media-button-text">{t('EDIT_LINK_SECTION_ADD_MEDIA_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                </div>
                <EditLinkSectionInput
                    title={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_TITLE')}
                    placeholder={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_PLACEHOLDER')}
                    maxLength={EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT}
                    tooltipText={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TOOLTIP_TEXT')}
                    value={description}
                    setValue={setDescription}
                    isMultiline={true}
                    rows={2}
                />
            </div>

            <div className="edit-link-section-add-products-button" onClick={openAddProductsDialog}>
                <div className="edit-link-section-add-products-button-title">{t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_TITLE')}</div>
                <div className="edit-link-section-add-products-button-right-section">
                    <div className="edit-link-section-add-products-button-right-section-product-quantity">{selectedProducts.length === 1 ? t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_QUANTITY_ONE') : t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_QUANTITY_OTHER', { quantity: selectedProducts.length })}</div>
                    <IoIosArrowForward className="edit-link-section-add-products-button-right-section-image" />
                </div>
            </div>
            <form className="edit-link-section-link-data-form">
                <div className="edit-link-section-link-data-form-inputs">
                    <div className="edit-link-section-link-data-link-visibility">
                        <PaydinSwitch disabled={mode === LINK_VIEW_MODES.EDIT || isFreeUser()} checked={isLinkVanished} setChecked={setIsLinkVanished} />
                        <div className="edit-link-section-link-data-link-visibility-title-container">
                            <div className="edit-link-section-link-data-link-visibility-title">{t('EDIT_LINK_SECTION_LINK_VISIBILITY_TITLE')}</div>
                            <div className="edit-link-section-link-data-link-visibility-subtitle-container">
                                <div className="edit-link-section-link-data-link-visibility-subtitle">{renderVanishLinkSubtitle()}</div>
                                {isFreeUser() && <div className='edit-link-section-link-data-link-visibility-upgrade-button' onClick={linkPropertiesModified ? showDiscardChangesDialog : goToPricingPlans}>{t('EDIT_LINK_SECTION_LINK_VISIBILITY_UPGRADE_BUTTON_TEXT')}</div>}
                            </div>
                        </div>
                    </div>
                    {
                        isMobile ? renderPromocodeTextFields() : <div className="edit-link-section-row">
                            {renderPromocodeTextFields()}
                        </div>
                    }
                    <EditLinkSectionInput
                        title={t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_TITLE')}
                        placeholder={t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PLACEHOLDER')}
                        tooltipText={t('EDIT_LINK_SECTION_SHOPPING_URL_TOOLTIP_TEXT')}
                        value={shoppingUrl}
                        setValue={setShoppingUrl}
                        prefix={`${t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PREFIX')}${userDetails?.domain.replace(t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PREFIX'), '')}/`}
                    />
                </div>
                <LoaderButton
                    buttonText={buttonText}
                    isDisabled={isButtonDisabled}
                    className={isMobile ? 'mobile-edit-link-section-button' : 'edit-link-section-button'}
                    isLoading={isButtonProcessing}
                    onClick={onButtonClick}
                    renderAsButton={true}
                />
                <input ref={fileInputRef} type="file" accept="image/*" onChange={onImageChange} style={{ display: 'none' }} />
            </form>
            {
                isAddProductsDialogOpen && <AddProductsDialog
                    isDialogOpen={isAddProductsDialogOpen}
                    handleDialogClose={closeAddProductDialog}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            }
            {
                isImageCropDialogOpen && <ImageCropDialog
                    isDialogOpen={isImageCropDialogOpen}
                    handleDialogClose={closeImageCropDialog}
                    image={cropperTempImage}
                    aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                    onCropDone={onApplyCrop}
                />
            }
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnRightClick={false}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    isLeftButtonWithLoader={false}
                    isRightButtonWithLoader={false}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    areButtonsInColumn={isMobile}
                />
            }
        </div>
    )
}