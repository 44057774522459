import React from 'react'
import './EditLinkSectionInput.css'
import { RxQuestionMark } from 'react-icons/rx'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

/**
 * Represents an input of the edit link section
 * @param {*} value - The value state of the input content
 * @param {function} setValue - A function for setting the value state of the input
 * @param {string} title - The input's title
 * @param {string} placeholder - The input's placeholder
 * @param {string} tooltipText - The input's tooltip text
 * @param {boolean} isMultiline - Determins whether the input is a multiline or not
 * @param {string} prefix - A prefix for the input. If non given, the prefix is not shown
 * @param {number} maxLength - The length limit for the input's value state. 
 *                              Shows a character count in the right top corner of the input. 
 *                              If non given, the character count is not shown
 * @param {number} rows - The number of rows for a multiline input. Ignored when "isMultiline" is false
 */
export default function EditLinkSectionInput({
    value,
    setValue,
    title,
    placeholder,
    tooltipText,
    isMultiline,
    prefix,
    maxLength = 0,
    rows = 2
}) {
    const { t } = useTranslation()

    function hasMaxLength() {
        return maxLength > 0
    }

    return (
        <div className="edit-link-section-link-data-promocode-input-container edit-link-section-input-container">
            <div className="edit-link-section-link-data-promocode-input-title-container edit-link-section-input-title-container">
                <div className="edit-link-section-link-data-input-title-info-container">
                    <div className="edit-link-section-link-data-promocode-input-title edit-link-section-input-title">{title}</div>
                    <div className="edit-link-section-link-data-promocode-section-info-container edit-link-section-info-section">
                        <RxQuestionMark className="edit-link-section-link-data-promocode-section-info edit-link-section-info" />
                        <div className="edit-link-section-link-data-tooptip-bubble">{tooltipText}</div>
                    </div>
                </div>
                {hasMaxLength() && <div className="edit-link-section-link-data-input-character-limit">{t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_CHARACTER_LIMIT', { length: value.length, limit: maxLength })}</div>}
            </div>
            {
                isMultiline ?
                    <textarea className='edit-link-section-link-data-promocode-input edit-link-section-input' rows={rows} maxLength={hasMaxLength() ? maxLength : null} onChange={e => setValue(e.target.value)} value={value} type="text" placeholder={placeholder} />
                    : (
                        prefix ? <div className="edit-link-section-link-data-shopping-url-input-row" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                            <div className="edit-link-section-link-data-shopping-url-input-prefix" style={{ alignSelf: isMobile ? 'start' : 'center' }}>{prefix}</div>
                            <input className='edit-link-section-link-data-shopping-url-input edit-link-section-input-with-prefix' onChange={e => setValue(e.target.value)} value={value} type="text" placeholder={placeholder} />
                        </div> :
                            <input className='edit-link-section-link-data-promocode-input edit-link-section-input' maxLength={hasMaxLength() ? maxLength : null} onChange={e => setValue(e.target.value)} value={value} type="text" placeholder={placeholder} />
                    )
            }
        </div>
    )
}