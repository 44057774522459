import { request_auth } from 'services/RequestWrapper';
import { environment } from "conf";
import { PRODUCT_SEARCH_API_URL } from 'constants/api.constants';

export function ProductSearchAPI(businessId, q, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }
        request_auth(environment.base_url + PRODUCT_SEARCH_API_URL + `?q=${q}&count=${count}&skip=${skip}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    });
}