import React, { useEffect, useState } from 'react'
import './PricingPlan.css'
import { formatNumber } from 'services/utils'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useTranslation } from 'react-i18next'
import { PRICING_PLAN_DATA, UNAVAILABLE_PLANS } from 'constants/general.constants'
import { CancelPlanAPI, UpgradePlanAPI } from 'api/store/store'
import { useHistoryContext } from 'contexts/History'
import { GiConfirmed } from 'react-icons/gi'
import { LINKS_PAGE_URL } from 'constants/routes.constants'

/**
 * Represents a pricing plan.
 * @param {string} name - The name of the pricing plan
 * @param {string} color - The color of this plan, shown as a colored bar on the top
 * @param {number} price - The price of this plan
 * @param {string} pricingPeriod - The subscription period of this plan, e.g. every week, month, year etc.
 * @param {number} pricePerInfluencer - The price per influencer of this plan
 * @param {array} features - The features of this plan
 */
export default function PricingPlan({
    name,
    color,
    price,
    pricingPeriod,
    pricePerInfluencer,
    features
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()
    const { verify } = useAuthContext()

    const [isChangingPlan, setIsChangingPlan] = useState(false)
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(name.toUpperCase() === userDetails?.subscriptionPlan)
    }, [])

    function getButtonText() {
        if (userDetails.subscriptionPlan === name.toUpperCase()) {
            return ''
        }

        switch (userDetails.subscriptionPlan) {
            case 'FREE':
            case null:
            case undefined: {
                switch (name.toUpperCase()) {
                    case 'STARTER': return t('PRICING_PLANS_PAGE_UPGRADE_BUTTON_TEXT')
                    case 'STANDARD':
                    case 'PRO': return ''
                }
            }
            case 'STARTER': {
                switch (name.toUpperCase()) {
                    case 'FREE': return t('PRICING_PLANS_PAGE_DOWNGRADE_BUTTON_TEXT')
                    case 'STANDARD':
                    case 'PRO': return ''
                }
            }
            case 'STANDARD':
                switch (name.toUpperCase()) {
                    case 'FREE':
                    case 'STARTER': return t('PRICING_PLANS_PAGE_DOWNGRADE_BUTTON_TEXT')
                    case 'PRO': return ''
                }
            case 'PRO':
                switch (name.toUpperCase()) {
                    case 'FREE':
                    case 'STARTER':
                    case 'STANDARD': return t('PRICING_PLANS_PAGE_DOWNGRADE_BUTTON_TEXT')
                }
        }
    }

    function getButtonClickHandler() {
        if (name === PRICING_PLAN_DATA.FREE.name)
            return cancel
        return upgrade
    }

    function upgrade() {
        setIsChangingPlan(true)
        UpgradePlanAPI(name.toUpperCase(), userDetails?.businessId)
            .then(url => {
                window.location.href = url
            })
            .catch(error => {
                console.log(error)
                setIsChangingPlan(false)
            })
    }

    function cancel() {
        setIsChangingPlan(true)
        CancelPlanAPI(userDetails?.businessId)
            .then(() => {
                // TODO - replace with updating plan to FREE without verify
                verify(data => {
                    history.push(LINKS_PAGE_URL)
                }, error => {
                    console.log(error)
                    history.push(LINKS_PAGE_URL)
                })
            })
            .catch(error => {
                console.log(error)
                setIsChangingPlan(false)
            })
    }

    return (
        <div className='pricing-plan-container'>
            <div className="pricing-plan-color-bar" style={{ backgroundColor: color }}></div>
            <div className="pricing-plan-content">
                <div className="pricing-plan-name">{name}</div>
                <div className="pricing-plan-pricing-method">
                    <div className="pricing-plan-price" style={{ color }}>${formatNumber(price)}</div>
                    <div className="pricing-plan-price-separator">/</div>
                    <div className="pricing-plan-pricing-period">{pricingPeriod}</div>
                </div>
                {pricePerInfluencer && <div className="pricing-plan-influencer-pricing-method">
                    <div className="pricing-plan-influencer-price">${formatNumber(pricePerInfluencer)}</div>
                    <div className="pricing-plan-influencer-price-separator">/</div>
                    <div className="pricing-plan-influencer-pricing-period">influencer</div>
                </div>}
                <div className="pricing-plan-separator"></div>
                {
                    features.basic.map(basicFeature => <div key={basicFeature} className='pricing-plan-feature-row'>
                        <GiConfirmed className='pricing-plan-feature-v' />
                        <div className='pricing-plan-feature'>{basicFeature}</div>
                    </div>
                    )
                }
                {
                    features.influencer && <>
                        <div className="pricing-plan-separator"></div>
                        {
                            features.influencer.map(influencerFeature => <div key={influencerFeature} className='pricing-plan-feature-row'>
                                <GiConfirmed className='pricing-plan-feature-v' />
                                <div className='pricing-plan-feature'>{influencerFeature}</div>
                            </div>)
                        }
                    </>
                }
                <div className="pricing-plan-bottom-section">
                    {
                        userDetails?.isAuthenticated && (
                            isSelected ? <div className='pricing-plan-active-plan-label'>{t('PRICING_PLANS_PAGE_ACTIVE_PLAN_LABEL')}</div> : (
                                UNAVAILABLE_PLANS.includes(name) ? <div className='pricing-plan-unavailable-plan-label'>{t('PRICING_PLANS_PAGE_UNAVAILABLE_PLAN_LABEL')}</div> :
                                    <LoaderButton
                                        buttonText={getButtonText()}
                                        className='pricing-plan-bottom-button'
                                        isLoading={isChangingPlan}
                                        onClick={getButtonClickHandler()}
                                    />
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}