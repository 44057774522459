import React from 'react'
import './LinkAnalyticsSection.css'

/**
 * Represents an analytics section inside the link preview page to display a piece of data about the link.
 * @param {string} title - The title for this section
 * @param {string} value  - The value for this section
 */
export default function LinkAnalyticsSection({
    title = '',
    value = ''
}) {
    return (
        <div className='link-analytics-section'>
            <div className="link-analytics-title">{title}</div>
            <div className="link-analytics-value">{value}</div>
        </div>
    )
}