import React, { useEffect, useState } from 'react'
import './AccountPage.css'
import { useTranslation } from 'react-i18next'
import AccountSection from 'components/AccountSection/AccountSection'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import { isMobile } from 'react-device-detect'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import ShopifyConnectStore from 'components/ShopifyConnectStore/ShopifyConnectStore'
import { ACCOUNT_PAGE_EMAIL_INPUT_NAME, ACCOUNT_PAGE_NAME_INPUT_NAME, SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR, SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX, SHOPIFY_LOGO_URL, ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME, ACCOUNT_PAGE_SHOP_WEBSITE_INPUT_NAME, ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME, ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME } from 'constants/general.constants'
import { ConnectStoreAPI, DisconnectStore, SyncProducts, UpdateStoreSettings } from 'api/store/store'
import { ADMIN_PAGES_URL_PREFIX, LINKS_PAGE_URL, PRICING_PLANS_PAGE_URL, STORE_SETTINGS_PAGE_URL_PREFIX } from 'constants/routes.constants'
import { ResetUserPassword, UpdateUserInfo } from 'api/auth/auth'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import ImageBox from 'components/ImageBox/ImageBox'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import SettingsInput from 'components/SettingsInput/SettingsInput'

/**
 * Represents the account page.
 */
export default function AccountPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails, setUserDetails } = useUserDetailsContext()
    const { verify } = useAuthContext()

    const originalShippingAndReturnUrl = (userDetails?.shippingAndReturnsUrl && userDetails?.shippingAndReturnsUrl !== '') ? userDetails?.shippingAndReturnsUrl.replace(t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_URL_PREFIX'), '') : ''

    const [inputState, setInputState] = useState({
        name: userDetails.name,
        email: userDetails.email,
        shippingAndReturnsUrl: originalShippingAndReturnUrl,
        facebookPixelId: userDetails?.facebookPixelId,
        googleAnalyticsId: userDetails?.googleAnalyticsId,
    })

    const [buttonsEnablerState, setButtonsEnablerState] = useState({
        saveGeneralInfo: false,
        saveAnalyticsIntegrations: false,
        saveShippingAndReturnUrl: false
    })
    const [isConnectingStore, setIsConnectingStore] = useState(false)
    const [isShopifyConnectionFail, setIsShopifyConnectionFail] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [buttonsLoadingState, setButtonsLoadingState] = useState({
        saveDetails: false,
        saveAnalyticsIntegrations: false,
        saveShippingAndReturnUrl: false,
        disconnect: false,
        forceProductSync: false,
        resetPassword: false,
        deleteAccount: false
    })
    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: t('')
    })

    useEffect(() => {
        setButtonsEnablerState(prev => ({
            ...prev,
            saveGeneralInfo: ![userDetails?.name, ''].includes(inputState.name),
            saveShippingAndReturnUrl: ![originalShippingAndReturnUrl, ''].includes(inputState.shippingAndReturnsUrl),
            saveAnalyticsIntegrations: ![userDetails?.facebookPixelId].includes(inputState.facebookPixelId) || ![userDetails?.googleAnalyticsId].includes(inputState.googleAnalyticsId)
        }))
    }, [inputState])

    function connectShopifyStore(shopUrlPrefix) {
        window.open("https://apps.shopify.com/paylink", "_blank", "noreferrer")
        // TODO - shopify review don't allow requesting myshopify url. So for now redirecting to app store.
        // setIsConnectingStore(true)
        // ConnectStoreAPI(shopUrlPrefix + SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX)
        //     .then(redirectUrl => {
        //         setIsConnectingStore(false)
        //         window.open(redirectUrl, "_blank", "noreferrer")
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         setIsConnectingStore(false)
        //         setIsShopifyConnectionFail(true)
        //     })
    }

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }

    function showApprovalMessage(text) {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: true,
            text
        }))
    }

    function saveDetails() {
        setButtonsLoadingState(prev => ({
            ...prev,
            saveDetails: true
        }))
        saveGeneralInfo()
    }

    function saveShippingAndReturnUrl() {
        setButtonsLoadingState(prev => ({
            ...prev,
            saveShippingAndReturnUrl: true
        }))
        saveBusinessSettings()
    }

    function saveAnalyticsIntegrations() {
        setButtonsLoadingState(prev => ({
            ...prev,
            saveAnalyticsIntegrations: true
        }))
        saveBusinessSettings()
    }

    function saveBusinessSettings() {
        const finalShippingAndReturnUrl = t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_URL_PREFIX') + inputState.shippingAndReturnsUrl

        UpdateStoreSettings(userDetails?.businessId, finalShippingAndReturnUrl, inputState.facebookPixelId, inputState.googleAnalyticsId)
        .then(response => {
            setButtonsLoadingState(prev => ({
                ...prev,
                saveShippingAndReturnUrl: false,
                saveAnalyticsIntegrations: false
            }))
            showApprovalMessage(t('ACCOUNT_PAGE_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
            setButtonsEnablerState(prev => ({
                ...prev,
                saveGeneralInfo: false,
                saveAnalyticsIntegrations: false,
                saveShippingAndReturnUrl: false,
            }))
            setUserDetails(prev => ({
                ...prev,
                shippingAndReturnsUrl: inputState.shippingAndReturnsUrl,
                facebookPixelId: inputState.facebookPixelId,
                googleAnalyticsId: inputState.googleAnalyticsId
            }))
        })
        .catch(error => {
            setButtonsLoadingState(prev => ({
                ...prev,
                saveShippingAndReturnUrl: false,
                saveAnalyticsIntegrations: false
            }))
            console.log(error)
            showDialog(t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_RIGHT_BUTTON_TEXT'))
        })
    }

    function saveGeneralInfo() {
        UpdateUserInfo(inputState.name)
        .then(response => {
            setButtonsLoadingState(prev => ({
                ...prev,
                saveDetails: false
            }))
            showApprovalMessage(t('ACCOUNT_PAGE_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
            setButtonsEnablerState(prev => ({
                ...prev,
                saveGeneralInfo: false
            }))
            setUserDetails(prev => ({
                ...prev,
                name: inputState.name
            }))
        })
        .catch(error => {
            setButtonsLoadingState(prev => ({
                ...prev,
                saveDetails: false
            }))
            console.log(error)
            showDialog(t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_RIGHT_BUTTON_TEXT'))
        })
    }

    function upgradePlan() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    function disconnect() {
        setButtonsLoadingState(prev => ({
            ...prev,
            disconnect: true
        }))
        DisconnectStore(userDetails?.businessId)
            .then(response => {
                verify(data => {
                    history.push(LINKS_PAGE_URL)
                }, error => {
                    setButtonsLoadingState(prev => ({
                        ...prev,
                        disconnect: false
                    }))
                    console.log(error)
                })
            })
            .catch(error => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    disconnect: false
                }))
                console.log(error)
            })
    }

    function syncProducts() {
        setButtonsLoadingState(prev => ({
            ...prev,
            forceProductSync: true
        }))
        SyncProducts(userDetails?.businessId)
            .then(response => {
                if (response.status) {
                    showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_APPROVAL_MESSAGE_TEXT'))
                } else {
                    showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_ERROR_MESSAGE_TEXT'))
                }
                setButtonsLoadingState(prev => ({
                    ...prev,
                    forceProductSync: false
                }))
            })
            .catch(error => {
                showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_APPROVAL_MESSAGE_TEXT'))
                setButtonsLoadingState(prev => ({
                    ...prev,
                    forceProductSync: false
                }))
            })
    }

    function resetPassword() {
        setButtonsLoadingState(prev => ({
            ...prev,
            resetPassword: true
        }))
        ResetUserPassword()
            .then(response => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    resetPassword: false
                }))
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('RESET_PASSWORD_EMAIL_SENT_DIALOG_TITLE'),
                    rightButtonText: null,
                    leftButtonText: t('ACCOUNT_PAGE_RESET_PASSWORD_EMAIL_SENT_DIALOG_LEFT_BUTTON_TEXT')
                }))
            })
            .catch(error => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    resetPassword: false
                }))
                console.log(error)
            })
    }

    function deleteAccount() {
        // TODO add delete account logic
        console.log('deleting account...')
    }

    function onDeleteAccountButtonClick() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_TITLE'),
            leftButtonText: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteAccount
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function handleInputChange(e) {
        switch (e.target.name) {
            case ACCOUNT_PAGE_NAME_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    name: e.target.value
                }))
                break

            case ACCOUNT_PAGE_EMAIL_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    email: e.target.value
                }))
                break

            case ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME: {
                setInputState(prev => ({
                    ...prev,
                    shippingAndReturnsUrl: e.target.value
                }))
                break
            }

            case ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    facebookPixelId: e.target.value
                }))
                break

            case ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    googleAnalyticsId: e.target.value
                }))
                break
        }
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function goToLinks() {
        history.push(LINKS_PAGE_URL)
    }

    function goToAppearance() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${STORE_SETTINGS_PAGE_URL_PREFIX}/${userDetails?.businessId}`)
    }

    function showDialog(title, leftButtonText, rightButtonText, rightButtonClickHandler = () => { }) {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title,
            leftButtonText,
            rightButtonText,
            rightButtonClickHandler
        }))
    }

    return (
        <div className={isMobile ? 'mobile-account-page-container' : 'account-page-container'}>
            <div className="account-page-title">{t('ACCOUNT_PAGE_TITLE')}</div>
            <div className="account-page-content">
                <AccountSection
                    title={t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_TITLE')}
                    sectionContentClassName='account-page-my-information-section-content'
                >
                    <>
                        <div className='account-page-shop-website-section-subtitle-input-container'>
                            <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_NAME_INPUT_LABEL')}</div>
                            <SettingsInput
                                name={ACCOUNT_PAGE_NAME_INPUT_NAME}
                                value={inputState.name}
                                onValueChange={handleInputChange}
                            />
                        </div>

                        <div className='store-settings-page-shop-website-section-subtitle-input-container'>
                            <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_EMAIL_INPUT_LABEL')}</div>
                            <SettingsInput
                                name={ACCOUNT_PAGE_EMAIL_INPUT_NAME}
                                value={inputState.email}
                                isDisabled={true}
                                onValueChange={handleInputChange}
                            />
                        </div>
                        <LoaderButton
                            buttonText={t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_SAVE_DETAILS_BUTTON_TEXT')}
                            isLoading={buttonsLoadingState.saveDetails}
                            className={isMobile ? 'mobile-account-page-my-information-section-save-details-button' : 'account-page-my-information-section-save-details-button'}
                            isDisabled={!buttonsEnablerState.saveGeneralInfo}
                            onClick={saveDetails}
                            loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                        />
                    </>
                </AccountSection>
                <AccountSection
                    title={t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_TITLE')}
                    sectionContentClassName='account-page-current-account-section-content'
                >
                    <>
                        <div className="account-page-current-account-section-username">@{userDetails?.username}</div>
                        <div className="account-page-current-account-section-plan-details-container">
                            <div className="account-page-current-account-section-plan-details">
                                <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_PLAN_DETAILS_TITLE')}</div>
                                <div className="account-page-current-account-section-plan-details-plan-name">{userDetails?.subscriptionPlan}</div>
                            </div>
                            {!isMobile && <div className="account-page-current-account-section-upgrade-button" onClick={upgradePlan}>{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_UPGRADE_BUTTON_TEXT')}</div>}
                        </div>
                        {isMobile && <div className="mobile-account-page-current-account-section-upgrade-button" onClick={upgradePlan}>{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_UPGRADE_BUTTON_TEXT')}</div>}
                    </>
                </AccountSection>
                <AccountSection
                    title={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_TITLE')}
                    sectionContentClassName='account-page-store-profile-section-content'
                >
                    <>
                        {
                            hasShopifyStore() ? <>
                                <div className="account-page-store-profile-section-content-row">
                                    <div className="account-page-store-profile-section-logo-name-container">
                                        <ImageBox
                                            image={SHOPIFY_LOGO_URL}
                                            className='account-page-store-profile-section-logo-container'
                                        />
                                        <div className="account-page-store-profile-section-store-name">{userDetails?.businessName}</div>
                                    </div>
                                    <div className="account-page-store-profile-section-buttons-container">
                                        <div className="account-page-store-profile-section-links-button account-page-store-profile-section-button" onClick={goToLinks}>{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_LINKS_BUTTON_TEXT')}</div>
                                        <div className="account-page-store-profile-section-links-button account-page-store-profile-section-button" onClick={goToAppearance}>{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_APPEARANCE_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            </> : <ShopifyConnectStore
                                isConnectingStore={isConnectingStore}
                                isShopifyConnectionFail={isShopifyConnectionFail}
                                connectShopifyStoreFunction={connectShopifyStore} />
                        }
                        {
                            hasShopifyStore() && <>
                                <div className="account-page-store-profile-section-spacer"></div>
                                <div className='account-page-store-profile-section-buttons-section' style={isMobile ? { flexDirection: 'column-reverse' } : { flexDirection: 'row' }}>
                                    <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_FORCE_PRODUCT_SYNC_BUTTON_TEXT')}
                                        className='account-page-store-profile-section-force-product-sync-button'
                                        isLoading={buttonsLoadingState.forceProductSync}
                                        onClick={syncProducts}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                    <div className="account-page-store-profile-section-force-product-sync-instruction">{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_FORCE_PRODUCT_SYNC_INSTRUCTION')}</div>
                                </div>
                            </>
                        }
                        {
                            hasShopifyStore() && <>
                                <div className="account-page-store-profile-section-spacer"></div>
                                <div className='account-page-store-profile-section-buttons-section' style={isMobile ? { flexDirection: 'column-reverse' } : { flexDirection: 'row' }}>
                                    <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECT_BUTTON_TEXT')}
                                        className='account-page-store-profile-section-disconnect-button'
                                        isLoading={buttonsLoadingState.disconnect}
                                        onClick={disconnect}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                    <div className="account-page-store-profile-section-disconnection-instruction">{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECTION_INSTRUCTION')}</div>
                                </div>
                            </>
                        }
                    </>
                </AccountSection>
                <AccountSection
                    title={t('ACCOUNT_PAGE_PASSWORD_SECTION_TITLE')}
                    sectionContentClassName='account-page-account-actions-section-content'
                >
                    <>
                        <div className="account-page-account-actions-section-container">
                            <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_ACCOUNT_ACTIONS_SECTION_RESET_PASSWORD_SUBTITLE')}</div>
                            <LoaderButton
                                buttonText={t('ACCOUNT_PAGE_ACCOUNT_ACTIONS_SECTION_RESET_PASSWORD_BUTTON_TEXT')}
                                isLoading={buttonsLoadingState.resetPassword}
                                className={`${isMobile ? 'mobile-account-page-account-actions-section-reset-password-button' : 'account-page-account-actions-section-reset-password-button'} account-page-store-profile-section-button`}
                                onClick={resetPassword}
                                loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                            />
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_TITLE')}
                    sectionContentClassName='account-page-analytics-integrations-section'
                >
                    <>
                        <div className="account-page-input-section">
                            <div className='account-page-subtitle-input-container'>
                                <div className="account-page-subtitle-input-titles-container">
                                <div className="account-page-input-title">{t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_FACEBOOK_PIXEL_ID_TITLE')}</div>
                                </div>
                                <SettingsInput
                                    name={ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME}
                                    value={inputState.facebookPixelId}
                                    placeholder={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_FACEBOOK_PIXEL_ID_INPUT_PLACEHOLDER')}
                                    onValueChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="account-page-input-section">
                            <div className='account-page-subtitle-input-container'>
                                <div className="account-page-subtitle-input-titles-container">
                                    <div className="account-page-input-title">{t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_GOOGLE_ANALYTICS_ID_TITLE')}</div>
                                    <div className="account-page-input-subtitle">{t('SETTINGS_PAGE_SHOP_WEBSITE_SECTION_REDIRECT_SUBTITLE')}</div>
                                </div>
                                <SettingsInput
                                    name={ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME}
                                    value={inputState.googleAnalyticsId}
                                    placeholder={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_GOOGLE_ANALYTICS_ID_INPUT_PLACEHOLDER')}
                                    onValueChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <LoaderButton
                            className={isMobile ? 'mobile-store-settings-page-shop-website-section-save-button' : 'store-settings-page-shop-website-section-save-button'}
                            buttonText={t('SETTINGS_PAGE_SHOP_WEBSITE_SECTION_SAVE_BUTTON_TEXT')}
                            isDisabled={!buttonsEnablerState.saveAnalyticsIntegrations}
                            isLoading={buttonsLoadingState.saveAnalyticsIntegrations}
                            onClick={saveAnalyticsIntegrations}
                            loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                        />
                    </>
                </AccountSection>
                <AccountSection
                    title={t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_TITLE')}
                    sectionContentClassName='store-settings-page-shipping-and-returns-section'
                >
                    <>
                        <div className="account-page-input-title">{t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_REDIRECT_TITLE')}</div>
                        <div className='account-page-subtitle-input-container'>
                            <div className="account-page-input-subtitle">{t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURN_SECTION_REDIRECT_SUBTITLE')}</div>
                            <SettingsInput
                                name={ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME}
                                value={inputState.shippingAndReturnsUrl}
                                prefix={t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_URL_PREFIX')}
                                onValueChange={handleInputChange}
                            />
                        </div>
                        <LoaderButton
                            className={isMobile ? 'mobile-store-settings-page-shipping-and-returns-section-save-button' : 'store-settings-page-shipping-and-returns-section-save-button'}
                            buttonText={t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_SAVE_BUTTON_TEXT')}
                            isDisabled={!buttonsEnablerState.saveShippingAndReturnUrl}
                            isLoading={buttonsLoadingState.saveShippingAndReturnUrl}
                            onClick={saveShippingAndReturnUrl}
                            loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                        />
                    </>
                </AccountSection>
                <AccountSection
                    title={t('ACCOUNT_PAGE_DANGER_ZONE_SECTION_TITLE')}
                    sectionContentClassName='account-page-danger-zone-section-content'
                    hasTitle={false}
                >
                    <div className='account-page-danger-zone-section-container' style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                        <LoaderButton
                            buttonText={t('ACCOUNT_PAGE_DANGER_ZONE_SECTION_DELETE_ACCOUNT_BUTTON_TEXT')}
                            isLoading={buttonsLoadingState.deleteAccount}
                            className={isMobile ? 'mobile-account-page-danger-zone-section-delete-account-button' : 'account-page-danger-zone-section-delete-account-button'}
                            onClick={onDeleteAccountButtonClick}
                            loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                        />
                        <div className="account-page-danger-zone-section-instruction">{t('ACCOUNT_PAGE_DANGER_ZONE_SECTION_DELETE_ACCOUNT_INSTRUCTION')}</div>
                    </div>
                </AccountSection>
            </div>
            {
                dialogState.isOpen && <PaydinDialog
                    title={dialogState.title}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    isLeftButtonWithLoader={false}
                />
            }
            <ApprovalMessage
                isMessageShown={approvalMessageState.isMessageShown}
                closeMessage={hideApprovalMassage}
                text={approvalMessageState.text}
                isUp={isMobile}
            />
        </div>
    )
}