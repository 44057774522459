import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import './App.css'
import {
	EDIT_LINK_PAGE_URL,
	WELCOME_PAGE_URL,
	LOGIN_PAGE_URL,
	SIGN_UP_PAGE_URL,
	SIGN_UP_REQUEST_PAGE_URL,
	FORGOT_PASSWORD_PAGE_URL,
	EXTERNAL_AUTH_PAGE_URL,
	CREATE_NEW_PASSWORD_PAGE_URL,
	PRIVACY_POLICY_PAGE_URL,
	TERMS_PAGE_URL,
	ANALYTICS_PAGE_URL,
	ACCOUNT_PAGE_URL,
	STORE_SETTINGS_PAGE_URL,
	LINKS_PAGE_URL,
	PRICING_PLANS_PAGE_URL,
	PREVIEW_LINK_PAGE_URL,
	CREATE_NEW_LINK_PAGE_URL,
	SHOPIFY_HELP_PAGE_URL,
	ADMIN_PAGES_URL_PREFIX,
	ACCOUNT_VERIFICATION_PAGE_URL,
} from 'constants/routes.constants'
import HistoryProvider from 'contexts/History'
import RouteWrapper from 'layouts/RouteWrapper'
import LinksPage from 'pages/LinksPage/LinksPage'
import EditLinkPage from 'pages/EditLinkPage/EditLinkPage'
import WelcomePage from 'pages/WelcomePage/WelcomePage'
import LoginPage from 'pages/LoginPage/LoginPage'
import AuthLayout from 'layouts/RequiredAuthLayout'
import UserProvider from 'contexts/User'
import NotFound from 'pages/NotFound/NotFound'
import {
	APPLICATION_RECOVERY_TIMEOUT_MILISECONDS
} from 'constants/general.constants'
import Splash from 'pages/Splash/Splash'
import SignUpPage from 'pages/SignUpPage/SignUpPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage/ForgotPasswordPage'
import ExternalAuthPage from 'pages/ExternalAuthPage/ExternalAuthPage'
import CreateNewPasswordPage from 'pages/CreateNewPasswordPage/CreateNewPasswordPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage'
import TermsAndConditionsPage from 'pages/TermsAndConditionsPage/TermsAndConditionsPage'
import ShopifyHelpPage from 'pages/ShopifyHelpPage/ShopifyHelpPage'
import AnalyticsPage from 'pages/AnalyticsPage/AnalyticsPage'
import AccountPage from 'pages/AccountPage/AccountPage'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import StoreSettingsPage from 'pages/StoreSettingsPage/StoreSettingsPage'
import 'bootstrap/dist/css/bootstrap.min.css'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import PricingPlansPage from 'pages/PricingPlansPage/PricingPlansPage'
import PreviewLinkPage from 'pages/PreviewLinkPage/PreviewLinkPage'
import CreateLinkPage from 'pages/CreateLinkPage/CreateLinkPage'
import WelcomeLayout from 'layouts/WelcomeLayout/WelcomeLayout'
import AccountVerificationPage from 'pages/AccountVerificationPage/AccountVerificationPage'
import SignUpRequestPage from 'pages/SignUpRequestPage/SignUpRequestPage'
import { isMobile } from 'react-device-detect'

function App() {
	const [isUserLoaded, setIsUserLoaded] = useState(false)
	const [isRecovery, setIsRecovery] = useState(false)
	const [isReady, setIsReady] = useState(false)
	const [isError, setIsError] = useState(false)

	useEffect(() => {

		setTimeout(() => { setIsRecovery(true) }, APPLICATION_RECOVERY_TIMEOUT_MILISECONDS)

		// This is used to hide all scroll bars in mobile devices
		if (isMobile) {
			const elHead = document.getElementsByTagName('head')[0]
			const elStyle = document.createElement('style')

			elHead.appendChild(elStyle)
			elStyle.innerHTML = '*::-webkit-scrollbar { display: none; }  body { -ms-overflow-style: none; scrollbar-width: none; }'
		}

		// catch general error and refresh the page
		// window.addEventListener("error", (event) => {
		//   window.location.replace(LINKS_PAGE_URL);
		// })

	}, [])

	useEffect(() => {
		if (isRecovery && !isUserLoaded) {
			setIsError(true)
		}
	}, [isRecovery])

	return (
		<>
			{
				isError ? <NotFound /> :
					<BrowserRouter>
						<Switch>
							<HistoryProvider>
								<UserProvider onUserLoaded={setIsUserLoaded} onReady={setIsReady}>
									{(!isUserLoaded || !isReady) && <Splash />}
									{isUserLoaded &&
										<>
											<RouteWrapper
												path={LINKS_PAGE_URL}
												exact
												component={LinksPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={EDIT_LINK_PAGE_URL}
												exact
												component={EditLinkPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={ANALYTICS_PAGE_URL}
												exact
												component={AnalyticsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={ACCOUNT_PAGE_URL}
												exact
												component={AccountPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={STORE_SETTINGS_PAGE_URL}
												exact
												component={StoreSettingsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={PRICING_PLANS_PAGE_URL}
												exact
												component={PricingPlansPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={PREVIEW_LINK_PAGE_URL}
												exact
												component={PreviewLinkPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={CREATE_NEW_LINK_PAGE_URL}
												exact
												component={CreateLinkPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={SHOPIFY_HELP_PAGE_URL}
												exact
												component={ShopifyHelpPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={WELCOME_PAGE_URL}
												exact
												component={WelcomePage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={LOGIN_PAGE_URL}
												exact
												component={LoginPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={SIGN_UP_PAGE_URL}
												exact
												component={SignUpPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={SIGN_UP_REQUEST_PAGE_URL}
												exact
												component={SignUpRequestPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={FORGOT_PASSWORD_PAGE_URL}
												exact
												component={ForgotPasswordPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={CREATE_NEW_PASSWORD_PAGE_URL}
												exact
												component={CreateNewPasswordPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={EXTERNAL_AUTH_PAGE_URL}
												exact
												component={ExternalAuthPage}
												layout={AuthLayout}
											/>
											<RouteWrapper
												path={PRIVACY_POLICY_PAGE_URL}
												exact
												component={PrivacyPolicyPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={TERMS_PAGE_URL}
												exact
												component={TermsAndConditionsPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={ACCOUNT_VERIFICATION_PAGE_URL}
												exact
												component={AccountVerificationPage}
												layout={WelcomeLayout}
											/>
											<RouteWrapper
												path={ADMIN_PAGES_URL_PREFIX}
												exact
												redirect={LINKS_PAGE_URL}
											/>
										</>
									}
								</UserProvider>
							</HistoryProvider>
						</Switch>
					</BrowserRouter>
			}
		</>
	)
}

export default App;
