import React from 'react'
import './PricingPlansPage.css'
import { useTranslation } from 'react-i18next'
import PricingPlan from 'components/PricingPlan/PricingPlan'
import { PRICING_PLAN_DATA } from 'constants/general.constants'

export default function PricingPlansPage() {
    const { t } = useTranslation()

    return (
        <div className='pricing-plans-page-container'>
            <div className="pricing-plans-page-content">
                <div className="pricing-plans-page-title">{t('PRICING_PLANS_PAGE_TITLE')}</div>
                <div className="pricing-plans-page-plans-container">
                    <PricingPlan
                        name={PRICING_PLAN_DATA.FREE.name}
                        color={PRICING_PLAN_DATA.FREE.color}
                        price={PRICING_PLAN_DATA.FREE.price}
                        pricingPeriod={PRICING_PLAN_DATA.FREE.pricingPeriod}
                        pricePerInfluencer={PRICING_PLAN_DATA.FREE.pricePerInfluencer}
                        features={PRICING_PLAN_DATA.FREE.features}
                    />
                    <PricingPlan
                        name={PRICING_PLAN_DATA.STARTER.name}
                        color={PRICING_PLAN_DATA.STARTER.color}
                        price={PRICING_PLAN_DATA.STARTER.price}
                        pricingPeriod={PRICING_PLAN_DATA.STARTER.pricingPeriod}
                        pricePerInfluencer={PRICING_PLAN_DATA.STARTER.pricePerInfluencer}
                        features={PRICING_PLAN_DATA.STARTER.features}
                    />
                    {/* TODO leave this comment untill the public app is approved */}
                    {/* <PricingPlan
                        name={PRICING_PLAN_DATA.STANDARD.name}
                        color={PRICING_PLAN_DATA.STANDARD.color}
                        price={PRICING_PLAN_DATA.STANDARD.price}
                        pricingPeriod={PRICING_PLAN_DATA.STANDARD.pricingPeriod}
                        pricePerInfluencer={PRICING_PLAN_DATA.STANDARD.pricePerInfluencer}
                        features={PRICING_PLAN_DATA.STANDARD.features}
                    />
                    <PricingPlan
                        name={PRICING_PLAN_DATA.PRO.name}
                        color={PRICING_PLAN_DATA.PRO.color}
                        price={PRICING_PLAN_DATA.PRO.price}
                        pricingPeriod={PRICING_PLAN_DATA.PRO.pricingPeriod}
                        pricePerInfluencer={PRICING_PLAN_DATA.PRO.pricePerInfluencer}
                        features={PRICING_PLAN_DATA.PRO.features}
                    /> */}
                </div>
            </div>
        </div>
    )
}