import React from 'react'
import './WelcomePage.css'
import { useHistoryContext } from 'contexts/History'
import { useTranslation } from 'react-i18next'
import WelcomePageSection from 'components/WelcomePageSection/WelcomePageSection'
import WelcomePageFooter from 'components/WelcomePageFooter/WelcomePageFooter'
import ImageBox from 'components/ImageBox/ImageBox'
import { WELCOME_PAGE_FIRST_SECTION_BACKGROUND_COLOR, WELCOME_PAGE_FIRST_SECTION_IMAGE_URL, WELCOME_PAGE_SECOND_SECTION_BACKGROUND_COLOR, WELCOME_PAGE_SECOND_SECTION_IMAGE_URL, WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX, WELCOME_PAGE_THIRD_SECTION_BACKGROUND_COLOR, WELCOME_PAGE_THIRD_SECTION_IMAGE_URL } from 'constants/general.constants'
import { SIGN_UP_REQUEST_PAGE_URL } from 'constants/routes.constants'
import { isMobile } from 'react-device-detect'

/**
 * Represents the landing page of Paylink.
 */
export default function WelcomePage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function goToSignUpRequest() {
        history.push(SIGN_UP_REQUEST_PAGE_URL)
    }

    return (
        <div className={isMobile ? 'mobile-welcome-page-container' : 'welcome-page-container'}>
            <WelcomePageSection
                backgroundColor={WELCOME_PAGE_FIRST_SECTION_BACKGROUND_COLOR}
                leftSection={
                    <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-container' : 'welcome-page-section-left-side-container'}`}>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-title' : 'welcome-page-section-left-side-title'}`}>{t('WELCOME_PAGE_FIRST_SECTION_TITLE')}</div>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-subtitle' : 'welcome-page-section-left-side-subtitle'}`}>{t('WELCOME_PAGE_FIRST_SECTION_SUBTITLE')}</div>
                        <div className="welcome-page-section-left-side-buttons-container">
                            <div className="welcome-page-section-left-side-button welcome-page-first-section-left-side-button" onClick={goToSignUpRequest}>{t('WELCOME_PAGE_SECTION_GET_STARTED_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                }
                rightSection={
                    <div className='welcome-page-section-right-side-container'>
                        <ImageBox
                            image={WELCOME_PAGE_FIRST_SECTION_IMAGE_URL}
                            className='welcome-page-section-first-image-container welcome-page-section-image-container'
                            imageClassName='welcome-page-section-first-image welcome-page-section-image'
                            showImageBorderRadius={true}
                            borderRadiusPx={WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX}
                        />
                    </div>
                }
            />
            <WelcomePageSection
                backgroundColor={WELCOME_PAGE_SECOND_SECTION_BACKGROUND_COLOR}
                leftSection={
                    <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-container' : 'welcome-page-section-left-side-container'}`}>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-title' : 'welcome-page-section-left-side-title'}`}>{t('WELCOME_PAGE_SECOND_SECTION_TITLE')}</div>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-subtitle' : 'welcome-page-section-left-side-subtitle'}`}>{t('WELCOME_PAGE_SECOND_SECTION_SUBTITLE')}</div>
                        <div className="welcome-page-section-left-side-buttons-container">
                            <div className="welcome-page-section-left-side-button welcome-page-second-section-left-side-button" onClick={goToSignUpRequest}>{t('WELCOME_PAGE_SECTION_GET_STARTED_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                }
                rightSection={
                    <div className='welcome-page-section-right-side-container'>
                        <ImageBox
                            image={WELCOME_PAGE_SECOND_SECTION_IMAGE_URL}
                            className='welcome-page-section-second-image-container welcome-page-section-image-container'
                            imageClassName='welcome-page-section-second-image welcome-page-section-image'
                            showImageBorderRadius={true}
                            borderRadiusPx={WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX}
                        />
                    </div>
                }
            />
            <WelcomePageSection
                backgroundColor={WELCOME_PAGE_THIRD_SECTION_BACKGROUND_COLOR}
                leftSection={
                    <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-container' : 'welcome-page-section-left-side-container'}`}>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-title' : 'welcome-page-section-left-side-title'}`}>{t('WELCOME_PAGE_THIRD_SECTION_TITLE')}</div>
                        <div className={`${isMobile ? 'mobile-welcome-page-section-left-side-subtitle' : 'welcome-page-section-left-side-subtitle'}`}>{t('WELCOME_PAGE_THIRD_SECTION_SUBTITLE')}</div>
                    </div>
                }
                rightSection={
                    <div className='welcome-page-section-right-side-container'>
                        <ImageBox
                            image={WELCOME_PAGE_THIRD_SECTION_IMAGE_URL}
                            className='welcome-page-section-third-image-container welcome-page-section-image-container'
                            imageClassName='welcome-page-section-third-image welcome-page-section-image'
                            showImageBorderRadius={true}
                            borderRadiusPx={WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX}
                        />
                    </div>
                }
            />
            <WelcomePageFooter />
        </div>
    )
}