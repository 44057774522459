import { isMobile } from "react-device-detect"
import {
    ACCOUNT_PAGE_URL,
    ANALYTICS_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    LINKS_PAGE_URL,
    LOGIN_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    TERMS_PAGE_URL,
    WELCOME_PAGE_URL,
} from "./routes.constants"
import { AiOutlineShopping } from 'react-icons/ai'
import { BsCart2 } from 'react-icons/bs'
import { HiOutlineShoppingBag } from 'react-icons/hi'

export const REQUEST_TIMEOUT_MILLISECONDS = 10000
export const IMAGE_UPLOAD_REQUEST_TIMEOUT_MILLISECONDS = 20000
export const TOP_BAR_HEIGHT_PX = 50
export const APPLICATION_RECOVERY_TIMEOUT_MILISECONDS = 20000
export const ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS = 0.25
export const APPLICATION_READY_TIMEOUT_MILISECONDS = 500
export const MAX_PRODUCT_SEARCH_RESULT_NUMBER = 100
const APPLICATION_RESOURCES_DOMAIN = 'https://cdn.paylink.cloudfront.payli.in/application_resources'
export const PAYLINK_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-logo.webp"
export const PAYLINK_SMALL_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-small-logo.webp"
export const IMAGE_PLACEHOLDER_URL = APPLICATION_RESOURCES_DOMAIN + "/no_image.webp"
export const SHOPIFY_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/shopify-logo.svg"
export const ADMIN_PAGE_MENU_OPTION_HEIGHT_PX = 50
export const PAYDIN_DIALOG_CONTENT_PADDING_PX = 15
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX = ".myshopify.com"
export const PAGE_DEFAULT_MAX_WIDTH = 750
export const ADMIN_PAGE_LINKS_MAX_WIDTH = 1000
export const LINK_PAGES_MAX_WIDTH = 1000
export const ACCOUNT_PAGE_MAX_WIDTH = 750
export const PRICING_PLANS_PAGE_MAX_WIDTH = 1200
export const USER_PASSWORD_MIN_LENGTH = 6
export const PAYLINK_LOGO_MAX_WIDTH_PX = 120
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_QUERY_MIN_LENGTH = 2
export const NO_AUTH_PAGES = [
    LOGIN_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL
]
export const NO_PADDING_PAGES = [
    LOGIN_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL
]
export const UNLIMITED_WIDTH_PAGES = [
    PRIVACY_POLICY_PAGE_URL,
    TERMS_PAGE_URL,
    WELCOME_PAGE_URL
]
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_TOP_PX = 20
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_BOTTON_PX = 20

export const ACCOUNT_PAGE_SHOP_WEBSITE_INPUT_NAME = 'shop-website'
export const ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME = 'shipping-and-returns'
export const ACCOUNT_PAGE_NAME_INPUT_NAME = 'name'
export const ACCOUNT_PAGE_EMAIL_INPUT_NAME = 'email'
export const ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME = 'facebook-pixel-id'
export const ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME = 'google-analytics-id'

export const PRICING_PLAN_DATA = {
    FREE: {
        name: 'free',
        color: '#9a60d5',
        price: 0,
        perInfluencer: null,
        pricingPeriod: 'free',
        features: {
            basic: [
                'Up to 10 active links',
                'Basic analytics',
                'Up to 1 GB storage',
                '100+ templates'
            ]
        }
    },
    STARTER: {
        name: 'starter',
        color: '#3d8e26',
        price: 9.9,
        perInfluencer: null,
        pricingPeriod: 'month',
        features: {
            basic: [
                <><strong>Unlimited</strong> active links</>,
                'Multimedia',
                'Up to 5 GB storage',
                'Extend customization',
                'Prioritized customer support'
            ]
        }
    },
    STANDARD: {
        name: 'standard',
        color: '#1732f6',
        price: 49.9,
        perInfluencer: 5,
        pricingPeriod: 'month',
        features: {
            basic: [
                'Commerce analytics',
                'In-link reccomendation',
                'AI auto look detect',
                'Unlimited storage'
            ],
            influencer: [
                'Influencer analytic',
                'Embedded promocode'
            ]
        }
    },
    PRO: {
        name: 'pro',
        color: '#ec5b54',
        price: 199.9,
        perInfluencer: 5,
        pricingPeriod: 'month',
        features: {
            basic: [
                'Pro analytics',
                'Data export',
                'AI link generation tools',
                'Premium support',
                'Customer success manager'
            ]
        }
    }
}

export const UNAVAILABLE_PLANS = [PRICING_PLAN_DATA.STANDARD.name, PRICING_PLAN_DATA.PRO.name]

export const BOXED_MESSAGE_TYPES = {
    ERROR: 'error',
    INFO: 'info'
}

export const SHOPIFY_CONNECT_NAVIGATE_TO_ADMIN_PAGE_DELAY = 2000

export const PAYDIN_MENU_DEFAULT_WIDTH_PX = 250
export const PAYDIN_MENU_OPENING_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right'
}

export const DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX = 15
export const DASHBOARD_TOPBAR_USER_MENU_WIDTH_PX = 350

export const PAYDIN_DRAWER_DIRECTION = {
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
}

export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX = 15

const PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS = 250
export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX = 20
export const MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ID_ROUTE_PARAMETER_INDEX = 3

export const LINK_VIEW_MODES = {
    PREVIEW: 'preview',
    EDIT: 'edit',
    CREATE: 'create'
}

export const ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX = 75
export const ADD_PRODUCTS_DIALOG_FETCH_NEXT_PRODUCTS_PAGE_THRESHOLD_COUNT = 3

export const CREATE_LINK_PHASES = {
    ADD_IMAGE_PHASE: 0,
    ADD_LINK_PROPERTIES_PHASE: 1
}

export const DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR = [
    LINKS_PAGE_URL,
    ANALYTICS_PAGE_URL,
    ACCOUNT_PAGE_URL
]

export const LINK_ITEM_HEIGHT_PX = isMobile ? 350 : 275
export const LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT = 1
export const LINKS_PAGE_LINK_COLUMN_COUNT = isMobile ? 2 : 4

export const SHOPIFY_HELP_CENTER_URL = 'https://help.shopify.com/en'

export const TIME_UNITS = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year'
}

export const EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_TIME_UNIT = TIME_UNITS.HOUR
export const EDIT_LINK_SECTION_LINK_VANISH_EXPIRY_DATE_VALUE = 24
export const CONTACT_SUPPORT_EMAIL = 'support@paydin.net'
export const CONTACT_SUPPORT_ADDRESS = 'Yosef Lishanski 27, Rishon LeTsiyon'

export const ADMIN_PAGES = [
    LINKS_PAGE_URL,
    ACCOUNT_PAGE_URL
]

export const PROMOCODE_INPUT_MAX_LENGTH = 20
export const PROMOCODE_DESCRIPTION_INPUT_MAX_LENGTH = 30

export const ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS = 700
export const ACCOUNT_VERIFICATION_PAGE_PROCEED_DELAY_MILLISECONDS = 2500

export const EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT = 50

export const WELCOME_PAGE_FIRST_SECTION_BACKGROUND_COLOR = '#2dc2df'
export const WELCOME_PAGE_FIRST_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage1.png'
export const WELCOME_PAGE_SECOND_SECTION_BACKGROUND_COLOR = '#8497b0'
export const WELCOME_PAGE_SECOND_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage2.png'
export const WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX = 5
export const WELCOME_PAGE_THIRD_SECTION_BACKGROUND_COLOR = '#dfe2ed'
export const WELCOME_PAGE_THIRD_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage3.jpg'

export const IMAGE_BOX_DEFAULT_BORDER_RADIUS_PX = 10

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS_MENU_WIDTH_PX = 'fit-content'

export const PAYDIN_IMAGE_CROP_DIALOG_WIDTH_PX = '90vmin'

export const PAYDIN_IMAGE_CROPPER_OBJECT_FITS = {
    CONTAIN: 'contain',
    VERTICAL_COVER: 'vertical-cover',
    HORIZONTAL_COVER: 'horizontal-cover'
}

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS = [
    {
        text: '1:1',
        ratio: 1 / 1
    },
    {
        text: '16:9',
        ratio: 16 / 9
    },
    {
        text: '4:5',
        ratio: 4 / 5
    }
]

export const PAYDIN_IMAGE_CROPPER_DEFAULT_ASPECT_RATIO = 1 / 1

export const PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO = 4 / 1

export const LINKS_PAGE_LINKS_FILTER_COUNT = 50

export const SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR = '#6B718E'
export const DEFAULT_CURRENCY_CODE = 'USD'

export const APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS = 2000

export const STORE_SETTINGS_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS = [
    {
        value: '0px',
        image: <div
            style={{ 
                width: '25px', 
                height: '25px', 
                backgroundColor: '#f6f6f6', 
                border: '2px solid #b6b6b8',
                borderRadius: '0px'
            }}>
        </div>
    },
    {
        value: '10px',
        image: <div
            style={{ 
                width: '25px', 
                height: '25px', 
                backgroundColor: '#f6f6f6', 
                border: '2px solid #b6b6b8',
                borderRadius: '5px'
            }}>
        </div>
    },
    {
        value: '25px',
        image: <div
            style={{
                width: '40px', 
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '25px'
            }}>
        </div>
    }
]

export const STORE_SETTINGS_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS = [
    {
        value: 'cart1',
        image: <AiOutlineShopping
            style={{ 
                width: '30px', 
                height: '30px'
            }} />
    },
    {
        value: 'cart2',
        image: <BsCart2
            style={{ 
                width: '30px', 
                height: '30px'
            }} />
    },
    {
        value: 'cart3',
        image: <HiOutlineShoppingBag
            style={{
                width: '30px', 
                height: '30px',
            }} />
    }
]

export const RESIZE_IMAGE_MAX_WIDTH = 1500
export const RESIZE_IMAGE_MAX_HEIGHT = 1500