import React from 'react'
import './MobileLinkPreviewProductsSlider.css'
import MobileLinkPreviewProductsSliderItem from 'components/MobileLinkPreviewProductsSliderItem/MobileLinkPreviewProductsSliderItem'

/**
 * Represents a products list slider inside the mobile link preview simulator, see {@link MobileLinkPreviewSimulator}.
 * @param {array} products - The list of products to display 
 */
export default function MobileLinkPreviewProductsSlider({ 
    products 
}) {
    return (
        <div className='link-products-slider-container'>
            {
                products && products.map(product =>
                    <MobileLinkPreviewProductsSliderItem
                        key={product?.title}
                        name={product?.title}
                        image={product?.image?.src}
                    />
                )
            }
        </div>
    )
}