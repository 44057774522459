import { CommitLogsAPI } from '../api/logger/logger'

class CloudLogger {
    /**
     *   Class for logging frontend logs to cloud. The logger object helds an array of logs if the array hit max_logs or
         an error log recieved the logger commits the logs to cloud. 
     * @param {*} max_logs 
     */
    constructor(max_logs = 50) {
        this.logs = [];
        this.count = 0;
        this.max_logs = max_logs;
        this.is_committing = false;
        this.is_comminting_needed = false;
    };

    /**
     * Debug log to console
     * @param {*} message - The log message
     * @param  {...any} optionalParams - More logs 
     */
    debug(message) {
        console.log(message)
    }

    /**
     * Log to cloud
     * 
     * @param {*} message - The log message
     * @param {*} force_commit - Is need to force commit to cloud
     */
    log(message, ...optionalParams) {
        let full_message = message + (optionalParams.length > 0 ? "\n" + JSON.stringify(optionalParams) : "");
        this.debug(full_message)
        this.logs[this.count % this.max_logs] = full_message;
        // if ((this.logs.length >= this.max_logs) && !this.is_committing) {
        //     this._commit();
        // }
        this.count++;
    }

    /**
     * Log error message. After error message all the logs is committed to cloud
     * @param {*} message - The log message
     */
    error(message) {
        this.log("ERROR:\n" + message + "\n");
        this._commit();
    }

    /**
     * Commits the logs to cloud
     */
    _commit() {
        this.is_committing = true;
        let total_logs = this.logs.length;
        let start = this.count < total_logs ? 0 : this.count % total_logs + 1;
        let arr1 = this.logs.splice(start, total_logs);
        let arr2 = this.logs.splice(0, start - 1);
        let orderd_logs = arr1.concat(arr2);

        CommitLogsAPI(orderd_logs, null, null)
        .then(()=>{
            this.count = 0;
            this.start = 0;
            this.logs = [];
        })
        .catch((error)=>{
            this.logs.push(error);
        })
        .finally(() => {
            this.is_committing = false;
        });

        return orderd_logs;
    }
}

export const logger = new CloudLogger()