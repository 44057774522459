import React from 'react'
import './Loader.css'

/**
 * Represents a circular spinning loader used by {@link LoaderPage} component.
 * @param {object} styles A style object for styling customizations
 */
export default function Loader({ styles }) {
	return (
		<div className='loader' style={{ ...styles }}></div>
	)
}
