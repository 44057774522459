import React from "react"
import { useUserDetailsContext } from 'contexts/User'
import { Redirect } from "react-router-dom"
import { EXTERNAL_AUTH_PAGE_URL, LOGIN_PAGE_URL } from 'constants/routes.constants'
import BaseLayout from "./BaseLayout/BaseLayout";

const AuthLayout = ({ children }) => {
	const { userDetails } = useUserDetailsContext()

	if (!userDetails?.isAuthenticated) {
		return (
			<Redirect to={{ pathname: LOGIN_PAGE_URL, search: window.location.pathname === EXTERNAL_AUTH_PAGE_URL ? window.location.search : "" }} />
		)
	}
	return (
		<BaseLayout>
			{children}
		</BaseLayout>
	)
};

export default AuthLayout