import React, { useRef, useState } from 'react'
import './SignUpPage.css'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import { EMAIL_PATTERN_REGEX } from 'constants/regex.constants'
import {
    BOXED_MESSAGE_TYPES,
    SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS,
    USER_PASSWORD_MIN_LENGTH
} from 'constants/general.constants'
import { LOGIN_PAGE_URL } from 'constants/routes.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useAuthContext } from 'contexts/User'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { isPasswordSecure } from 'services/utils'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'
import { ValidateEmail, ValidateUsername } from 'api/auth/auth'
import Loader from 'components/Loader/Loader'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { isMobile } from 'react-device-detect'

export default function SignUpPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { register } = useAuthContext()

    const [showPassword, setShowPassword] = useState(false)
    const [isSignUpInProcess, setIsSignUpInProcess] = useState(false)
    const [inputState, setInputState] = useState({
        name: '',
        username: '',
        email: '',
        password: ''
    })

    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: ''
    })

    const [usernameValidationState, setUsernameValidationState] = useState({
        isValidating: false,
        validationResult: null
    })

    const [emailValidationState, setEmailValidationState] = useState({
        isValidating: false,
        validationResult: null
    })

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    const typingTimeoutRef = useRef(null)

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function togglePasswordVisibility() {
        setShowPassword(prev => !prev)
    }

    function handleInputChange(e) {
        clearTypingTimeout(typingTimeoutRef.current)

        setInputState(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        if (e.target.name === 'username') {
            if (e.target.value === '') {
                setUsernameValidationState({
                    isValidating: false,
                    validationResult: null
                })
                return
            }

            typingTimeoutRef.current = setTimeout(() => {
                validateUsername(e.target.value)
            }, SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS)
        } else if (e.target.name === 'email') {
            if (e.target.value === '') {
                setEmailValidationState({
                    isValidating: false,
                    validationResult: null
                })
            } else if (isEmailValid()) {
                typingTimeoutRef.current = setTimeout(() => {
                    validateEmail(e.target.value)
                }, SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS)
            }
        }
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function handleSignUp() {
        if (isPasswordSecure(inputState.password)) {
            setBoxedMessageState(prev => ({
                ...prev,
                isOpen: false
            }))
            setIsSignUpInProcess(true)
            register(inputState.name, inputState.username, inputState.password, inputState.email, () => {
                setIsSignUpInProcess(false)
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('SIGN_UP_PAGE_ACCOUNT_VERIFICATION_DIALOG_TITLE'),
                    message: t('SIGN_UP_PAGE_ACCOUNT_VERIFICATION_DIALOG_MESSAGE'),
                    leftButtonText: t('SIGN_UP_PAGE_ACCOUNT_VERIFICATION_DIALOG_LEFT_BUTTON_TEXT')
                }))
            }, (error) => {
                setIsSignUpInProcess(false)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('SIGN_UP_PAGE_SIGN_UP_ERROR_MESSAGE_TEXT')
                })
            })
        } else {
            setBoxedMessageState({
                isOpen: true,
                message: t('PASSWORD_INSECURE_ERROR_MESSAGE')
            })
        }
    }

    function isNameValid() {
        return inputState.name.length > 0
    }

    function isPasswordValid() {
        return inputState.password.length >= USER_PASSWORD_MIN_LENGTH
    }

    function isEmailValid() {
        return EMAIL_PATTERN_REGEX.test(inputState.email)
    }

    function isAbleToSignUp() {
        return (
            isNameValid() &&
            usernameValidationState.validationResult &&
            emailValidationState.validationResult &&
            isPasswordValid()
        )
    }

    function navigateToLogin() {
        history.push(LOGIN_PAGE_URL)
    }

    function validateUsername(username) {
        setUsernameValidationState(prev => ({
            ...prev,
            isValidating: true
        }))
        ValidateUsername(username)
            .then(response => {
                setUsernameValidationState({
                    isValidating: false,
                    validationResult: true
                })
            })
            .catch(error => {
                setUsernameValidationState({
                    isValidating: false,
                    validationResult: false
                })
            })
    }

    function validateEmail(email) {
        setEmailValidationState(prev => ({
            ...prev,
            isValidating: true
        }))
        ValidateEmail(email)
            .then(response => {
                setEmailValidationState({
                    isValidating: false,
                    validationResult: true
                })
            })
            .catch(error => {
                setEmailValidationState({
                    isValidating: false,
                    validationResult: false
                })
            })
    }

    return (
        <div className='sign-up-page-container'>
            <form dir={t('direction.dir')} className="sign-up-page-content">
                <div className="sign-up-page-title auth-page-title">{t('SIGN_UP_PAGE_TITLE')}</div>
                {
                    boxedMessageState.isOpen && <BoxedMessage
                        message={boxedMessageState.message}
                        type={BOXED_MESSAGE_TYPES.ERROR} />
                }
                <div className="sign-up-page-fields-form">
                    <div className="sign-up-page-fields-form-username-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_PAGE_USERNAME_INPUT_PLACEHOLDER')} name='username' type='text' onChange={handleInputChange} />
                        <div className="sign-up-page-fields-form-username-validation-indication input-field-validation-indication">
                            {
                                usernameValidationState.isValidating ? <Loader styles={{ height: '80%', width: '80%', position: 'absolute' }} /> : (
                                    usernameValidationState.validationResult !== null && (
                                        usernameValidationState.validationResult ?
                                            <IoIosCheckmarkCircleOutline className="sign-up-page-fields-form-username-indicator input-field-valid-indicator" /> :
                                            <IoCloseCircleOutline className="sign-up-page-fields-form-username-indicator input-field-invalid-indicator" />
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="sign-up-page-fields-form-name-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_PAGE_NAME_INPUT_PLACEHOLDER')} name='name' type='text' onChange={handleInputChange} />
                    </div>
                    <div className="sign-up-page-fields-form-email-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_PAGE_EMAIL_INPUT_PLACEHOLDER')} name='email' type='email' onChange={handleInputChange} />
                        <div className="sign-up-page-fields-form-email-validation-indication input-field-validation-indication">
                            {
                                emailValidationState.isValidating ? <Loader styles={{ height: '80%', width: '80%', position: 'absolute' }} /> : (
                                    emailValidationState.validationResult !== null && (
                                        emailValidationState.validationResult ?
                                            <IoIosCheckmarkCircleOutline className="sign-up-page-fields-form-username-indicator input-field-valid-indicator" /> :
                                            <IoCloseCircleOutline className="sign-up-page-fields-form-username-indicator input-field-invalid-indicator" />
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="sign-up-page-fields-form-password-input-container input-field-container">
                        <input className='input-field' placeholder={t('SIGN_UP_PAGE_PASSWORD_INPUT_PLACEHOLDER')} name='password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} />
                        {
                            showPassword ? <AiOutlineEye className='sign-up-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                                : <AiOutlineEyeInvisible className='sign-up-page-password-field-show-hide-password-image' onClick={togglePasswordVisibility} />
                        }
                    </div>
                </div>
                <LoaderButton
                    className="sign-up-page-sign-up-button"
                    buttonText={t('SIGN_UP_PAGE_SIGN_UP_BUTTON_TEXT')}
                    isLoading={isSignUpInProcess}
                    renderAsButton={true}
                    isDisabled={!isAbleToSignUp()}
                    onClick={handleSignUp}
                />
                <div className="sign-up-page-already-have-account-section">
                    <div className="sign-up-page-already-have-account-label">{t('SIGN_UP_PAGE_ALREADY_HAVE_ACCOUNT_LABEL_TEXT')}</div>
                    <div className="sign-up-page-already-have-account-login-button" onClick={navigateToLogin}>{t('SIGN_UP_PAGE_ALREADY_HAVE_ACCOUNT_LOGIN_BUTTON_TEXT')}</div>
                </div>
            </form>
            <PrivaciesFooterText />
            {
                dialogState.isOpen && <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnRightClick={false}
                    leftButtonText={dialogState.leftButtonText}
                    isLeftButtonWithLoader={false}
                    areButtonsInColumn={isMobile}
                />
            }
        </div>
    )
}