import React, { useEffect, useRef, useState } from 'react'
import './AccountVerificationPage.css'
import Loader from 'components/Loader/Loader'
import { useTranslation } from 'react-i18next'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { IoCloseCircle } from 'react-icons/io5'
import { VerifyAccount } from 'api/auth/auth'
import { getQueryVariable } from 'services/SystemService'
import { LOGIN_PAGE_URL, RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import {
    ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS,
    ACCOUNT_VERIFICATION_PAGE_PROCEED_DELAY_MILLISECONDS
} from 'constants/general.constants'

export default function AccountVerificationPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isAccountVerified, setIsAccountVerified] = useState(null)

    const verificationLoaderRef = useRef(null)
    const verificationResultIndicatorRef = useRef(null)

    useEffect(() => {
        VerifyAccount(getQueryVariable(RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY, null))
            .then(response => {
                setTimeout(() => {
                    setIsAccountVerified(true)
                    verificationLoaderRef.current.style.display = 'none'
                    verificationResultIndicatorRef.current.classList.add('has-result')
                    setTimeout(() => {
                        history.push(LOGIN_PAGE_URL)
                    }, ACCOUNT_VERIFICATION_PAGE_PROCEED_DELAY_MILLISECONDS)
                }, ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS)
            })
            .catch(error => {
                console.log(error)
                setTimeout(() => {
                    setIsAccountVerified(false)
                    verificationLoaderRef.current.style.display = 'none'
                    verificationResultIndicatorRef.current.classList.add('has-result')
                }, ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS)
            })
    }, [])

    return (
        <div className='account-verification-page-container'>
            <div className="account-verification-central-container">
                <div ref={verificationLoaderRef} className="account-verification-loader">
                    <div className="account-verification-loader-title">{t('ACCOUNT_VERIFICATION_PAGE_LOADER_TITLE')}</div>
                    <Loader styles={{ width: '35px', height: '35px' }} />
                </div>
                <div ref={verificationResultIndicatorRef} className="account-verification-result-indication">
                    {isAccountVerified ? <>
                        <div className="account-verification-verified-title">{t('ACCOUNT_VERIFICATION_PAGE_VERIFIED_TITLE')}</div>
                        <IoIosCheckmarkCircle className='account-verification-verified-indication account-verification-indication' />
                    </> : <>
                        <div className="account-verification-not-verified-title">{t('ACCOUNT_VERIFICATION_PAGE_NOT_VERIFIED_TITLE')}</div>
                        <IoCloseCircle className='account-verification-not-verified-indication account-verification-indication' />
                    </>}
                </div>
            </div>
        </div>
    )
}