import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NotFound() {
    const { t } = useTranslation();

    return (

        <div>
            <br></br>
            <br></br>
            <br></br>
            <p style={{ fontSize: "60px", textAlign: "center" }}>{t("NOT_FOUND_PAGE_WELCOME_TITLE")}</p>
            <p style={{ fontSize: "40px", textAlign: "center" }}>{t("NOT_FOUND_PAGE_WRONG_TITLE")}</p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p style={{ fontSize: "30px", textAlign: "center" }}>{t("NOT_FOUND_PAGE_TRY_AGIAN_TITLE")}</p>
        </div>
    );
}