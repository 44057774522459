import React from "react";
import { Route, Redirect } from "react-router-dom";

function RouteWrapper({
    component: Component,
    layout: Layout,
    redirect = null,
    ...rest
}) {

    return (
        <Route
            {...rest}
            render={(props) => {
                return redirect ? <Redirect to={redirect} /> : <>
                    {Layout ? <Layout {...props}>
                        <Component style={{ heigth: "100%" }} {...props} />
                    </Layout> : <Component style={{ heigth: "100%" }} {...props} />}
                </>
            }}
        />
    );
}

export default RouteWrapper;