import Loader from 'components/Loader/Loader'
import { TOP_BAR_HEIGHT_PX } from 'constants/general.constants'
import React, { useEffect } from 'react'
import './LoaderPage.css'
import AnimatedStringLoader from 'components/AnimatedStringLoader/AnimatedStringLoader'
import { isMobile } from 'react-device-detect'

/**
 * Represents a loading page for displaying while performing background tasks, such as API requests.
 * A modular loading page which can be either an indeterminate spinning circular loader or an animated string loader.
 * By default, this loader page displays an indeterminate spinning circular loader.
 * The animation of the animated string is explained in {@link AnimatedStringLoader} component documentation.
 * @param {string} animatedString The string to animate, transforms this page's circular loader to an animated string loader
 * @param {object} styles A style object for styling customizations
 */
export default function LoaderPage({ 
  animatedString, 
  styles = {}, 
  marginBottom = '30%', 
  isFullScreen = false,
  isContained = false
}) {

  useEffect(() => {
    document.querySelector('#root').style.overflow = 'hidden'

    return () => document.querySelector('#root').style.overflow = 'auto'
  })

  return (
    <div className='loader-container' style={{ ...styles, height: isFullScreen ? (isContained ? '100%' : '100vh') : `calc(100vh - ${TOP_BAR_HEIGHT_PX})`, marginTop: isFullScreen ? '0' : `${TOP_BAR_HEIGHT_PX}px` }}>
      {animatedString && animatedString.length !== 0 ? <AnimatedStringLoader string={animatedString} /> : <Loader styles={{ marginBottom: marginBottom }} />}
    </div>
  )
}