import React from 'react'
import './AnalyticsTimeSelect.css'
import PaydinSelect from 'components/PaydinSelect/PaydinSelect'
import { TbCalendarEvent } from 'react-icons/tb'

/**
 * Represents a select element for selecting time periods for business analytics.
 * @param {*} optionState - The select value state
 * @param {function} onOptionClick - A function for setting the select's value state
 * @param {array} options - The select's options list
 */
export default function AnalyticsTimeSelect({
    optionState,
    onOptionClick = () => { },
    options = []
}) {
    return (
        <div className='analytics-time-select-container'>
            <PaydinSelect
                options={options}
                optionState={optionState}
                onOptionClick={onOptionClick}
                hideArrow={true}
                textColor='hsl(229, 23%, 72%)'
            />
            <TbCalendarEvent className='analytics-time-select-image' />
        </div>
    )
}