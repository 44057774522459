import React from 'react'
import './AnalyticsDataCard.css'
import Card from 'components/Card/Card'

/**
 * Represents an analytics data, card-like container.
 * Uses the {@link Card} component for card looking style.
 * @param {string} title - The container's title
 * @param {*} value - The container's data value state
 * @param {number} paddingPx - The container's padding in pixels
 */
export default function AnalyticsDataCard({
    title,
    value,
    paddingPx = 25
}) {
    return (
        <Card
            paddingPx={paddingPx}
        >
            <div className="analytics-data-card-title">{title}</div>
            <div className="analytics-data-card-value">{value}</div>
        </Card>
    )
}