import './PaydinSelect.css'
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';

/**
 * Represents a custom drop down menu.
 * @param {string} optionState - The current selected option
 * @param {array} options - The options array for the drop down menu
 * @param {string} border - The border of this select element
 * @param {string} width - The width of this select element
 * @param {number} paddingTopPx - The top padding of the select element in pixels
 * @param {number} paddingBottomPx - The bottom padding of the select element in pixels
 * @param {number} paddingRightPx - The right padding of the select element in pixels
 * @param {number} paddingLeftPx - The left padding of the select element in pixels
 * @param {string} textColor - The text color of the select element
 * @param {boolean} hideArrow - Determins whether the default arrow of the select element is hidden or not
 * @param {function} onOptionClick - A callback to perform when an option from the drop down menu was clicked
 */
export default function PaydinSelect({
    optionState,
    options = [],
    border = null,
    width = '300px',
    paddingTopPx = 5,
    paddingBottomPx = 5,
    paddingRightPx = 10,
    paddingLeftPx = 10,
    textColor = 'black',
    hideArrow = false,
    onOptionClick = option => { }
}) {

    const outlineStyles = {
        border: border ? border : 'none'
    }

    const inputStyles = {
        width,
        padding: `${paddingTopPx}px ${paddingRightPx}px ${paddingBottomPx}px ${paddingLeftPx}px`,
        color: textColor
    }

    return (
        hideArrow ?
            <Select
                fullWidth
                value={optionState}
                onChange={e => onOptionClick(e.target.value)}
                sx={{
                    '.MuiOutlinedInput-notchedOutline': outlineStyles,
                    '.MuiInputBase-input': inputStyles
                }}
                inputProps={{ IconComponent: () => null }}
            >
                {
                    options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)
                }
            </Select> :
            <Select
                fullWidth
                value={optionState}
                onChange={e => onOptionClick(e.target.value)}
                sx={{
                    '.MuiOutlinedInput-notchedOutline': outlineStyles,
                    '.MuiInputBase-input': inputStyles
                }}
            >
                {
                    options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)
                }
            </Select>
    )
}