import React from 'react'
import './PaydinMenu.css'
import { 
    PAYDIN_MENU_DEFAULT_WIDTH_PX, 
    PAYDIN_MENU_OPENING_DIRECTIONS 
} from 'constants/general.constants'
import { Menu } from '@mui/material'

/**
 * Represents a custom menu using the MUI {@link Menu}.
 * It's content can be a list of options or a custom layout given as children.
 * @param {boolean} isOpen - Determins whether the menu is opened or not 
 * @param {DOMElement} anchorElement - The element which the menu is attached to
 * @param {function} onClose - The callback function for closing the menu
 * @param {number} borderRadius - The border radius for the menu container
 * @param {string} direction - The direction from which the menu is opened
 * @param {string} backgroundColor - The background color of this menu
 * @param {array} options - The options list for this menu. Must contain an array of objects 
 *                          that each one of them has 'text' and 'onClick' fields. 
 *                          If this list is empty, the children will be rendered
 */
export default function PaydinMenu({
    children,
    isOpen,
    anchorElement,
    onClose,
    borderRadius = 0,
    direction = PAYDIN_MENU_OPENING_DIRECTIONS.LEFT,
    backgroundColor = 'white',
    options = [],
    widthPx = PAYDIN_MENU_DEFAULT_WIDTH_PX,
    paddingTopPx = 0,
    paddingBottomPx = 0,
    paddingLeftPx = 0,
    paddingRightPx = 0
}) {
    return (
        <Menu
            open={isOpen}
            anchorEl={anchorElement}
            onClose={onClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'top',
                horizontal: direction,
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: direction,
            }}
            sx={{
                '& .MuiMenu-paper': {
                    borderRadius: `${borderRadius}px`,
                    width: widthPx,
                    backgroundColor,
                    boxShadow: '0px 5px 20px 5px rgba(0, 0, 0, 0.1)'
                },
                '& .MuiList-root': {
                    padding: `${paddingTopPx}px ${paddingRightPx}px ${paddingBottomPx}px ${paddingLeftPx}px`,
                }
            }}
        >
            {options.length === 0 ? children : options.map(option => <div className='paydin-menu-item' key={option.text} onClick={option.onClick}>{option.text}</div>)}
        </Menu>
    )
}