import React from 'react'
import './MobileLinkPreviewProductsSliderItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { IMAGE_PLACEHOLDER_URL } from 'constants/general.constants'

/**
 * Represents an item inside the product slider inside the mobile link preview simulator, see {@link MobileLinkPreviewProductsSlider}.
 * @param {string} name - the name of the product
 * @param {string} image - The image url of the product 
 */
export default function MobileLinkPreviewProductsSliderItem({
	name,
	image
}) {
	return (
		<div className='link-products-slider-item-container'>
			<ImageBox
				className='link-products-slider-item-image'
				image={image ? image : IMAGE_PLACEHOLDER_URL}
				showImageLoader={true}
				isImageFitCover={true}
			/>
			<div className="link-products-slider-item-title">{name}</div>
		</div>
	)
}