import { request_post_file } from 'services/RequestWrapper'
import { environment } from "conf"
import { UPLOAD_FILE_API_URL } from 'constants/api.constants'
import { IMAGE_UPLOAD_REQUEST_TIMEOUT_MILLISECONDS } from 'constants/general.constants';

export function UploadFileAPI(file) {
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('image_resource', file)

        const options = {
            method: 'POST',
            formData,
        };  
        request_post_file(environment.base_url + UPLOAD_FILE_API_URL, options, IMAGE_UPLOAD_REQUEST_TIMEOUT_MILLISECONDS)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}