import {
	ACCOUNT_PAGE_MAX_WIDTH,
	LINK_PAGES_MAX_WIDTH,
	NO_PADDING_PAGES,
	PAGE_DEFAULT_MAX_WIDTH,
	PRICING_PLANS_PAGE_MAX_WIDTH,
	UNLIMITED_WIDTH_PAGES
} from "constants/general.constants"
import {
	ACCOUNT_PAGE_URL,
	ADMIN_PAGES_URL_PREFIX,
	ANALYTICS_PAGE_URL,
	CREATE_NEW_LINK_PAGE_URL,
	EDIT_LINK_PAGE_SUFFIX,
	LINKS_PAGE_URL,
	PREVIEW_LINK_PAGE_SUFFIX,
	PRICING_PLANS_PAGE_URL,
	SHOPIFY_HELP_PAGE_URL,
	STORE_SETTINGS_PAGE_URL_PREFIX,
} from 'constants/routes.constants'
import React, { useEffect } from "react"
import './BaseLayout.css'

const BaseLayout = ({ children }) => {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const baseLayoutStyles = {
		...(isLimitedPage() ? { maxWidth: `${getMaxWidth()}px` } : {}),
		...(!NO_PADDING_PAGES.includes(window.location.pathname) ? { padding: 0 } : {})
	}

	function isLimitedPage() {
		return !UNLIMITED_WIDTH_PAGES.includes(window.location.pathname)
	}

	function getMaxWidth() {
		if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + STORE_SETTINGS_PAGE_URL_PREFIX)) {
			return ACCOUNT_PAGE_MAX_WIDTH
		}

		if (window.location.pathname.endsWith(PREVIEW_LINK_PAGE_SUFFIX) || window.location.pathname.endsWith(EDIT_LINK_PAGE_SUFFIX) ) {
			return LINK_PAGES_MAX_WIDTH
		}
		
		switch (window.location.pathname) {
			case ACCOUNT_PAGE_URL: 
				return ACCOUNT_PAGE_MAX_WIDTH

			case LINKS_PAGE_URL:
			case CREATE_NEW_LINK_PAGE_URL:
				return LINK_PAGES_MAX_WIDTH

			case PRICING_PLANS_PAGE_URL:
				return PRICING_PLANS_PAGE_MAX_WIDTH

			case SHOPIFY_HELP_PAGE_URL:
				return 1200

			case ANALYTICS_PAGE_URL:
				return 900

			default: return PAGE_DEFAULT_MAX_WIDTH
		}
	}

	return (
		<div className="base-layout-container" style={baseLayoutStyles}>
			{children}
		</div>
	)
};

export default BaseLayout;