import React, { useEffect, useRef, useState } from 'react'
import './LinkItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { getCurrencySymbol } from 'services/currency'
import { useUserDetailsContext } from 'contexts/User'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_MENU_OPENING_DIRECTIONS
} from 'constants/general.constants'
import { formatNumber, shortenNumber } from 'services/utils'
import { useHistoryContext } from 'contexts/History'
import { LINKS_URL_PREFIX } from 'constants/routes.constants'
import { ADMIN_PAGES_URL_PREFIX } from 'constants/routes.constants'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { environment } from 'conf'
import defaultImage from 'assets/default-image.webp'
import { DeleteLinkAPI } from 'api/links/links'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import { copyToClipboard } from 'services/SystemService'
import { MdOpenInNew, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'

/**
 * Represents a link item.
 * @param {string} linkId - The id of this link item 
 * @param {Image/string} image - The image of this link item 
 * @param {number} createdAt - The epoch time number representing the creation date of this link
 * @param {number} views - The number of views for this link
 * @param {number} revenue - The revenue of this link
 * @param {string} currency - The currency of this link
 * @param {function} onDeleteLink - A callback function to invoke after the link was deleted
 */
export default function LinkItem({
    linkId,
    image,
    createdAt,
    views,
    revenue,
    currency,
    isLinkExpired,
    title,
    onDeleteLink = () => { }
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()

    const [isLinkDeletionDialogOpen, setIsLinkDeletionDialogOpen] = useState(false)
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: () => { },
        onClose: () => closeOptionsDrawer(),
        children: <></>
    })

    const linkItemTitlesContainerRef = useRef(null)
    const linkItemTitleRef = useRef(null)

    useEffect(() => {
        linkItemTitleRef.current.style.maxWidth = `${linkItemTitlesContainerRef?.current?.clientWidth}px`
    }, [])

    function getFormattedDate(epochTime) {
        const date = new Date(epochTime)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function previewLink() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${LINKS_URL_PREFIX}/${linkId}/preview`)
    }

    function editLink() {
        history.push(`${ADMIN_PAGES_URL_PREFIX}${LINKS_URL_PREFIX}/${linkId}/edit`)
    }

    async function copyLink() {
        if (isMobile)
            optionsDrawerState.onClose()
        else
            optionsMenuState.onClose()

        const url = `${environment.frontend_url}${userDetails?.username}/${linkId}`
        await copyToClipboard(url)
        alert(`${url} was copied to clipboard`)
    }

    function openDeleteLinkConfirmationDialog() {
        if (isMobile) {
            optionsDrawerState.onClose()
            setTimeout(() => {
                setIsLinkDeletionDialogOpen(true)
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            setIsLinkDeletionDialogOpen(true)
            history.addBackHandler(() => setIsLinkDeletionDialogOpen(false))
        }
    }

    function deleteLink() {
        DeleteLinkAPI(linkId)
            .then(response => {
                closeDialog()
                onDeleteLink(linkId)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function closeDialog() {
        setIsLinkDeletionDialogOpen(false)
    }

    function openOptionsDrawer() {
        setOptionsDrawerState({
            isOpen: true,
            onOpen: () => { },
            onClose: () => closeOptionsDrawer(),
            children: <>
                <div className="mobile-link-item-more-options-menu-container">
                    <div className="mobile-link-item-more-options-menu-item-line">
                        <div className="mobile-link-item-more-options-menu-single-item" onClick={copyLink}>
                            <MdOutlineContentCopy className="mobile-link-item-more-options-menu-single-item-image" />
                            <div className="mobile-link-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                        </div>
                        <div className="mobile-link-item-more-options-menu-single-item" onClick={previewLink}>
                            <MdOpenInNew className="mobile-link-item-more-options-menu-single-item-image" />
                            <div className="mobile-link-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN')}</div>
                        </div>
                    </div>
                    <div className="mobile-link-item-more-options-menu-section">
                        <div className="mobile-link-item-more-options-menu-section-item" onClick={editLink}>
                            <MdOutlineEdit className="mobile-link-item-more-options-menu-section-item-image" />
                            <div className="mobile-link-item-more-options-menu-section-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT')}</div>
                        </div>
                        <div className="mobile-link-item-more-options-menu-section-item-delete-link mobile-link-item-more-options-menu-section-item" onClick={openDeleteLinkConfirmationDialog}>
                            <RiDeleteBin6Line className="mobile-link-item-more-options-menu-section-item-image" />
                            <div className="mobile-link-item-more-options-menu-section-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE')}</div>
                        </div>
                    </div>
                </div>
            </>
        })
        history.addBackHandler(() => setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        })))
    }

    function closeOptionsDrawer() {
        history.goBack()
    }

    function getTitle() {
        if (!title)
            return 'L' // a random letter for giving the containing div a content to preserve its height
        return title?.length > EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT ? title?.slice(0, EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT) : title
    }

    return (
        <div className={isMobile ? 'mobile-link-item-container' : 'link-item-container'}>
            <div className="link-item-content">
                <div className={isMobile ? 'mobile-link-item-details-container' : 'link-item-details-container'}>
                    <ImageBox
                        image={image ?? defaultImage}
                        alt='link image'
                        className='link-item-image'
                        showGreyImageBackground={true}
                        onImageClickCallback={previewLink}
                    />
                    <div className="link-item-details">
                        <div className="link-item-details-lower-row">
                            <div className="link-item-created-at-expired-container">
                                <div ref={linkItemTitlesContainerRef} className="link-item-titles-container" onClick={previewLink}>
                                    <div ref={linkItemTitleRef} className={isMobile ? "mobile-link-item-title" : "link-item-title"} style={{ visibility: title ? 'visible' : 'hidden' }}>{getTitle()}</div>
                                    <div className={isMobile ? "mobile-link-item-created-at-text" : "link-item-created-at-text"}>{t('LINKS_PAGE_CREATED_AT_TEXT', { createdAtDate: getFormattedDate(createdAt) })}</div>
                                    <div className="link-item-link-expired-text" style={{ visibility: isLinkExpired ? 'visible' : 'hidden' }}>{t('LINKS_PAGE_LINK_EXPIRED_TEXT')}</div>
                                </div>
                                <BiDotsVerticalRounded className='link-item-statistics-more-options-button' onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                            </div>
                            <div className="link-item-statistics-container" onClick={previewLink}>
                                <div className="link-item-statistics-views-container detail-container">
                                    <div className="link-item-statistics-views-title link-item-statistics-title">{t('LINKS_PAGE_STATISTICS_VIEWS_TITLE')}</div>
                                    <div className='link-item-statistics-views-value link-item-statistics-value' style={{ fontSize: isMobile ? '19px' : '21px' }}>{shortenNumber(views)}</div>
                                </div>
                                {/* TODO Uncomment this when decided to show a link's revenue again */}
                                {/* <div className="link-item-statistics-revenue-container detail-container">
                                    <div className="link-item-statistics-revenue-title link-item-statistics-title">{t('LINKS_PAGE_STATISTICS_REVENUE_TITLE')}</div>
                                    <div className={`link-item-statistics-revenue-value ${isMobile ? 'mobile-link-item-statistics-value' : 'link-item-statistics-value'}`} style={{ fontSize: isMobile ? '19px' : '21px' }}>{`${getCurrencySymbol(currency)}${shortenNumber(revenue)}`}</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrowserView>
                <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.LEFT}
                    options={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN'),
                            onClick: previewLink
                        },
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: editLink
                        },
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK'),
                            onClick: copyLink
                        },
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: openDeleteLinkConfirmationDialog
                        }
                    ]}
                />
            </BrowserView>
            <MobileView>
                <PaydinDrawer
                    direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                    isOpen={optionsDrawerState.isOpen}
                    onOpen={optionsDrawerState.onOpen}
                    onClose={optionsDrawerState.onClose}
                    hasPadding={true}
                    topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    paddingTopPx={LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX}
                    paddingBottomPx={LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX}
                    paddingLeftPx={LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX}
                    paddingRightPx={LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX}
                >
                    {optionsDrawerState.children}
                </PaydinDrawer>
            </MobileView>
            {
                isLinkDeletionDialogOpen && <PaydinDialog
                    title={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_TITLE')}
                    message={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_MESSAGE')}
                    isDialogOpen={isLinkDeletionDialogOpen}
                    handleDialogClose={closeDialog}
                    leftButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                    rightButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={deleteLink}
                    closeOnRightClick={false}
                    isLeftButtonWithLoader={false}
                    areButtonsInColumn={isMobile}
                />
            }
        </div>
    )
}